import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';

import { WithPermissions } from 'modules/auth';
import { useDetailsStore } from 'modules/common/details';
import { USER_DETAILS_ENTITY_ID, EPermissionType } from 'modules/user-management';

import { useUsersStore } from '../../module';
import { getUserColumns } from './tableDefs';
import { BackofficeUserRespDto } from 'apis-generated/mapper-sso-admin';
import Button from '@mui/material/Button/Button';

export interface IProps {}

export const UsersPane: FC<IProps> = () => {
  const { t } = useTranslation();
  const store = useUsersStore();
  const detailsStore = useDetailsStore();

  const columns = useMemo(() => getUserColumns(t), [t]);
  // TODO: Нужно получить статус от бэкэнда
  const { tableProps, toolBarProps, refreshIfContainsId, refresh } = useTable({
    columns: columns,
    keyGetter: (it) => it.id,
    fetchData: store.fetchUsers,
  });

  useEffect(() => {
    const updatesHandler = (arg: { userId: string | undefined }) => {
      if (arg.userId) {
        refreshIfContainsId([arg.userId]);
      } else {
        refresh();
      }
    };

    const sub = store.updates$.subscribe(updatesHandler);
    return () => {
      sub.unsubscribe();
    };
  }, [refresh, refreshIfContainsId, store.updates$]);

  const handleAddRoleClick = useCallback(() => {
    detailsStore.openNewDetails(USER_DETAILS_ENTITY_ID);
  }, [detailsStore]);

  return (
    <>
      <TableToolbar<BackofficeUserRespDto> {...toolBarProps} columns={columns}>
        <WithPermissions allow={[EPermissionType.Superadmin]}>
          <Button
            size="small"
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleAddRoleClick}
          >
            {t('users_page_action_new_user')}
          </Button>
        </WithPermissions>
      </TableToolbar>
      <DataTable<BackofficeUserRespDto> {...tableProps} />
    </>
  );
};
