import { Box, Button, Typography } from '@mui/material';
import { MuiRouteLink } from '@mapper/admin-ui-kit';
import { delay } from '@pay/common-utils';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  title: string;
  description?: string;
  shouldReload?: boolean;
}
export const ErrorScreen: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { description, title, shouldReload } = props;
  const { t } = useTranslation();

  const handleClick = useCallback(async () => {
    if (shouldReload) {
      await delay(100);
      window.location.reload();
    }
  }, [shouldReload]);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        background: 'background.paper',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box textAlign="center">
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        {description && <Typography gutterBottom>{description}</Typography>}
        <Button
          onClick={handleClick}
          {...({ component: MuiRouteLink, to: '/' } as any)}
          variant="contained"
        >
          {t('back_to_home')}
        </Button>
      </Box>
    </Box>
  );
};
