import { useGlobalContext } from '@pay/modules';

// import { AuthService } from './service/AuthService';
import { AuthStore } from './data';
import { AuthService } from './service/AuthService_new';

export interface AuthModuleContext {
  auth: {
    service: AuthService;
    store: AuthStore;
  };
}

export const useAuthService = () => useGlobalContext<AuthModuleContext>().auth.service;
export const useAuthStore = () => useGlobalContext<AuthModuleContext>().auth.store;
