import React, { FC, useCallback } from 'react';
import { Box, Paper, TextField, useTheme } from '@mui/material';
import { useNotificationsStore } from '@mapper/admin-ui-kit';
import { pipe } from 'fp-ts/lib/function';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as TE from 'fp-ts/TaskEither';
import { Button, makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';

import { useTranslation } from 'startup/utils';
import { useStaticQrService } from 'modules/business-accounts/module';
import { downloadFile, shouldTrans } from 'modules/common/utils';
import { BOPdfQRRequest } from 'apis-generated/mapper-processing-admin';

interface IProps {}

const Merchant = z.object({
  extId: z.string().length(12, { message: shouldTrans('Неверный формат') }),
  mcc: z.string().length(4, { message: shouldTrans('Неверный формат') }),
  name: z.string().min(1),
  address: z.string().min(1),
});

type Merchant = z.infer<typeof Merchant>;

const schema = z.object({
  merchant: Merchant,
  salePointId: z.string().length(8, { message: shouldTrans('Неверный формат') }),
});

export type FormValues = Zod.infer<typeof schema>;

export const StaticQrDownloadPane: FC<IProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { showNotification } = useNotificationsStore();
  const staticQrService = useStaticQrService();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });
  const handleSubmitValues = useCallback(
    (values: FormValues) => {
      return pipe(
        staticQrService.downloadStaticQr(values as BOPdfQRRequest),
        TE.map((file) => {
          const fileName = `StaticQr.pdf`;
          return downloadFile(file, fileName);
        }),
        TE.mapLeft((err) => {
          showNotification({
            text: t('common_download_error'),
            options: { variant: 'error' },
          });
        })
      )();
    },
    [showNotification, t, staticQrService]
  );

  return (
    <Box mt={2} mb={2}>
      <Paper>
        <form onSubmit={handleSubmit(handleSubmitValues)}>
          <Box p={2} display="flex" flexDirection="row">
            <Controller
              name={'salePointId'}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label={t('common_title_terminal_id')}
                  style={{
                    marginRight: theme.spacing(2),
                  }}
                  {...makeRhfMuiTextFieldProps(errors.salePointId, t)}
                  {...field}
                />
              )}
            />
            <Controller
              name={'merchant.extId'}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label={t('common_title_iin')}
                  style={{
                    marginRight: theme.spacing(2),
                  }}
                  {...makeRhfMuiTextFieldProps(errors.merchant?.extId, t)}
                  {...field}
                />
              )}
            />
            <Controller
              name={'merchant.mcc'}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label={t('common_title_mcc')}
                  style={{
                    marginRight: theme.spacing(2),
                  }}
                  {...makeRhfMuiTextFieldProps(errors.merchant?.mcc, t)}
                  {...field}
                />
              )}
            />
            <Controller
              name={'merchant.name'}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label={t('common_title_name')}
                  style={{
                    marginRight: theme.spacing(2),
                  }}
                  {...makeRhfMuiTextFieldProps(errors.merchant?.name, t)}
                  {...field}
                />
              )}
            />
            <Controller
              name={'merchant.address'}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label={t('common_title_address')}
                  style={{
                    marginRight: theme.spacing(2),
                  }}
                  {...makeRhfMuiTextFieldProps(errors.merchant?.address, t)}
                  {...field}
                />
              )}
            />
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                loading={isSubmitting}
              >
                {t('common_download')}
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};
