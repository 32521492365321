import { UseTableQueryStateArgs } from '@mapper/admin-data-table/dist/DataTable/hooks/useTableQueryState';
import {
  BackofficeNonmontaryControllerApiInterface,
  School,
} from 'apis-generated/mapper-admin-nonmonetary-admin';

import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { mapFetcherResultEither } from 'modules/common/fetcher';
import { pipe } from 'fp-ts/lib/function';
import { TEtoPromise } from 'modules/common/fpts-utils';
import { encode } from 'mdurl';
import { fetchWithRsql } from 'api';
import { IFetcher } from '@pay/data-fetching';
import uuid from 'uuid';
import { makeTableDataSource } from '@mapper/admin-resources';
import { LegalInfo } from 'apis-generated/mapper-processing-admin';

const bin = 'bwml';

export const makeNonMonetaryService = (
  api: BackofficeNonmontaryControllerApiInterface,
  fetcher: IFetcher
) => {
  return {
    fetchAllSchool: (value: string, page = 0) => {
      const encodedVal = encode(value, encode.componentChars + '=:,;"\'<>#', false);
      const fetch = fetchWithRsql<School, 'content'>(
        fetcher,
        `bo/nonmonetary/organization/manage/${bin}/schools?page=${page}&size=${1000}&search=${encode(
          encodedVal
        )}`,
        {
          headers: { 'X-Request-ID': uuid() },
        }
      );
      return pipe(
        fetch,
        T.map(mapFetcherResultEither),
        TE.map((res) => res.content ?? [])
      );
    },

    fetchLegalSchool: (externalId: string) =>
      (async (_, query) => {
        return pipe(
          () => api.getSchools3({ bin, externalId }),
          T.map(mapFetcherResultEither),
          TE.map((items) => {
            return {
              items: items.slice(query.page * query.pageSize).splice(0, query.pageSize),
              total: items.length,
            };
          }),
          TEtoPromise
        )();
      }) as UseTableQueryStateArgs<School>['fetchData'],

    addSchool: (externalId: string, schoolBin: string) => {
      return pipe(
        () =>
          api.addSchool1(
            {
              mNGLegalSchoolRequest: {
                externalId,
                schoolBin,
              },
            },
            { bin }
          ),
        T.map(mapFetcherResultEither)
      );
    },

    deleteSchool: (externalId: string, schoolBin: string) => {
      return pipe(
        () => api.deleteSchool1({ bin, externalId, schoolBin }),
        T.map(mapFetcherResultEither)
      );
    },
    fetchLegalsTable: makeTableDataSource<LegalInfo>(fetcher, 'bo/legals'),
  };
};

export type NonMonataryService = ReturnType<typeof makeNonMonetaryService>;
