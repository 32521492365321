import React, { useCallback, useState, FC } from 'react';
import { useNotificationsStore } from '@mapper/admin-ui-kit';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';

import { constCommonError } from 'modules/common/store';

import { IResetSecondFactorContext } from '../../service/AuthService';
import { useAuthStore } from '../../module';
import { useAuthModals } from '../AuthProvider';
import { useTranslation } from '../utils';
import { ResetSecondAuth } from './ResetSecondAuth';
import { ResetSecondAuthDilog } from './ResetSecondAuthDialog';

interface IProps {}
export const ResetSecondAuthContainer: FC<IProps> = () => {
  const { showNotification } = useNotificationsStore();
  const { showSecondAuthModal } = useAuthModals();
  const { t } = useTranslation();
  const authStore = useAuthStore();

  const [resetContext, setResetContext] = useState<IResetSecondFactorContext | undefined>(
    undefined
  );

  const handleResetSecondAuthRequest = useCallback(() => {
    showSecondAuthModal({
      onSubmit: (code) =>
        pipe(
          authStore.generateSecondFactorData(code),
          TE.fold(T.of, (res) => {
            setResetContext(res);
            return T.of(undefined);
          })
        )(),
    });
  }, [authStore, showSecondAuthModal]);

  const handleResetDialogClose = useCallback(() => {
    setResetContext(undefined);
  }, []);

  const handleResetSecondAuth = useCallback(
    async (code: string) => {
      if (!resetContext) {
        return constCommonError();
      }
      return pipe(
        authStore.validateUserSecondFactor(resetContext.generatedID2FA, code),
        TE.fold(T.of, (_) => {
          showNotification({
            text: t('settings_second_auth_reset_success'),
            options: {
              variant: 'success',
            },
          });
          handleResetDialogClose();
          return T.of(undefined);
        })
      )();
    },
    [authStore, handleResetDialogClose, resetContext, showNotification, t]
  );

  return (
    <>
      <ResetSecondAuthDilog
        key={resetContext?.generatedID2FA ?? 'empty'}
        onSubmit={handleResetSecondAuth}
        context={resetContext}
        onClosed={handleResetDialogClose}
        open={Boolean(resetContext)}
      />
      <ResetSecondAuth onReset={handleResetSecondAuthRequest} />
    </>
  );
};
