import {
  AccountBalance,
  AccountBalanceOutlined,
  AccountCircle,
  AccountCircleOutlined,
  Build,
  BuildOutlined,
  BusinessCenter,
  BusinessCenterOutlined,
  Dashboard,
  DashboardOutlined,
  Description,
  DescriptionOutlined,
  ListAlt,
  ListAltOutlined,
  Settings,
  SettingsOutlined,
  SupervisorAccount,
  SupervisorAccountOutlined,
  Policy,
  PolicyOutlined,
  PhoneAndroid,
  PhoneAndroidOutlined,
  AndroidOutlined,
  Android,
} from '@mui/icons-material';
import { INavigationLinkUnion } from '@mapper/admin-ui-kit';
import { CurrentUserModel } from 'modules/auth';
import { BusinessAccountsPage } from 'modules/business-accounts';
import { ClientsPage } from 'modules/clients';
import { DashboardPage } from 'modules/dashboard';
import { FinOrganizationsPage } from 'modules/fin-organizations';
import { LogsPage } from 'modules/logs';
import { SettingsPage } from 'modules/settings';
import { EPermissionType, UserManagementPage } from 'modules/user-management';
import React from 'react';
import { IAppTransFunction } from 'startup/utils';
import { ConfigurationPage } from 'modules/configuration';
import { AgreementPage } from 'modules/agreement';
import { LegalAgreementPage } from 'modules/legal-agreement';
import { PrivacyPolicyPage } from 'modules/privacy-policy';
import { CoidLogPage } from 'modules/coid';
import { TestClientsPage } from 'modules/test-clients';
import { PosTerminalApkPage } from 'modules/pos-terminals';
import { getNavigationLinks as getMealsNavigationLinks } from 'modules/meals';
import { generatePath, RouteProps } from 'react-router-dom';
import { getRoutes as getMealsRoutes } from 'modules/meals';
import * as AuthUser from 'modules/auth/data/AuthUser';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';
import * as A from 'fp-ts/Array';

type RouteConfig = RouteProps & {
  path: string;
  permissions?: EPermissionType[];
};

export const settingsRoute: RouteConfig = {
  path: '/settings',
  element: <SettingsPage />,
};

export const NAVIGATION_ROUTES: readonly RouteConfig[] = [
  {
    path: '/home',
    element: <DashboardPage />,
    permissions: [EPermissionType.DashboardRead, EPermissionType.Superadmin],
  },
  {
    path: '/clients',
    element: <ClientsPage />,
    permissions: [EPermissionType.ClientsRead, EPermissionType.Superadmin],
  },
  {
    path: '/business-accounts',
    element: <BusinessAccountsPage />,
    permissions: [EPermissionType.LegalRead, EPermissionType.Superadmin],
  },
  {
    path: '/financial-institutes',
    element: <FinOrganizationsPage />,
    permissions: [EPermissionType.BlackboxRead, EPermissionType.Superadmin],
  },
  {
    path: '/test-clients',
    element: <TestClientsPage />,
    permissions: [EPermissionType.ClientsRead, EPermissionType.Superadmin],
  },
  {
    path: '/pos-terminals-apk',
    element: <PosTerminalApkPage />,
    permissions: [EPermissionType.ApkRead, EPermissionType.Superadmin],
  },
  ...getMealsRoutes(),
  {
    path: '/user-management',
    element: <UserManagementPage />,
    permissions: [
      EPermissionType.UsersRead,
      EPermissionType.RolesRead,
      EPermissionType.System,
      EPermissionType.Superadmin,
    ],
  },
  {
    path: '/user-management/:tab',
    element: <UserManagementPage />,
    permissions: [
      EPermissionType.UsersRead,
      EPermissionType.RolesRead,
      EPermissionType.System,
      EPermissionType.Superadmin,
    ],
  },
  {
    path: '/logs',
    element: <LogsPage />,
    permissions: [EPermissionType.UsersRead, EPermissionType.Superadmin],
  },
  {
    path: '/coid',
    element: <CoidLogPage />,
  },
  {
    path: '/agreement/sw',
    element: <AgreementPage />,
    permissions: [EPermissionType.AgreementRead, EPermissionType.Superadmin],
  },
  {
    path: '/agreement/legal',
    element: <LegalAgreementPage />,
    permissions: [EPermissionType.AgreementRead, EPermissionType.Superadmin],
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicyPage />,
    permissions: [EPermissionType.PrivacyPolicyRead, EPermissionType.Superadmin],
  },
  {
    path: '/config',
    element: <ConfigurationPage />,
    permissions: [EPermissionType.SmsRead, EPermissionType.Superadmin],
  },
  settingsRoute,
  {
    path: '/spps',
    element: <>SPP</>,
  },
];

export const getFirstAvailableRoute = (currentUser: AuthUser.AuthUser | undefined) => {
  if (!currentUser) return settingsRoute;
  const isAccessibleRoute = (route: RouteConfig) =>
    route.permissions?.length ? AuthUser.hasOneOfPermissions(currentUser, route.permissions) : true;
  const routeConfig = NAVIGATION_ROUTES.find(isAccessibleRoute) ?? settingsRoute;

  return { ...routeConfig, path: generatePath(routeConfig.path) };
};

export const getNavigationLinks = (t: IAppTransFunction, userModel: CurrentUserModel) => {
  const addIfHasPermission = (permissions: EPermissionType[], link: INavigationLinkUnion) =>
    AuthUser.hasOneOfPermissions(userModel, permissions) ? O.some(link) : O.none;

  const firstGroupLinks: O.Option<INavigationLinkUnion>[] = [
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/home',
      end: true,
      menuIcon: DashboardOutlined,
      menuIconSelected: Dashboard,
      title: t('navigation_main') as string,
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/clients',
      end: false,
      menuIconSelected: AccountCircle,
      menuIcon: AccountCircleOutlined,
      title: t('navigation_clients'),
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/business-accounts',
      end: false,
      menuIconSelected: BusinessCenter,
      menuIcon: BusinessCenterOutlined,
      title: t('navigation_business_accounts'),
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/financial-institutes',
      end: false,
      menuIconSelected: AccountBalance,
      menuIcon: AccountBalanceOutlined,
      title: t('navigation_financial_organizations'),
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/test-clients',
      end: false,
      menuIconSelected: PhoneAndroidOutlined,
      menuIcon: PhoneAndroid,
      title: t('test_clients'),
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/pos-terminals-apk',
      end: false,
      menuIconSelected: AndroidOutlined,
      menuIcon: Android,
      title: t('pos_terminal_apk'),
    }),
    addIfHasPermission(
      [
        EPermissionType.UsersRead,
        EPermissionType.RolesRead,
        EPermissionType.System,
        EPermissionType.Superadmin,
      ],
      {
        group: false,
        path: '/user-management/:tab',
        end: false,
        menuIconSelected: SupervisorAccount,
        menuIcon: SupervisorAccountOutlined,
        title: t('navigation_users'),
        rootLink: '/user-management',
      }
    ),
  ];

  const mealsLinks = getMealsNavigationLinks({ userModel, t });
  if (O.isSome(mealsLinks)) firstGroupLinks.push(mealsLinks);

  firstGroupLinks.push(
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/logs',
      end: false,
      menuIconSelected: ListAlt,
      menuIcon: ListAltOutlined,
      title: t('navigation_logs'),
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/agreement/sw',
      end: false,
      menuIconSelected: Description,
      menuIcon: DescriptionOutlined,
      title: t('navigation_offer'),
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/agreement/legal',
      end: false,
      menuIconSelected: Description,
      menuIcon: DescriptionOutlined,
      title: t('legal_navigation_offer'),
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/privacy-policy',
      end: false,
      menuIconSelected: Policy,
      menuIcon: PolicyOutlined,
      title: t('navigation_privacy_policy'),
    }),
    addIfHasPermission([EPermissionType.DashboardRead, EPermissionType.Superadmin], {
      group: false,
      path: '/config',
      end: false,
      menuIconSelected: Build,
      menuIcon: BuildOutlined,
      title: t('navigation_config'),
    })
  );

  const secondGroupLinks: INavigationLinkUnion[] = [];

  secondGroupLinks.push({
    group: false,
    path: '/settings',
    end: false,
    menuIconSelected: Settings,
    menuIcon: SettingsOutlined,
    title: t('navigation_settings') as string,
  });

  return { firstGroupLinks: pipe(firstGroupLinks, A.compact), secondGroupLinks };
};
