import React, { FC, useCallback, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { DataTable, useTable } from '@mapper/admin-data-table';
import {
  DetailsCard,
  DetailsCardDefinitions,
  IDefinitionItem,
  SimplePopup,
  useModals,
  useNotificationsStore,
} from '@mapper/admin-ui-kit';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { useTranslation } from 'startup/utils';
import { LegalDetails } from 'apis-generated/mapper-processing-admin/models';
import { getPosTerminalInfoColumns, usePosTerminalsService } from 'modules/pos-terminals';
import { useAuthStore } from 'modules/auth';
import { EPermissionType } from 'modules/user-management';
import { TerminalInfoResponse } from 'apis-generated/mapper-documents-admin';
import { ApkVersionCard } from '../ApkVersionCard';
import { SUBJECT_TYPE_MAP } from '../../constants';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';
import { Participants } from './Participants/Participants';
import { Schools } from './Schools/Schools';
import { hasOneOfPermissions } from 'modules/auth/data/AuthUser';

interface IProps {
  account: LegalDetails;
  onRefreshAccount: () => void;
}

export const BusinessAccountInfoPane: FC<IProps> = ({ account, onRefreshAccount }) => {
  const { t } = useTranslation();
  const { fetchTerminalListByIinOrBin, changeTerminalApkVersion } = usePosTerminalsService();
  const { deactivatePosTerminal } = usePosTerminalsService();
  const { showNotification } = useNotificationsStore();
  const { showModal } = useModals();

  const { currentUser } = useAuthStore();

  const canApkWrite = hasOneOfPermissions(currentUser!, [EPermissionType.ApkWrite]);
  const canApkRead = hasOneOfPermissions(currentUser!, [EPermissionType.ApkRead]);
  const canDeletePos = hasOneOfPermissions(currentUser!, [EPermissionType.Superadmin]);

  const handleChangeApkVersion = useCallback(
    (info: TerminalInfoResponse) => {
      showModal(() => <ApkVersionCard info={info} onChanged={refresh} open={true} />);
    },
    [showModal, changeTerminalApkVersion, showNotification, t]
  );

  const handleDeactivatePosTerminal = useCallback(
    (info: TerminalInfoResponse) => {
      const terminalId = info.terminalId;
      showModal((close) => (
        <SimplePopup
          title={t('pos_terminals_delete')}
          onOk={() => {
            pipe(
              deactivatePosTerminal(info.terminalId),
              TE.match(
                () => {
                  showNotification({
                    text: t('pos_terminals_delete_failured', { terminalId }),
                    options: { variant: 'error' },
                  });
                  close();
                },
                () => {
                  showNotification({
                    text: t('pos_terminals_delete_successed', { terminalId }),
                    options: { variant: 'success' },
                  });
                  refresh();
                  close();
                }
              )
            )();
          }}
          okLabel={t('common_delete')}
          content={t('pos_terminals_delete_ask', { terminalId })}
        />
      ));
    },
    [showModal, deactivatePosTerminal, showNotification, t]
  );

  const posTerminalsColumns = useMemo(
    () =>
      getPosTerminalInfoColumns(
        t,
        handleChangeApkVersion,
        handleDeactivatePosTerminal,
        canApkWrite,
        canDeletePos
      ),
    [t, handleChangeApkVersion, canApkWrite, canDeletePos]
  );

  const { tableProps: posTeminalsTableProps, refresh } = useTable<TerminalInfoResponse>({
    fetchData: fetchTerminalListByIinOrBin(account.extId),
    initialQuery: {
      filters: {},
      page: 0,
      pageSize: 15,
      sortings: {},
    },
    columns: posTerminalsColumns,
    keyGetter: (it) => it.terminalId,
  });

  const accDetails: IDefinitionItem[] = useMemo(
    () => [
      { title: t('business_accounts_column_name'), value: account.name },
      { title: t('business_accounts_column_identifier'), value: account.extId },
      { title: t('business_accounts_column_type'), value: t(SUBJECT_TYPE_MAP[account.type]) },
      {
        title: t('common_added_date'),
        value: formatDate(new Date(account.createdAt), DATE_FORMAT.DD_MM_YYYY_HH_mm),
      },
    ],
    [t, account.name, account.extId, account.type, account.createdAt]
  );

  return (
    <>
      <Box mb={2} mt={2}>
        <Grid container spacing={2}>
          <Grid lg={4} sm={6} xs={12} item>
            <DetailsCard title={t('business_accounts_details_area_info')}>
              <DetailsCardDefinitions items={accDetails} />
            </DetailsCard>
          </Grid>
        </Grid>
      </Box>

      <Participants account={account} onRefreshAccount={onRefreshAccount} />
      <br />
      <Schools account={account} onRefreshAccount={onRefreshAccount} />
      <br />

      {canApkRead && (
        <>
          <br />
          <Typography>{t('business_accounts_pos_terminals')}</Typography>
          <DataTable {...posTeminalsTableProps} />
        </>
      )}
    </>
  );
};
