import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';
import {
  Page,
  PageTitle,
  SimplePopup,
  useModals,
  useNotificationsStore,
} from '@mapper/admin-ui-kit';
import React, { useMemo, FC, useCallback, useEffect } from 'react';
import * as TE from 'fp-ts/TaskEither';
import { useTranslation } from 'startup/utils';
import { BlackboxData } from 'apis-generated/mapper-processing-admin';
import { getTestClientsColumns } from './tableDefs';
import { useTestClientsEvents, useTestClientsService } from '../module';
import { Button } from '@mui/material';
import { showBoolean } from 'fp-ts/lib/Show';
import { AddTestClientModal } from './AddTestClient';
import { Refresh } from '@mui/icons-material';
import { pipe } from 'fp-ts/lib/function';
import { BackofficeTestUserRespDto } from 'apis-generated/mapper-sso-admin';
import { TestClientDetailsCard } from './TestClientDetailsCard';
import { useAuthStore } from 'modules/auth';
import { EPermissionType } from 'modules/user-management';
import { hasOneOfPermissions } from 'modules/auth/data/AuthUser';

interface IProps {}

export const TestClientsPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const { fetchTestClientsTable, deleteTestClient } = useTestClientsService();
  const { onRefreshTable$ } = useTestClientsEvents();
  const { showNotification } = useNotificationsStore();
  const { currentUser } = useAuthStore();

  const { showModal } = useModals();

  const canClientsWrite = hasOneOfPermissions(currentUser!, [
    EPermissionType.ClientsWrite,
    EPermissionType.Superadmin,
  ]);

  const handleDeleteTestClientConfirm = useCallback(
    (testClient: BackofficeTestUserRespDto) => {
      const showFailureNotification = () => {
        showNotification({
          options: { variant: 'error' },
          text: t('test_clients_delete_failure', [testClient.username]),
        });
      };

      showModal((close) => (
        <SimplePopup
          title={t('test_clients_delete')}
          onOk={() => {
            return pipe(
              testClient.id,
              deleteTestClient,
              TE.fold(
                (e) => {
                  close();
                  showFailureNotification();
                  return TE.of(undefined);
                },
                (isOk) => {
                  close();
                  if (isOk) {
                    showNotification({
                      options: { variant: 'success' },
                      text: t('test_clients_delete_success', [testClient.username]),
                    });
                    onRefreshTable$.next({ itemId: testClient.id });
                  } else {
                    showFailureNotification();
                  }
                  return TE.of(undefined);
                }
              )
            )();
          }}
          content={<TestClientDetailsCard testClient={testClient} />}
        />
      ));
    },
    [onRefreshTable$, showModal, deleteTestClient, showNotification, t]
  );

  const columns = useMemo(
    () => getTestClientsColumns(t, handleDeleteTestClientConfirm, canClientsWrite),
    [t, handleDeleteTestClientConfirm, canClientsWrite]
  );

  const { tableProps, toolBarProps, refresh, refreshIfContainsId } = useTable({
    fetchData: fetchTestClientsTable,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.id,
  });

  const handleAddClick = () => {
    showModal(<AddTestClientModal onAdded={refresh} onClose={() => {}} open={false} />);
  };

  useEffect(() => {
    const sub = onRefreshTable$.subscribe(({ itemId }) => {
      if (itemId) {
        refreshIfContainsId([itemId]);
      } else {
        refresh();
      }
    });
    return () => sub.unsubscribe();
  }, [onRefreshTable$, refresh, refreshIfContainsId]);

  return (
    <Page>
      <PageTitle>{t('test_clients')}</PageTitle>
      <TableToolbar<BlackboxData> {...toolBarProps} columns={columns}>
        {canClientsWrite && (
          <Button variant="contained" color="primary" size="small" onClick={handleAddClick}>
            {t('common_generate')}
          </Button>
        )}
        <Button startIcon={<Refresh />} variant="contained" color="primary" onClick={refresh}>
          {t('common_refresh')}
        </Button>
      </TableToolbar>
      <DataTable {...tableProps} />
    </Page>
  );
};
