import { useLocation, Navigate } from 'react-router-dom';
import { useAuthStore } from '../../auth';
import React from 'react';
import { EPermissionType } from 'modules/user-management';
import { useMount } from 'react-use';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
interface PrivateRouteProps {
  permissions?: EPermissionType[];
}
export const PrivateRoute: React.FC<React.PropsWithChildren<PrivateRouteProps>> = ({
  children,
  permissions,
  ...rest
}) => {
  const authStore = useAuthStore();
  const currentUser = authStore.currentUser;
  if (!currentUser) {
    return <RedirectToAuth />;
  }
  if (permissions && !authStore.userHasOneOfPermissions(permissions)) {
    return <Navigate to={'/not-found'} replace />;
  }
  return <>{children}</>;
};

const RedirectToAuth: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const authStore = useAuthStore();
  const location = useLocation();

  useMount(() => {
    authStore.setReturnUrl(location.pathname);
  });

  return (
    <Navigate
      to={{
        pathname: '/auth',
      }}
      state={{ returnUrl: location.pathname }}
      replace
    />
  );
};
