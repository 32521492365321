/**
 * TODO: This is naive implementation. Non optimized!
 */

import {
  Checkbox,
  Box,
  FormControlLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';

import { PERMISSION_GROUPS, EPermissionGroup, PERMISSION_GROUPS_TRANS_MAP } from '../constants';
import { EPermissionType } from '../../../../constants';
import { PermissionsGroup } from './PermissionsGroup';
import { isAllPermissionsSet, isSomePermissionsSet, PERMISSION_GROUPS_ARRAY } from './utils';

// const useStyles = makeStyles((theme) => ({
//   group: {
//     marginBottom: theme.spacing(),
//   },
//   groupTitle: {
//     margin: 0,
//     fontWeight: 500,
//   },
//   groupTitleBtn: {
//     width: '100%',
//     justifyContent: 'flex-start',
//     padding: 0,
//     marginLeft: -9,
//   },
//   expansionPanelSummaryContent: {
//     margin: 0,
//     alignItems: 'center',
//   },
//   expansionPanelSummaryContentExpanded: {},
//   expansionPanelDetailsRoot: {
//     paddingTop: 0,
//   },
// }));

interface IProps {
  readonly: boolean;
  permissionsState: Record<EPermissionType, boolean>;
  onPermissionsStateChange: (
    updater: (state: Record<EPermissionType, boolean>) => Record<EPermissionType, boolean>
  ) => void;
}

export const RolePermissionsEditor: FC<IProps> = ({
  permissionsState,
  onPermissionsStateChange,
  readonly,
}) => {
  const { t } = useTranslation();

  const handleGroupCheck = useCallback(
    (group: EPermissionGroup) => (event: unknown, checked: boolean) => {
      onPermissionsStateChange((st) => {
        const newState = { ...st };
        PERMISSION_GROUPS[group].forEach((perm) => (newState[perm] = checked));
        return newState;
      });
    },
    [onPermissionsStateChange]
  );

  const handleItemClick = useCallback(
    (permission: EPermissionType, set: boolean) => {
      onPermissionsStateChange((st) => ({ ...st, [permission]: set }));
    },
    [onPermissionsStateChange]
  );

  // const classes = useStyles();
  return (
    <>
      <Box>
        {PERMISSION_GROUPS_ARRAY.map((group) => {
          const allSet = isAllPermissionsSet(group.key, permissionsState);
          const someSet = !allSet && isSomePermissionsSet(group.key, permissionsState);
          return (
            <Accordion
              key={group.key}
              // expanded={collapseState[group.key]}
              // onChange={e => handelGroupExpand(group.key)}
            >
              <AccordionSummary
                sx={{
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                    alignItems: 'center',
                  },
                }}
                expandIcon={<ExpandMore />}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={allSet}
                      disabled={readonly}
                      indeterminate={someSet}
                      onClick={(e) => e.stopPropagation()}
                      onChange={handleGroupCheck(group.key)}
                    />
                  }
                  label={t(PERMISSION_GROUPS_TRANS_MAP[group.key])}
                />
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                <PermissionsGroup
                  readonly={readonly}
                  permissions={group.permissions}
                  state={permissionsState}
                  onPermissionChange={handleItemClick}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
};
