import { IAppTransFunction } from 'startup/utils';
import { Column, FilterType, TextFilterFormat } from '@mapper/admin-data-table';
import React from 'react';
import { ClientAuditRespDto } from 'apis-generated/mapper-sso-admin';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';

export const getClientLogsColumns = (t: IAppTransFunction): Column<ClientAuditRespDto>[] => {
  return [
    {
      accessor: 'createdAt',
      Header: t('client_details_logs_column_created_date'),
      customFilter: {
        field: 'createdAt',
        type: FilterType.DateRange,
      },
      Cell: ({ row: { original }, value }) => {
        return <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>;
      },
    },
    {
      accessor: 'details',
      Header: t('client_details_logs_column_action'),
    },
  ];
};
