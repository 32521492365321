import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { Button } from '@pay/mui-enhancement';
import { useNotificationsStore } from '@mapper/admin-ui-kit';
import { endOfToday, startOfToday, subMonths } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';
import { DATE_FORMAT } from 'modules/common/constants';
import { removeEmptyKeys } from 'modules/common/utils';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'startup/utils';
import { useReportService } from '../module';
import * as TE from 'fp-ts/TaskEither';
import { LocationAndSchoolForm } from './LocationAndSchoolForm';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  refresh: () => void;
}

const schema = z.object({
  dateFrom: z.date(),
  dateTo: z.date(),
  locationCode: z.string().optional(),
  schoolBin: z.string().optional(),
});
export type FormValues = Zod.infer<typeof schema>;

export const StatReportPage: FC<Props> = ({ refresh }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showNotification } = useNotificationsStore();

  const reportService = useReportService();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      dateFrom: subMonths(startOfToday(), 1),
      dateTo: endOfToday(),
    },
  });

  const handleSubmitValues = useCallback(
    (values: FormValues) => {
      pipe(
        reportService.downloadStatsReport(removeEmptyKeys(values) as FormValues),
        TE.map(() => refresh()),
        TE.mapLeft(() => {
          showNotification({
            text: t('common_download_error'),
            options: { variant: 'error' },
          });
        })
      )();
    },
    [reportService, showNotification, refresh, t]
  );

  return (
    <Paper>
      <Box p={2} alignItems="center">
        <form onSubmit={handleSubmit(handleSubmitValues)}>
          <Box mt={2} mb={2}>
            <Grid container direction="column" spacing={2}>
              <Grid container item key={1} alignItems="center">
                <Grid item xs={1}>
                  <Box mr={2}>
                    <Typography>{t('dashboard_period')}</Typography>
                  </Box>
                </Grid>
                <Controller
                  name={'dateFrom'}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      disableFuture
                      format={DATE_FORMAT.DD_MM_YYYY}
                      slotProps={{
                        textField: {
                          size: 'small',
                          variant: 'outlined',
                          style: { marginRight: theme.spacing(2) },
                        },
                      }}
                      label={t('common_range_from')}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={'dateTo'}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      disableFuture
                      format={DATE_FORMAT.DD_MM_YYYY}
                      slotProps={{
                        textField: {
                          size: 'small',
                          variant: 'outlined',
                          style: { marginRight: theme.spacing(2) },
                        },
                      }}
                      label={t('common_range_to')}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <LocationAndSchoolForm control={control} />
            <Button variant="contained" type="submit" color="primary" loading={isSubmitting}>
              {t('common_download')}
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
