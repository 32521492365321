import { DetailsContainer, EDetailsLayoutType } from '@mapper/admin-ui-kit';
import { CLIENT_DETAIL_ENTITY_ID, ClientDetails } from 'modules/clients';
import {
  BUSINESS_ACCOUNT_DETAILS_ENTITY_ID,
  BusinessAccountDetails,
} from 'modules/business-accounts';
import { OrganizationDetails, ORGANIZATION_ENTITY_ID } from 'modules/fin-organizations';
import {
  USER_DETAILS_ENTITY_ID,
  ROLE_DETAILS_ENTITY_ID,
  UserDetails,
  RoleDetails,
} from 'modules/user-management';
import {
  MEAL_ADMIN_DETAILS_ENTITY_ID,
  MEAL_ADMIN_EDIT_DETAILS_ENTITY_ID,
} from 'modules/meals/constants';
import { MealAdminDetails } from 'modules/meals/ui/edit/MealAdminDetails';

export const DETAILS_MAP: React.ComponentProps<typeof DetailsContainer>['elementMap'] = {
  [CLIENT_DETAIL_ENTITY_ID]: { component: ClientDetails, layout: EDetailsLayoutType.Full },
  [BUSINESS_ACCOUNT_DETAILS_ENTITY_ID]: {
    component: BusinessAccountDetails,
    layout: EDetailsLayoutType.Full,
  },
  [USER_DETAILS_ENTITY_ID]: { component: UserDetails, layout: EDetailsLayoutType.Drawer },
  [ROLE_DETAILS_ENTITY_ID]: { component: RoleDetails, layout: EDetailsLayoutType.Drawer },
  [ORGANIZATION_ENTITY_ID]: {
    component: OrganizationDetails,
    layout: EDetailsLayoutType.Full,
  },
  [MEAL_ADMIN_DETAILS_ENTITY_ID]: {
    component: MealAdminDetails,
    layout: EDetailsLayoutType.Drawer,
  },
  [MEAL_ADMIN_EDIT_DETAILS_ENTITY_ID]: {
    component: MealAdminDetails,
    layout: EDetailsLayoutType.Drawer,
  },
};
