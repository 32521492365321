import { MenuItem } from '@mui/material';
import { useAuthStore } from 'modules/auth';
import { BarUser, Layout, Navigation, TopBar } from '@mapper/admin-ui-kit';
import { observer } from 'mobx-react';
import { useUiPrefencesStore } from 'modules/common';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'startup/utils';
import { getNavigationLinks } from './routes';

interface Props {}

export const LayoutContainer: React.FC<React.PropsWithChildren<Props>> = observer((props) => {
  const { children } = props;
  const uiStore = useUiPrefencesStore();
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUser = authStore.currentUser!;

  const navigationLinks = useMemo(() => getNavigationLinks(t, currentUser), [currentUser, t]);

  const navigationEl = useMemo(() => {
    const { firstGroupLinks, secondGroupLinks } = navigationLinks;
    return (
      <Navigation
        collapsed={uiStore.menuCollapsed}
        navigationLinkSections={[
          { id: 'first', links: firstGroupLinks },
          { id: 'second', links: secondGroupLinks },
        ]}
      ></Navigation>
    );
  }, [navigationLinks, uiStore.menuCollapsed]);

  const handleSettingsClick = useCallback(() => {
    navigate('/settings');
  }, [navigate]);

  const topBarEl = useMemo(() => {
    const menuItems = [
      <MenuItem key="setting" onClick={handleSettingsClick}>
        {t('menu_settings')}
      </MenuItem>,
      <MenuItem key="logout" onClick={() => authStore.logOut()}>
        {t('auth_sign_out')}
      </MenuItem>,
    ];
    const fullName = [currentUser.firstName, currentUser.lastName].filter(Boolean).join(' ');
    const barUserEl = (
      <BarUser
        primary={fullName ?? ''}
        menuItems={menuItems}
        secondary={currentUser.roles[0]?.name ?? ' '}
      />
    );
    return (
      <TopBar
        onThemeToggle={uiStore.toggleTheme}
        onHamburgerToggle={uiStore.toggleNavigation}
        barUserComponent={barUserEl}
      />
    );
  }, [authStore, currentUser, handleSettingsClick, t, uiStore]);

  return (
    <Layout topBarEl={topBarEl} navigationEl={navigationEl}>
      {children}
    </Layout>
  );
});
