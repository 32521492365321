import { styled, Tab } from '@mui/material';
import {
  DetailsError,
  DetailsLoading,
  DetailsView,
  IDetailsComponentProps,
  Tabs as MuiTabs,
} from '@mapper/admin-ui-kit';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDetailsStore } from 'modules/common/details';
import { useFetching } from 'modules/common/hooks';
import { useBusinessAccountsStore } from '../../module';
import { BUSINESS_ACCOUNT_DETAILS_ENTITY_ID } from '../constants';
import { BusinessAccountInfoPane } from './BusinessAccountInfoPane';
import { StaticQrDownloadPane } from './StaticQrDownloadPane/StaticQrDownloadPane';
import { usePermissionsCheck } from 'modules/auth/hooks/usePermissionsCheck';
import { EPermissionType } from 'modules/user-management';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const ContentRoot = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  overflow: 'auto',
  '& [data-table-body=true]': {
    height: 'auto!important',
  },
}));

const TabsRoot = styled(MuiTabs)(({ theme }) => ({
  padding: `0 ${theme.spacing(10)}px`,
}));

enum ETab {
  Default = '',
  Transactions = 'transactions',
  Qr = 'qr',
}

interface IProps extends IDetailsComponentProps {}
export const BusinessAccountDetails: FC<IProps> = ({ tab = ETab.Default, ...props }) => {
  const { t } = useTranslation();
  const canView = usePermissionsCheck([EPermissionType.Superadmin]);

  const detailsStore = useDetailsStore();

  const handleTabChange = useCallback(
    (_: unknown, tab: ETab) => {
      detailsStore.openDetails({
        entityId: props.itemId,
        entityType: BUSINESS_ACCOUNT_DETAILS_ENTITY_ID,
        tab: tab,
      });
    },
    [detailsStore, props.itemId]
  );

  const store = useBusinessAccountsStore();
  const [state, refresh] = useFetching(() => store.fetchOne(props.itemId));

  const title =
    (!state.loading &&
      state.result.isRight &&
      `${t('business_account')}: ${state.result.right.name ?? ''}`) ??
    '';

  const renderTabContent = () => {
    if (state.loading) {
      return <DetailsLoading />;
    }

    if (state.result.isLeft) {
      return <DetailsError />;
    }

    const businessAccount = state.result.right;

    return (
      <>
        <TabsRoot
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab value={ETab.Default} label={t('business_accounts_details_tab_main')} />
          {canView && <Tab value={ETab.Qr} label={t('business_participants_title_static_qr')} />}
        </TabsRoot>
        <ContentRoot>
          <>
            {tab === ETab.Default && (
              <BusinessAccountInfoPane account={businessAccount} onRefreshAccount={refresh} />
            )}
            {tab === ETab.Qr && <StaticQrDownloadPane />}
          </>
        </ContentRoot>
      </>
    );
  };

  return (
    <DetailsView
      style={{ overflowY: 'hidden', padding: 16 }}
      contentBoxProps={{ flexShrink: 1 }}
      onClose={props.onClose}
      title={title}
      entered={props.entered}
      className="loli"
    >
      {renderTabContent()}
    </DetailsView>
  );
};
