import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, Controller, UseFormSetValue } from 'react-hook-form';
import { TextField, IconButton, Box, Typography, Divider, Popover, debounce } from '@mui/material';
import { Add } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@pay/mui-enhancement';
import { useTranslation } from 'startup/utils';
import { EMode, FormValues } from 'modules/meals/types';
import { useDictionaryService } from 'modules/meals/module';
import { useTaskEither, useTaskEitherImmediate } from 'modules/common/async/hooks';
import { CustomTreeView } from './TreeView';
import { LocationNextDTO, SchoolDictionaryDto } from 'apis-generated/reports';
import { Autocomplete } from '@mui/material';

export const SchoolLocationsForm: React.FC<{
  control: any;
  mode: EMode;
  onLocationSelected: UseFormSetValue<FormValues>;
  initialLocationSelected?: { location: string }[];
}> = ({ control, mode, onLocationSelected, initialLocationSelected }) => {
  const { t } = useTranslation();
  const dictionaryService = useDictionaryService();
  const anchorEls = useRef<(HTMLElement | null)[]>([]);
  const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);
  const [expanded, setExpanded] = useState<{ [index: number]: string[] }>({});
  const [selected, setSelected] = useState<{ [index: number]: string }>({});
  const [childData, setChildData] = useState<{
    [index: number]: { [key: string]: LocationNextDTO[] };
  }>({});

  const { state } = useTaskEitherImmediate(() =>
    dictionaryService.getLocations({ page: 0, size: 20 })
  );
  const { execute: updateRequest, state: childState } = useTaskEither(
    dictionaryService.getNextLocation
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'schoolLocations',
  });

  useEffect(() => {
    if (initialLocationSelected) {
      const initialSelected = initialLocationSelected.reduce((acc, curr, index) => {
        acc[index] = curr.location;
        return acc;
      }, {} as { [index: number]: string });
      setSelected(initialSelected);
    }
  }, [initialLocationSelected]);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  const clearUnrelatedNodes = (selectedNode: string, index: number) => {
    const parentCode = selectedNode.slice(0, 2);

    setChildData((prevData) => ({
      ...prevData,
      [index]: Object.keys(prevData[index] || {}).reduce((acc, key) => {
        if (key.startsWith(parentCode) || key === selectedNode) {
          acc[key] = prevData[index][key];
        }
        return acc;
      }, {} as { [key: string]: LocationNextDTO[] }),
    }));

    setExpanded((prevExpanded) => {
      const isAlreadyExpanded = prevExpanded[index]?.includes(selectedNode);
      if (isAlreadyExpanded) {
        return {
          ...prevExpanded,
          [index]: prevExpanded[index].filter((id) => id !== selectedNode),
        };
      } else {
        const newExpanded = prevExpanded[index]?.filter((id) => id.startsWith(parentCode)) || [];
        return {
          ...prevExpanded,
          [index]: [...newExpanded, selectedNode],
        };
      }
    });
  };

  const handleSelect = (event: React.ChangeEvent<{}>, nodeId: string, index: number) => {
    const newSelected = { ...selected, [index]: nodeId };
    clearUnrelatedNodes(nodeId, index);
    setSelected(newSelected);

    updateRequest({ parentCode: nodeId });

    onLocationSelected(`schoolLocations.${index}.location`, nodeId);
  };

  useEffect(() => {
    if (childState?.value && openPopoverIndex !== null) {
      setChildData((prevData) => ({
        ...prevData,
        [openPopoverIndex]: {
          ...prevData[openPopoverIndex],
          [selected[openPopoverIndex]]: childState.value,
        },
      }));
    }
  }, [childState, selected, openPopoverIndex]);

  const handleClose = () => {
    setOpenPopoverIndex(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>, index: number) => {
    anchorEls.current[index] = e.currentTarget;
    setOpenPopoverIndex(index);
  };

  return (
    <>
      {fields.map((item, index) => (
        <Box key={item.id} mb={2}>
          <Typography gutterBottom>{`${t('navigation_service_meals_admin_region_school')} ${
            index + 1
          }`}</Typography>
          <Controller
            name={`schoolLocations.${index}.location`}
            control={control}
            render={({ field: { value, ...fieldProps } }) => (
              <>
                <TextField
                  variant="outlined"
                  size="small"
                  disabled={mode === EMode.View}
                  label={t('user_field_region')}
                  id={`region_${index}`}
                  fullWidth
                  value={selected[index] || value || ''}
                  inputProps={{ readOnly: mode === EMode.View }}
                  onClick={(e) => handleClick(e, index)}
                  {...fieldProps}
                />
                <Popover
                  id={`popover-${index}`}
                  open={openPopoverIndex === index}
                  anchorEl={anchorEls.current[index] || null}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <CustomTreeView
                    regionsData={state?.value?.content || []}
                    childData={childData[index] || []}
                    expanded={expanded[index] || []}
                    selected={selected[index] || value}
                    onNodeToggle={handleToggle}
                    onNodeSelect={(event, nodeId) => handleSelect(event, nodeId!, index)}
                  />
                </Popover>
              </>
            )}
          />
          <SchoolBinsForm
            control={control}
            nestIndex={index}
            mode={mode}
            code={selected[index] || ''}
          />
          {mode !== EMode.View && (
            <IconButton onClick={() => remove(index)}>
              <DeleteIcon />
            </IconButton>
          )}
          <Divider />
        </Box>
      ))}
      {mode !== EMode.View && (
        <Button variant="outlined" onClick={() => append({ location: '', schoolBins: [] })}>
          {t('meal_admin_editor_add_location_button')}
        </Button>
      )}
    </>
  );
};

const SchoolBinsForm: React.FC<{ control: any; nestIndex: number; mode: EMode; code: string }> = ({
  control,
  nestIndex,
  mode,
  code,
}) => {
  const { t } = useTranslation();
  const dictionaryService = useDictionaryService();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `schoolLocations.${nestIndex}.schoolBins`,
  });

  const { state, execute: getSchools } = useTaskEither(dictionaryService.getSchools);

  useEffect(() => {
    if (code) {
      getSchools({ locationCodes: [code] });
    }
  }, [getSchools, code]);

  const schoolOptions = useMemo(() => {
    if (state?.value) {
      return state.value;
    }
    return [];
  }, [state?.value]);

  const debouncedFetchSchools = useMemo(
    () =>
      debounce((symbol?: string) => {
        getSchools({ locationCodes: [code], search: symbol });
      }, 1000),
    [getSchools, code]
  );

  const handleFetchSchools = useCallback(
    (symbol?: string) => {
      debouncedFetchSchools(symbol);
    },
    [debouncedFetchSchools]
  );

  return (
    <>
      {fields.map((item, k) => (
        <Box key={item.id} display="flex" alignItems="center" mt={1}>
          <Controller
            name={`schoolLocations.${nestIndex}.schoolBins.${k}`}
            control={control}
            render={({ field: { ref, onChange, value, ...fieldProps }, fieldState }) => (
              <Autocomplete<SchoolDictionaryDto>
                size="small"
                onInputChange={(_, value, reason) => {
                  if (reason === 'input') {
                    handleFetchSchools(value);
                  }
                }}
                onChange={(e, val) => {
                  if (val) {
                    onChange(val.bin);
                  }
                }}
                value={
                  schoolOptions.find((school: SchoolDictionaryDto) => school.bin === value) || null
                }
                options={schoolOptions}
                fullWidth
                disabled={mode === EMode.View}
                getOptionLabel={(school) =>
                  school?.bin ? `${school.bin} (Школа №${school.number || ''})` : ''
                }
                renderOption={(props, op) => {
                  return (
                    <Box component="li" {...props} overflow="auto" mb={0} key={op.bin}>
                      <Typography>{op.bin}</Typography>
                      {op.number && <Typography>&nbsp; (Школа №{op.number})</Typography>}
                    </Box>
                  );
                }}
                {...fieldProps}
                noOptionsText={t('common_table_empty')}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    error={!!fieldState.error}
                    {...params}
                    label={t('meal_admin_bin_field')}
                  />
                )}
              />
            )}
          />
          {mode !== EMode.View && (
            <IconButton onClick={() => remove(k)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      {mode !== EMode.View && (
        <Button onClick={() => append('')}>
          <Add /> {t('meal_admin_editor_add_bin_button')}
        </Button>
      )}
    </>
  );
};
