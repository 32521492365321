import React from 'react';

import { useAuthStore } from '../../../auth/module';
import { EPermissionType } from 'modules/user-management';

interface Props {
  allow: EPermissionType[];
  fallback?: React.ReactElement;
}
export const WithPermissions: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { allow, children, fallback } = props;
  const authStore = useAuthStore();

  if (!authStore.currentUser) {
    return null;
  }

  if (!authStore.userHasAllPermissions(allow)) {
    return fallback ?? null;
  }

  return <>{children}</>;
};
