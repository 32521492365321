import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';
import { useBusinessSchoolColumns } from './tableDefs';
import { shouldTrans } from 'modules/common/utils';
import { LegalDetails, LegalParticipants } from 'apis-generated/mapper-processing-admin';
import { AddEmployeeModal } from 'modules/business-accounts/ui/AddEmployee';
import { matchPrimitive } from 'modules/common/typescript';
import { useModals } from '@mapper/admin-ui-kit';
import { useNonMonetaryService } from 'modules/business-accounts/module';
import { AddSchoolModal } from 'modules/business-accounts/ui/AddSchool';
import { ExpandMore } from '@mui/icons-material';

type ParticipantsProps = {
  account: LegalDetails;
  onRefreshAccount: () => void;
};

export const Schools = (props: ParticipantsProps) => {
  const { account, onRefreshAccount } = props;

  return (
    <>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ marginBottom: '8px' }}>{shouldTrans('Школы')}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column', padding: 0 }}>
          <SchoolTable account={account} onRefreshAccount={onRefreshAccount} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

type SchoolTableProps = {
  account: LegalDetails;
  onRefreshAccount: () => void;
};

export const SchoolTable = (props: SchoolTableProps) => {
  const { account, onRefreshAccount } = props;

  const { showModal } = useModals();

  const schoolsColumns = useBusinessSchoolColumns(account.extId, onRefreshAccount);

  const { fetchLegalSchool } = useNonMonetaryService();

  const { tableProps: participantTableProps, toolBarProps } = useTable({
    fetchData: fetchLegalSchool(account.extId),
    initialQuery: {
      filters: {},
      page: 0,
      pageSize: 2,
      sortings: {},
    },
    columns: schoolsColumns,
    keyGetter: (it) => it.bin!,
  });

  return (
    <>
      <TableToolbar {...toolBarProps} columns={schoolsColumns}>
        {
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              showModal(
                <AddSchoolModal open={false} legalId={account.extId} onAdded={onRefreshAccount} />
              );
            }}
          >
            {shouldTrans('Добавить Школу')}
          </Button>
        }
      </TableToolbar>
      <DataTable {...participantTableProps} />
    </>
  );
};
