import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorScreen } from './ErrorScreen';

interface IProps {}
export const Page404: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return <ErrorScreen title={t('not_found_title')} description={t('not_found_description')} />;
};
