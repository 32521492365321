import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DetailsContainer, ModalProvider, NotificationsProvider } from '@mapper/admin-ui-kit';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { Navigate, Route, unstable_HistoryRouter as HistoryRouter, Routes } from 'react-router-dom';

import { useUiPrefencesStore } from 'modules/common';
import { AuthModalsProvider, AuthPage, useAuthStore } from 'modules/auth';
import { Page404 } from 'modules/main/error/404Page';
import { LayoutContainer } from 'modules/main/Layout';
import { browserHistory } from 'startup/di';
import { DATE_FNS_LOCALE_MAP } from 'startup/i18n';
import { muiDarkTheme, muiLightTheme } from '../../startup/theme';
import { DETAILS_MAP } from './details';
import { getFirstAvailableRoute, NAVIGATION_ROUTES } from './routes';
import { PrivateRoute } from './routing/PrivateRoute';
import { AuthExpiredSessionModal } from 'modules/auth/ui/expired-session/AuthExpiredSessionModal';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface Props {}

const AppContainerBase: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const authStore = useAuthStore();
  const { isLoggedIn, currentUser } = authStore;

  useEffect(() => {
    const globalLoader = document.getElementById('global-loader');
    if (globalLoader) {
      globalLoader.style.transition = 'all .2s ease-in';
      globalLoader.style.opacity = '0';
      setTimeout(() => globalLoader.remove(), 200);
    }
  }, []);

  const firstAvailableRoute = useMemo(() => getFirstAvailableRoute(currentUser), [currentUser]);

  const appRoutes = (
    <LayoutContainer>
      <Routes>
        {NAVIGATION_ROUTES.map((route) => {
          const { permissions, element, ...otherProps } = route;
          return (
            <Route
              key={route.path as string}
              element={<PrivateRoute permissions={route.permissions}>{element}</PrivateRoute>}
              {...otherProps}
            ></Route>
          );
        })}
        <Route path="/" element={<Navigate to={firstAvailableRoute.path} replace />}></Route>
        <Route path="*" element={<Navigate to="/not-found" replace />}></Route>
      </Routes>
      <DetailsContainer elementMap={DETAILS_MAP} />
      <AuthExpiredSessionModal />
    </LayoutContainer>
  );

  return (
    <>
      <Routes>
        <Route
          path="auth"
          element={
            isLoggedIn ? (
              <Navigate
                to={authStore.initialState?.returnUrl || firstAvailableRoute.path}
                replace
              />
            ) : (
              <AuthPage backgroundUrl="/images/auth-bg.jpg" />
            )
          }
        />
        <Route path="not-found" element={<Page404 />} />
        <Route path="*" element={<PrivateRoute>{appRoutes}</PrivateRoute>} />
      </Routes>
    </>
  );
};
const AppContainer = observer(AppContainerBase);

const App = observer(() => {
  const uiPreferencesStore = useUiPrefencesStore();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={uiPreferencesStore.theme === 'light' ? muiLightTheme : muiDarkTheme}>
        <LocalizationProvider
          adapterLocale={DATE_FNS_LOCALE_MAP[uiPreferencesStore.lang]}
          dateAdapter={AdapterDateFns}
        >
          <AuthModalsProvider>
            <ModalProvider>
              <HistoryRouter history={browserHistory}>
                <NotificationsProvider>
                  <CssBaseline />
                  <AppContainer />
                </NotificationsProvider>
              </HistoryRouter>
            </ModalProvider>
          </AuthModalsProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default App;
