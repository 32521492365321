import { IFetcher } from '@pay/data-fetching';
import {
  DeleteSchoolPathParams,
  EditSchoolOperationPathParams,
  EditSchoolOperationRequestBody,
  SchoolControllerApiInterface,
} from 'apis-generated/school-meal';

export const makeSchoolControllerApi = (fetcher: IFetcher): SchoolControllerApiInterface => {
  return {
    list: () => fetcher.get('meal/school/search'),
    addSchool: (args) => fetcher.postJson('meal/school', args.addSchoolRequest),
    deleteSchool: (pathParams: DeleteSchoolPathParams) => {
      return fetcher.delete(`meal/school/${pathParams.bin}`);
    },
    editSchool: (
      args: EditSchoolOperationRequestBody,
      pathParams: EditSchoolOperationPathParams
    ) => {
      return fetcher.putJson(`meal/school/${pathParams.bin}`, args.editSchoolRequest);
    },
    uploadSchool: () => {
      throw new Error('not implemented');
    },
    getTemplate: () => {
      return fetcher.get('meal/school/template');
    },
  };
};
