import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as TE from 'fp-ts/TaskEither';
import { Search } from '@mui/icons-material';
import {
  ErrorOverlay,
  IDetailsComponentProps,
  ITEM_ID_NEW,
  LoadingOverlay,
} from '@mapper/admin-ui-kit';

import { useAuthStore } from 'modules/auth/module';
import { constRemoteError } from 'modules/common/store';
import { EPermissionType } from 'modules/user-management';
import { useTaskEither } from 'modules/common/async/hooks';

import { EMode, RoleEditor } from './RoleEditForm';
import { IRoleValues } from '../../data/types';
import { useRolesStore } from '../../../module';
import { hasOneOfPermissions } from 'modules/auth/data/AuthUser';

interface IRoleDetailsProps extends IDetailsComponentProps {}
export const RoleDetails: FC<IRoleDetailsProps> = ({ itemId, onClose, entered }) => {
  const store = useRolesStore();

  return itemId === ITEM_ID_NEW ? (
    <RoleEditor onClose={onClose} onSave={store.addRole} mode={EMode.Create} />
  ) : (
    <RoleViewEditContainer entered={entered} onClose={onClose} originalRoleId={itemId} />
  );
};

interface IRoleEditContainerProps {
  originalRoleId: string;
  onClose: () => void;
  entered: boolean;
}
export const RoleViewEditContainer: FC<IRoleEditContainerProps> = ({
  originalRoleId,
  entered,
  onClose,
}) => {
  const store = useRolesStore();
  const { t } = useTranslation();
  const { currentUser } = useAuthStore();

  const [isEditing, setIsEditing] = useState(false);
  const { state, execute } = useTaskEither(() => store.fetchOneRole(originalRoleId));

  useEffect(() => {
    execute();
  }, [execute]);

  const handleEdit = useCallback(() => setIsEditing(true), []);
  const handleSave = useCallback(
    (value: IRoleValues) => {
      if (!state?.value) {
        // hack, not really a remote error
        return TE.left(constRemoteError());
      }
      return store.updateRole(state.value, value);
    },
    [state, store]
  );

  if (state?.loading || !entered) {
    return <LoadingOverlay />;
  }

  if (state?.error) {
    if (state?.error) {
      return <ErrorOverlay icon={Search} text={t('role_not_found')} onClose={onClose} />;
    }
    return <ErrorOverlay text={t('common_unknown_error')} onClose={onClose} />;
  }

  return (
    <RoleEditor
      mode={isEditing ? EMode.Edit : EMode.View}
      onClose={onClose}
      originalRole={state?.value}
      onStartEdit={handleEdit}
      onSave={handleSave}
      canEdit={hasOneOfPermissions(currentUser!, [
        EPermissionType.RolesWrite,
        EPermissionType.System,
        EPermissionType.Superadmin,
      ])}
    />
  );
};
