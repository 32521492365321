import { IconButton, InputAdornment, TextFieldProps, Tooltip } from '@mui/material';
import { IRifmTextFieldProps, makeRhfMuiTextFieldProps, RifmTextField } from '@pay/mui-enhancement';
import React, { useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { ITransKey } from 'startup/i18n';
import { CODE_LENGTH, formatSecondAuthCode, useTranslation } from '../utils';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

interface IProps extends Omit<TextFieldProps, 'onChange' | 'error' | 'onPaste'> {
  error: FieldError | undefined;
  onChange?: IRifmTextFieldProps['onChange'];
  onPasted: (code: string) => void;
}
export const SecondAuthCodeField: React.FC<IProps> = (props) => {
  const { error, onPasted, ...textFieldProps } = props;
  const { t } = useTranslation();

  const errorTransFn = useCallback(
    (key: string) => t(key as ITransKey, { maxLength: CODE_LENGTH }),
    [t]
  );

  const handlePaste = async () => {
    onPasted(await navigator.clipboard.readText());
  };

  return (
    <RifmTextField
      rifm={{
        accept: /\d/g,
        format: formatSecondAuthCode,
      }}
      size="small"
      autoFocus
      fullWidth
      variant="outlined"
      label={t('auth_code')}
      inputProps={{ 'data-test-id': 'code' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Вставить код'}>
              <IconButton onClick={handlePaste} edge="end">
                <AssignmentTurnedInOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
      {...makeRhfMuiTextFieldProps(error, errorTransFn)}
    />
  );
};
