import React, { FC } from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Button, RifmTextField, makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';
import { Delete } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import { FIELD_LABEL_MAP, TITLE_MODE_MAP } from 'modules/meals/constants';
import { IValidatorFn, makeRequiredValidator } from '@pay/common-utils';
import { ITransKey } from 'startup/i18n';
import { useTranslation } from 'startup/utils';
import { formatPhoneRifm, userNameValidator } from 'modules/user-management/users/ui/edit/utils';
import { COUNTRY_PHONE_CODE, ECountryCode } from 'modules/common/format-phone';
import { EFormField, EMode, FormValues } from 'modules/meals/types';
import { BOMealUserDto, RoleRespDto } from 'apis-generated/mapper-sso-admin';

interface Props {
  onClose: () => void;
  mode: EMode;
  control: Control<any>;
  formState: FormState<FormValues>;
  userCanEdit?: boolean;
  onStartEdit?: () => void;
  handleDeleteUser: () => void;
  originalUser: BOMealUserDto;
  handleRolesChange: (event: unknown, newRoles: RoleRespDto[] | null) => void;
  roles: RoleRespDto[];
  rolesOptions: RoleRespDto[];
  validateEmail: IValidatorFn<string, string>;
  validatePhone: IValidatorFn<string, string>;
  handleNext: () => void;
}

export const UserForm: FC<Props> = ({
  control,
  formState,
  mode,
  userCanEdit,
  onStartEdit,
  handleDeleteUser,
  onClose,
  originalUser,
  handleRolesChange,
  roles,
  rolesOptions,
  validateEmail,
  validatePhone,
  handleNext,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle> {t(TITLE_MODE_MAP[mode])}</DialogTitle>
      <DialogContent dividers sx={{ py: 3, px: 4 }}>
        <Box mb={2}>
          <Typography gutterBottom>{t('meal_admin_editor_group_personal')}</Typography>
          <Divider />
        </Box>

        <Controller
          name={EFormField.Firstname}
          control={control}
          rules={{ validate: makeRequiredValidator<ITransKey>('validation_required') }}
          render={({ field }) => (
            <TextField
              size="small"
              margin="dense"
              disabled={mode === EMode.View || formState.isSubmitting}
              label={t(FIELD_LABEL_MAP[EFormField.Firstname])}
              autoFocus
              fullWidth
              {...makeRhfMuiTextFieldProps(formState.errors.firstName, t)}
              {...field}
            />
          )}
        />
        <Controller
          name={EFormField.Lastname}
          control={control}
          rules={{ validate: makeRequiredValidator<ITransKey>('validation_required') }}
          render={({ field }) => (
            <TextField
              size="small"
              margin="dense"
              disabled={mode === EMode.View || formState.isSubmitting}
              label={t(FIELD_LABEL_MAP[EFormField.Lastname])}
              autoFocus
              fullWidth
              {...makeRhfMuiTextFieldProps(formState.errors.lastName, t)}
              {...field}
            />
          )}
        />
        <Controller
          name={EFormField.Email}
          control={control}
          rules={{ validate: validateEmail }}
          render={({ field }) => (
            <TextField
              type="email"
              size="small"
              margin="dense"
              disabled={mode === EMode.View || formState.isSubmitting}
              label={t(FIELD_LABEL_MAP[EFormField.Email])}
              fullWidth
              {...makeRhfMuiTextFieldProps(formState.errors.email, t)}
              {...field}
            />
          )}
        />
        <Controller
          name={EFormField.Phone}
          control={control}
          // rules={{ validate: validatePhone }}
          render={({ field }) => (
            <RifmTextField
              rifm={{
                format: formatPhoneRifm,
                accept: /\d/g,
              }}
              type="tel"
              size="small"
              margin="dense"
              disabled={mode === EMode.View || formState.isSubmitting}
              label={t(FIELD_LABEL_MAP[EFormField.Phone])}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    +{COUNTRY_PHONE_CODE[ECountryCode.Kk]}
                  </InputAdornment>
                ),
              }}
              {...makeRhfMuiTextFieldProps(formState.errors.phone, t)}
              {...field}
            />
          )}
        />
        <Controller
          name={EFormField.Position}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              margin="dense"
              disabled={mode === EMode.View || formState.isSubmitting}
              label={t(FIELD_LABEL_MAP[EFormField.Position])}
              autoFocus
              fullWidth
              {...makeRhfMuiTextFieldProps(formState.errors.position, t)}
              {...field}
            />
          )}
        />
        <Box mb={2}>
          <Typography gutterBottom>{t('user_editor_group_login_info')}</Typography>
          <Divider />
        </Box>
        <Controller
          name={EFormField.Username}
          control={control}
          rules={{ validate: userNameValidator }}
          render={({ field }) => (
            <TextField
              size="small"
              margin="dense"
              disabled={mode === EMode.Edit || mode === EMode.View}
              label={t(FIELD_LABEL_MAP[EFormField.Username])}
              fullWidth
              autoComplete="off"
              {...makeRhfMuiTextFieldProps(formState.errors.username, t)}
              {...field}
            />
          )}
        />
        <Box mb={2}>
          <Typography gutterBottom>{t('user_editor_group_roles')}</Typography>
          <Divider />
        </Box>
        <Autocomplete<RoleRespDto, true>
          multiple
          value={roles}
          onChange={handleRolesChange}
          size="small"
          options={rolesOptions}
          filterSelectedOptions
          disabled={mode === EMode.View || formState.isSubmitting}
          getOptionLabel={(it) => it.name}
          noOptionsText={t('common_table_empty')}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip size="small" label={option.name} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField margin="dense" {...params} label={t('user_field_roles')} />
          )}
        />
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button disableElevation variant="text" onClick={onClose}>
          {t('common_close')}
        </Button>
        <Box>
          {mode === EMode.View && userCanEdit && (
            <Button disableElevation variant="contained" color="primary" onClick={onStartEdit}>
              {t('common_edit')}
            </Button>
          )}
          {mode === EMode.Edit && userCanEdit && originalUser && (
            <Button
              startIcon={<Delete />}
              variant="contained"
              color="primary"
              onClick={handleDeleteUser}
            >
              {t('common_delete')}
            </Button>
          )}
          <Button
            form="MealEditForm"
            // loading={formState.isSubmitting}
            disableElevation
            onClick={handleNext}
            disabled={!formState.isValid}
            variant="outlined"
            color="primary"
          >
            {t('common_next')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};
