import { styled, Tab } from '@mui/material';
import { Page, PageTitle, Tabs } from '@mapper/admin-ui-kit';
import React, { useState, FC } from 'react';
import { useTranslation } from 'startup/utils';

import { AgreementHistoryPane } from './history/AgreementHistoryPane';
import { AgreementChangeRequestsPane } from './changeRequests/AgreementChangeRequestsPane';

// export const TabsRoot = styled('div')(({ theme }) => ({
//   padding: `0 ${theme.spacing(6)}`,
//   marginBottom: theme.spacing(2),
//   flex: 0,
// }));

export enum ETab {
  History = '',
  ChangeRequests = 'changeRequests',
}

interface IProps {}

export const AgreementPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(ETab.History);

  const handleChange = (event: React.ChangeEvent<{}>, tab: ETab) => {
    setTab(tab);
  };

  const renderTabContent = () => {
    return (
      <>
        <Page>
          <PageTitle>{t('agreement_page_title')}</PageTitle>
          <Tabs
            value={tab}
            indicatorColor="primary"
            onChange={handleChange}
            textColor="primary"
            variant="standard"
            style={{ padding: '0 6px', marginBottom: 2, flex: 0 }}
          >
            <Tab value={ETab.History} label={t('agreement_tab_history')} />
            <Tab value={ETab.ChangeRequests} label={t('agreement_tab_change_requests')} />
          </Tabs>
          {tab === ETab.History && <AgreementHistoryPane />}
          {tab === ETab.ChangeRequests && <AgreementChangeRequestsPane />}
        </Page>
      </>
    );
  };

  return <>{renderTabContent()}</>;
};
