import { IFetcher } from '@pay/data-fetching';
import {
  PlanInstallationApkForTerminalRequestBody,
  TerminalApkControllerApiInterface,
} from 'apis-generated/mapper-documents-admin';
import { TerminalApiInterface } from 'apis-generated/mapper-sso-admin';

export const makePostTerminalApkApi = (fetcher: IFetcher): TerminalApkControllerApiInterface => {
  return {
    apkList: () => fetcher.get('bo/terminal/apklist'),
    uploadApk: () => {
      throw new Error('not implemented');
    },
    getTerminalListByIinOrBin: ({ iinOrBin }) => fetcher.get(`/bo/terminal/list/${iinOrBin}`),
    getApkInstalls: () => {
      throw new Error('not implemented');
    },
    planInstallationApkForTerminal: ({ terminalPlanApkInstallRequest }) =>
      fetcher.putJson(`/bo/terminal/apkinstall`, terminalPlanApkInstallRequest),
    deleteApk: () => {
      throw new Error('not implemented');
    },
  };
};

export const makeDeletePosTerminalApi = (fetcher: IFetcher): TerminalApiInterface => {
  return {
    deactivateTerminalById: ({ id }) => fetcher.delete(`/bo/terminal/${id}`),
  };
};
