import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';
import { Page, PageTitle } from '@mapper/admin-ui-kit';
import React, { useMemo, FC } from 'react';

import { useTranslation } from 'startup/utils';
import { BlackboxData } from 'apis-generated/mapper-processing-admin';
import { getFinOrganizationColumns } from './tableDefs';
import { useFinOrganizationsService } from '../module';

interface IProps {}

export const FinOrganizationsPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const { fetchOrganizationsTable } = useFinOrganizationsService();

  const columns = useMemo(() => getFinOrganizationColumns(t), [t]);

  const { tableProps, toolBarProps } = useTable<BlackboxData>({
    fetchData: fetchOrganizationsTable,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.bin!,
  });

  return (
    <Page>
      <PageTitle>{t('fin_organizations_page_title')}</PageTitle>
      <TableToolbar<BlackboxData> {...toolBarProps} columns={columns} />
      <DataTable<BlackboxData> {...tableProps} />
    </Page>
  );
};
