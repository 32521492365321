import { observer } from 'mobx-react';
import React, { FC } from 'react';
import * as AuthState from '../core/AuthState';
import { EAuthStage } from '../data';
import { useAuthStore } from '../module';
import { AuthCodeForm } from './AuthCodeForm';
import { AuthForm } from './AuthForm';
import { AuthFormWrapper } from './AuthFormWrapper';

interface IProps {
  backgroundUrl: string;
}

export const AuthPage: React.FC<React.PropsWithChildren<IProps>> = observer(({ backgroundUrl }) => {
  const authStore = useAuthStore();
  const initialState = authStore.initialState!;

  const renderBody = () => {
    switch (initialState.stageContext.stage) {
      case AuthState.InitialStage.Login:
        return <AuthForm onSubmit={authStore.logIn} logOutReason={initialState.logOutReason} />;
      case AuthState.InitialStage.SecondFactor:
        return (
          <AuthCodeForm
            onSubmit={authStore.verify2AuthCode}
            authResponse={initialState.stageContext.authResponse}
          />
        );
    }
  };

  return <AuthFormWrapper backgroundUrl={backgroundUrl}>{<>{renderBody()}</>}</AuthFormWrapper>;
});
