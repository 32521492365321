import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';

export const TEtoPromise = <E, A>(te: TE.TaskEither<E, A>): T.Task<A> =>
  pipe(
    te,
    T.map(
      (res): A =>
        E.isRight(res)
          ? (Promise.resolve(res.right) as unknown as A)
          : (Promise.reject(res.left) as unknown as A)
    )
  );

export const extractSome = <T>(some: O.Some<T>) => some.value;

export const filterSomes = A.filter(O.isSome);
