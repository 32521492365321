import { FilterType, TextFilterFormat, Column } from '@mapper/admin-data-table';
import { TFunction } from 'i18next';
import { DATE_FORMAT } from 'modules/common/constants';
import React from 'react';
import { formatDate } from 'modules/common/format';
import { BackofficeAuditRespDto } from 'apis-generated/mapper-sso-admin';

export const getColumns = (t: TFunction): Column<BackofficeAuditRespDto>[] => {
  return [
    {
      accessor: 'createdAt',
      Header: t('logs_table_column_date') as string,
      Cell: ({ value }) => <>{formatDate(value, DATE_FORMAT.DD_MM_YYYY_HH_mm)}</>,
      customFilter: {
        type: FilterType.DateRange,
        withTime: true,
      },
    },
    {
      accessor: 'username',
      Header: t('logs_table_column_user') as string,
      customFilter: {
        field: 'user.userName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
      sorting: {
        rsqlOptions: {
          field: 'user.userName',
        },
      },
    },
    {
      accessor: 'details',
      Header: t('logs_table_column_description') as string,
      width: 200,
      customFilter: {
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
  ];
};
