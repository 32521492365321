import { Column } from '@mapper/admin-data-table';
import { AgreementHistoryRespDto } from 'apis-generated/mapper-sso-admin';
import React from 'react';
import { IAppTransFunction } from 'startup/utils';
import { DATE_FORMAT } from 'modules/common/constants';
import { formatDate } from 'modules/common/format';
import { Button } from '@mui/material';
import { EApiLang } from 'startup/i18n';

export const getAgreementHistoryColumns = (
  t: IAppTransFunction,
  onClick: (agreementHistoryRespDto: AgreementHistoryRespDto, lang: EApiLang) => void
): Column<AgreementHistoryRespDto>[] => {
  return [
    {
      accessor: 'updateDate',
      sorting: {
        rsqlOptions: {
          field: 'updatedAt',
        },
      },
      Header: t('agreement_column_update_date'),
      Cell: ({ row: { original }, value }) => (
        <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
      ),
    },
    {
      accessor: 'version',
      Header: t('agreement_column_version'),
    },
    {
      accessor: 'nameKz',
      sortable: false,
      Header: t('agreement_column_content_kk'),
      Cell: ({ row: { original }, value }) => (
        <Button onClick={() => onClick(original, EApiLang.Kk)}>{value}</Button>
      ),
    },
    {
      accessor: 'nameRu',
      sortable: false,
      Header: t('agreement_column_content_ru'),
      Cell: ({ row: { original }, value }) => (
        <Button onClick={() => onClick(original, EApiLang.Ru)}>{value}</Button>
      ),
    },
    {
      accessor: 'active',
      Header: t('agreement_column_status'),
      Cell: ({ row: { original }, value }) => (
        <>{value ? t('agreement_status_active') : t('agreement_status_inactive')}</>
      ),
    },
  ];
};
