import * as AsyncState from 'async-state-ts/AsyncState';

import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, AutocompleteProps, Link, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'startup/utils';
import { useLatest, useMountedState } from 'react-use';

type AsyncInfinityAutoCompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'> & {
  onLoadItems: (symbol: string) => Promise<T[]>;
  symbol: string;
  inputProps?: TextFieldProps;
  preLoadedOptions?: T[];
};

export const AsyncInfinityAutoComplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AsyncInfinityAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const {
    value,
    onLoadItems,
    symbol,
    inputProps,
    fullWidth = true,
    preLoadedOptions = [],
    ...autoCompleteProps
  } = props;
  const { t } = useTranslation();

  type State = AsyncState.AsyncState<unknown, T[]>;

  const [state, setState] = useState<State>(
    preLoadedOptions ? AsyncState.success(preLoadedOptions) : AsyncState.loading()
  );
  const [attempt, setAttempt] = useState(0);
  const isMounted = useMountedState();

  const invocationId = useRef(0);

  const onLoadItemsLatest = useLatest(onLoadItems);

  useEffect(() => {
    if (!symbol) {
      setState(AsyncState.success(preLoadedOptions));
      return;
    }

    invocationId.current++;
    const id = invocationId.current;

    setState(AsyncState.loading());

    onLoadItemsLatest.current(symbol).then(
      (res) => {
        console.log(res);

        isMounted() && invocationId.current === id && setState(AsyncState.success(res));
      },
      (err) => isMounted() && invocationId.current === id && setState(AsyncState.error(err))
    );
  }, [onLoadItemsLatest, isMounted, attempt, symbol]);

  const handleFocus = () => {
    if (!state.value?.length) {
      onLoadItemsLatest.current(symbol || '').then(
        (res) => {
          isMounted() && setState(AsyncState.success(res));
        },
        (err) => isMounted() && setState(AsyncState.error(err))
      );
    }
  };

  const renderHelperText = () => {
    if (state.error) {
      return (
        <>
          {t('common_data_download_error')}.{' '}
          <Link style={{ cursor: 'pointer' }} onClick={() => setAttempt((a) => a + 1)}>
            {t('common_retry')}
          </Link>
        </>
      );
    }

    return inputProps?.helperText;
  };

  return (
    <Autocomplete
      loading={state.loading}
      onFocus={handleFocus}
      fullWidth={fullWidth}
      {...autoCompleteProps}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            {...inputProps}
            helperText={renderHelperText()}
          />
        );
      }}
      options={state.value ?? []}
    />
  );
};

const a = [
  {
    clientId: '0d3ada0a-c3d6-4123-9b42-00e357bc2583',
    firstName: 'САТИНОВ',
    lastName: 'АКЖОЛ',
    username: '77712087362',
    iin: '990622351447',
    lastActive: 1669193423097,
    active: true,
  },
  {
    clientId: '3992645c-c3db-471a-8a38-5f0e9d19cefc',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087368',
    iin: '990622351447',
    lastActive: 1664771778165,
    active: true,
  },
  {
    clientId: 'be39ce48-6e43-4b2d-a795-2d2f2b454196',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087371',
    iin: '990622351447',
    lastActive: 1664780560970,
    active: true,
  },
  {
    clientId: '12679697-3119-4183-bbae-17cf1c4118ba',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087379',
    iin: '990622351447',
    lastActive: 1664781165893,
    active: true,
  },
  {
    clientId: '3102f1fe-dd8e-4d27-b059-60db8e18d340',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087365',
    iin: '990622351447',
    lastActive: 1664521477136,
    active: true,
  },
  {
    clientId: '28c3a36a-d988-4676-81ba-f50ac6f4696f',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087369',
    iin: '990622351447',
    lastActive: 1664774330223,
    active: true,
  },
  {
    clientId: '6e149b46-6631-4394-8815-5ce25374785f',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087377',
    iin: '990622351447',
    lastActive: 1664880692552,
    active: true,
  },
  {
    clientId: '7bcf4d39-d68b-4d36-a81d-fd8b6a48a09a',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087370',
    iin: '990622351447',
    lastActive: 1664775033829,
    active: true,
  },
  {
    clientId: 'b672afe8-ed4b-40d6-9127-096d0a74786a',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77773232323',
    iin: '990622351447',
    lastActive: null,
    active: true,
  },
  {
    clientId: 'a48a2f5e-8879-4268-9df4-284de1bb43be',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77779191919',
    iin: '990622351447',
    lastActive: 1649856558492,
    active: true,
  },
  {
    clientId: '1c087967-aadd-4e6e-8890-0894fe5e08ec',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087366',
    iin: '990622351447',
    lastActive: 1664768406883,
    active: true,
  },
  {
    clientId: 'd745abc5-be3b-48d2-920e-83928e1da4e0',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77712087367',
    iin: '990622351447',
    lastActive: 1664771511512,
    active: true,
  },
  {
    clientId: '2d72d7c7-43c1-426e-99a5-fb9a2c07d53a',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77773737373',
    iin: '990622351447',
    lastActive: null,
    active: true,
  },
  {
    clientId: 'c28ff8f5-27ff-4c66-b060-1dbcabcf88f1',
    firstName: 'АКЖОЛ',
    lastName: 'САТИНОВ',
    username: '+77773030303',
    iin: '990622351447',
    lastActive: null,
    active: true,
  },
  {
    clientId: '5c15232f-0ae2-43a2-b6cf-2193eb5d6373',
    firstName: 'Абай',
    lastName: 'Абенов',
    username: '+77772626262',
    iin: '990622351447',
    lastActive: 1671528458014,
    active: true,
  },
  {
    clientId: '5caee924-c97f-4d17-954f-fe94113c8fad',
    firstName: 'Абай',
    lastName: 'Абенов',
    username: '+77712087362',
    iin: '990622351447',
    lastActive: 1684913871621,
    active: true,
  },
  {
    clientId: '1387afbd-a3b3-4ae4-8dc0-d5ec91b75279',
    firstName: 'Абай',
    lastName: 'Абенов',
    username: '+77777262626',
    iin: '990622351447',
    lastActive: null,
    active: true,
  },
  {
    clientId: 'f47050cc-dc65-4970-8d6d-62c07313d188',
    firstName: 'Абай',
    lastName: 'Абенов',
    username: '+77772626226',
    iin: '990622351447',
    lastActive: 1666178373917,
    active: true,
  },
  {
    clientId: 'a693f144-8a18-4f1d-947a-c91c8cc69a72',
    firstName: 'Абай',
    lastName: 'Абенов',
    username: '+77712626262',
    iin: '990622351447',
    lastActive: 1666264555005,
    active: true,
  },
  {
    clientId: '12a05af8-6c53-40c0-8bc3-b563316303bf',
    firstName: 'А',
    lastName: 'А',
    username: '+77777777777',
    iin: '990622351447',
    lastActive: 1681724474786,
    active: true,
  },
];
