import React from 'react';
import { DetailsCard, DetailsCardDefinitions } from '@mapper/admin-ui-kit';
import { BackofficeTestUserRespDto } from 'apis-generated/mapper-sso-admin';
import { useTranslation } from 'startup/utils';
import { formatDate } from 'modules/common/format';

type Props = {
  testClient: BackofficeTestUserRespDto;
  title?: string;
};

export const TestClientDetailsCard = (props: Props) => {
  const { testClient, title } = props;
  const { t } = useTranslation();

  const definitions = (
    <DetailsCardDefinitions
      items={[
        { title: t('test_clients_number'), value: testClient.username },
        { title: t('test_clients_code'), value: testClient.code },
      ]}
    />
  );

  return title ? <DetailsCard title={title}>{definitions}</DetailsCard> : definitions;
};
