import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';
import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'startup/utils';
// import { DateTimePicker } from '@mui/x-date-pickers';
import { pipe } from 'fp-ts/es6/function';
import {
  DetailsCard,
  DetailsCardDefinitions,
  LoadingOverlay,
  useNotificationsStore,
} from '@mapper/admin-ui-kit';
import * as TE from 'fp-ts/TaskEither';

import { requiredValidator } from 'modules/common/validators';
import { DATE_FORMAT } from 'modules/common/constants';
import { useTestClientsService } from '../module';
import { useTaskEitherImmediate } from 'modules/common/async/hooks';
import { mapAsyncState } from 'modules/common/async';
import { ErrorAlert } from 'modules/common/ui/errors';
import { TestClientDetailsCard } from './TestClientDetailsCard';

interface IProps extends Omit<DialogProps, 'children'> {
  onClose: () => void;
  isEdit?: boolean;
  onAdded?: () => void;
}

export const AddTestClientModal: FC<IProps> = ({ onClose, isEdit, onAdded, ...dialogProps }) => {
  const { t } = useTranslation();

  const { addTestClient } = useTestClientsService();

  const { state, retry } = useTaskEitherImmediate(addTestClient);

  useEffect(() => {
    if (state.value) {
      onAdded?.();
    }
  }, [state, onAdded]);

  return (
    <>
      <Dialog {...dialogProps} onClose={onClose} PaperProps={{ style: { width: 488 } }}>
        <DialogTitle>{t('test_clients_generate')}</DialogTitle>
        <DialogContent dividers>
          {mapAsyncState(state, {
            error: (e) => <ErrorAlert error={e} onRetry={retry} />,
            loading: (_) => <CircularProgress />,
            success: (state) => (
              <TestClientDetailsCard
                testClient={state}
                title={t('test_clients_generate_success')}
              />
            ),
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('common_close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
