import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { Alert } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { combineValidators, requiredValidator } from '@pay/common-utils';
import { Button, makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';

import { useTranslation } from '../utils';
import { passwordValidator } from './utils';

enum EField {
  Password = 'password',
  PasswordConfirm = 'passwordConfirm',
}

export type IChangePasswordFormValues = Record<EField, string>;

interface ChangePasswordProps {
  onChangePassword: (values: IChangePasswordFormValues) => void;
}
export const ChangePassword: React.FC<ChangePasswordProps> = ({ onChangePassword }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    formState: { errors },
  } = useForm<IChangePasswordFormValues>();

  const handleSubmitInternal = useCallback(
    async (values: IChangePasswordFormValues) => {
      await onChangePassword(values);
    },
    [onChangePassword]
  );

  const validateConfirmation = useMemo(
    () =>
      combineValidators(requiredValidator, (value: string) => {
        return getValues().password === value ? undefined : 'validation_passwords_not_matching';
      }),
    [getValues]
  );

  return (
    <>
      <Card component="form" onSubmit={handleSubmit(handleSubmitInternal)}>
        <CardHeader
          title={t('settings_change_pass_title')}
          titleTypographyProps={{ variant: 'h6' }}
        />
        <Divider />
        <CardContent>
          <Box mb={2}>
            <Alert severity="info">{t('settings_change_pass_description')}</Alert>
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Controller
                rules={{ validate: passwordValidator }}
                name={EField.Password}
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    fullWidth
                    type="password"
                    label={t('settings_change_pass_password')}
                    {...makeRhfMuiTextFieldProps(errors.password, t)}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                rules={{ validate: validateConfirmation }}
                name={EField.PasswordConfirm}
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    fullWidth
                    type="password"
                    label={t('settings_change_pass_password_repeat')}
                    {...makeRhfMuiTextFieldProps(errors.passwordConfirm, t)}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            loading={formState.isSubmitting}
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            size="medium"
          >
            {t('settings_change_pass_btn')}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
