/**
 * TODO: This is naive implementation. Non optimized!
 */
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getEnumValues, requiredValidator } from '@pay/common-utils';
import { Button, makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';

import { RoleRespDto } from 'apis-generated/mapper-sso-admin';
import { IRemoteError } from 'modules/common/store';

import { EPermissionType } from '../../../constants';
import { IRoleValues } from '../../data/types';
import { RolePermissionsEditor } from './permissions/PermissionsEditor';
import { matchI } from 'modules/common/match';
import { extractErrorMessage } from 'modules/common/errors';
import { ErrorAlert } from 'modules/common/ui/errors';
import { useTranslation } from 'startup/utils';
import { ITransKey } from 'startup/i18n';
import { TFunction } from 'i18next';

enum EField {
  Name = 'name',
  Permissions = 'permissions',
}

export enum EMode {
  Create,
  Edit,
  View,
}

type IFormValues = Record<EField, string>;

// TODO: Не хватает description в измнении/добавлении ролей

interface IProps {
  onClose: () => void;
  mode: EMode;
  originalRole?: RoleRespDto;
  onStartEdit?: () => void;
  canEdit?: boolean;
  onSave?: (
    values: IRoleValues
  ) => TE.TaskEither<IRemoteError | { type: 'role-already-exists' }, unknown>;
}
export const RoleEditor: FC<IProps> = ({
  onClose,
  onSave,
  mode,
  originalRole,
  canEdit,
  onStartEdit,
}) => {
  const { t } = useTranslation();
  // const classes = useStyles();
  const [error, setError] = useState<IRemoteError | { type: 'role-already-exists' } | undefined>();

  const originalPermissions = useMemo(
    () => originalRole?.permissions?.map((it) => it.name as EPermissionType) ?? [],
    [originalRole]
  );

  const [checkedState, setCheckedState] = useState<Record<EPermissionType, boolean>>(() =>
    getEnumValues<EPermissionType>(EPermissionType).reduce(
      (acc, perm) => ({ ...acc, [perm]: originalPermissions.includes(perm) }),
      {} as any
    )
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    formState,
  } = useForm<RoleRespDto>({
    defaultValues: {
      name: originalRole?.name,
      permissions: originalRole?.permissions,
    },
  });
  // TODO: CHECK CORRECT TYPINGS
  const handleSubmitInternal = useCallback(
    async (values: IFormValues) => {
      setError(undefined);
      if (!onSave) {
        return;
      }
      const flatPerms = Object.keys(checkedState).filter(
        (key) => checkedState[key as EPermissionType]
      ) as EPermissionType[];

      return pipe(
        onSave({
          name: values.name,
          permissions: flatPerms,
        }),
        TE.fold(
          (e) => {
            setError(e);
            return T.of(undefined);
          },
          (_) => {
            onClose();
            return T.of(undefined);
          }
        )
      )();
    },
    [checkedState, onClose, onSave]
  ) as any;

  const errorText = error
    ? (matchI(error)({
        'role-already-exists': () => t('role_already_exists'),
        unknown: (err) => extractErrorMessage(err, t),
      }) as string)
    : '';

  return (
    <>
      <DialogTitle> {t(TITLE_MODE_MAP[mode])}</DialogTitle>
      <DialogContent dividers sx={{ py: 3, px: 4 }}>
        <form id="RoleEditForm" onSubmit={handleSubmit(handleSubmitInternal)}>
          <Grow in={!!error} unmountOnExit>
            <Box mb={3}>
              <ErrorAlert error={errorText} />
            </Box>
          </Grow>
          <Box mb={1}>
            <Controller
              name={EField.Name}
              control={control}
              rules={{ validate: requiredValidator }}
              render={({ field }) => (
                <TextField
                  size="small"
                  disabled={mode === EMode.View || formState.isSubmitting}
                  label={t('roles_field_name')}
                  autoFocus
                  fullWidth
                  {...makeRhfMuiTextFieldProps(errors.name, t as TFunction)}
                  {...field}
                />
              )}
            />
          </Box>

          {/* <Controller
            name={EField.Description}
            control={control}
            rules={{ validate: requiredValidator }}
            as={
              <TextField
                disabled={props.mode === EMode.View || formState.isSubmitting}
                fullWidth
                label={t('roles_field_description')}
                multiline
                rows={3}
                variant="outlined"
                {...makeRhfMuiTextFieldProps(errors.description, t)}
              />
            }
          /> */}
          <Box mb={2}>
            <Typography color="textSecondary" variant="body2">
              {t('role_permissions_description_1')}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {t('role_permissions_description_2')}
            </Typography>
          </Box>

          <RolePermissionsEditor
            readonly={mode === EMode.View || formState.isSubmitting}
            permissionsState={checkedState}
            onPermissionsStateChange={setCheckedState}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button disableElevation variant="contained" onClick={onClose}>
          {t('common_cancel')}
        </Button>
        {mode === EMode.View && canEdit && (
          <Button color="primary" disableElevation variant="contained" onClick={onStartEdit}>
            {t('common_edit')}
          </Button>
        )}
        {mode !== EMode.View && (
          <Button
            form="RoleEditForm"
            loading={formState.isSubmitting}
            disableElevation
            type="submit"
            disabled={Object.keys(errors).length > 0}
            variant="contained"
            color="primary"
          >
            {t('common_save')}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

const TITLE_MODE_MAP: Record<EMode, ITransKey> = {
  [EMode.Create]: 'roles_editor_create_title',
  [EMode.View]: 'roles_editor_view_title',
  [EMode.Edit]: 'roles_editor_edit_title',
};
