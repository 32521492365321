import { IFetcher } from '@pay/data-fetching';
// import { AuthControllerApiInterface, AuthRpModel, UserControllerApiInterface } from '@/api';
import { constUndefined, pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { encode } from 'js-base64';
// import * as ApiError from '@/modules/common/api/ApiError';
// import { mapFetcherResultEither } from '@/modules/common/fetcher';
// import { matchServerError } from '@/modules/common/store';
// import { LoginSessionExpiredSession, SecondFactorVerificationError } from './errors';
import { mapFetcherResultEither } from 'modules/common/fetcher';
// import { matchServerError } from 'modules/common/errors';
import { map2FaVerificationError } from './utils';
import {
  AuthResponse,
  BackofficeUserApiInterface,
  VerifyApiInterface,
} from 'apis-generated/mapper-sso-admin';

export const makeAuthService = (
  fetcher: IFetcher,
  _verifyApi: VerifyApiInterface,
  _backofficeUserApi: BackofficeUserApiInterface
) => ({
  login: (login: string, password: string) => {
    const credHash = encode(login + ':' + password);
    const loginTask = () => {
      return fetcher.post<AuthResponse>('bo/auth', {
        headers: {
          Authorization: `Basic ${credHash}`,
        },
        credentials: 'include',
      });
    };
    return pipe(loginTask, T.map(mapFetcherResultEither));
  },
  logout: () => pipe(() => _backofficeUserApi.logout(), T.map(mapFetcherResultEither)),
  verifyCode: (codeDto: { authSessionId: string; code: string }) => {
    return pipe(
      () =>
        _verifyApi.verify2FABackoffice({
          verifyRequest: codeDto,
        }),
      T.map(mapFetcherResultEither),
      TE.mapLeft(map2FaVerificationError)
    );
  },

  changePassword: (newPassword: string, code: string) =>
    pipe(
      () =>
        _backofficeUserApi.changeCurrentUserKey({
          backofficeChangeCurrentKeyReqDto: { key: newPassword, code: code },
        }),
      T.map(mapFetcherResultEither),
      TE.map(constUndefined)
    ),

  fetchCurrentUser: () =>
    pipe(
      () => _backofficeUserApi.getCurrentUser(),
      T.map(mapFetcherResultEither)
      // TE.map(mapToCurrentUserModel)
    ),

  generate2FA: (code: string) =>
    pipe(
      () => _backofficeUserApi.generateUser2FA({ backofficeGenerate2FAUserReqDto: { code } }),
      T.map(mapFetcherResultEither)
    ),

  validate2FA: (code: string, tokenId: string) =>
    pipe(
      () =>
        _backofficeUserApi.acceptUser2FA({
          backofficeAccept2FAUserReqDto: { codeNew: code, generatedID2FA: tokenId },
        }),
      T.map(mapFetcherResultEither)
    ),
});
export type AuthService = ReturnType<typeof makeAuthService>;
