import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';
import { Page, PageTitle } from '@mapper/admin-ui-kit';
import React, { useMemo, FC } from 'react';
import { useTranslation } from 'startup/utils';

import { useNonMonetaryService } from '../module';
import { getBusinessAccountsColumns } from './details/BusinessAccountInfoPane/tableDefs';
import { LegalInfo } from 'apis-generated/mapper-processing-admin';

interface IProps {}

export const BusinessAccountsPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const { fetchLegalsTable } = useNonMonetaryService();
  const columns = useMemo(() => getBusinessAccountsColumns(t), [t]);

  const { tableProps, toolBarProps } = useTable<LegalInfo>({
    fetchData: fetchLegalsTable,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.extId,
  });

  return (
    <Page>
      <PageTitle>{t('business_accounts_page_title')}</PageTitle>
      <TableToolbar<LegalInfo> {...toolBarProps} columns={columns} />
      <DataTable<LegalInfo> {...tableProps} />
    </Page>
  );
};
