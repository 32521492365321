import { Box, Button, Grow, Alert, AlertTitle } from '@mui/material';
import React from 'react';

import { useTranslation } from 'startup/utils';
import { extractErrorMessage } from '../errors';
import { IRemoteError } from '../store';

// const useStyles = makeStyles((theme) => ({
//   errors: {
//     whiteSpace: 'pre-line',
//   },
// }));

interface IProps {
  error: IRemoteError | string | undefined;
  onRetry?: () => Promise<void> | void;
}
export const ErrorAlert: React.FC<IProps> = ({ error, onRetry }) => {
  const { t } = useTranslation();
  // const classes = useStyles();

  return (
    <Grow in={!!error} unmountOnExit>
      <Box mb={2}>
        <Alert severity="error">
          <AlertTitle>{t('common_error')}</AlertTitle>
          <div style={{ whiteSpace: 'pre-line' }}>
            {error && (typeof error === 'string' ? error : extractErrorMessage(error, t))}
          </div>
          {onRetry && (
            <Box pt={1}>
              <Button onClick={onRetry} variant="contained" color="primary" size="small">
                {t('common_retry')}
              </Button>
            </Box>
          )}
        </Alert>
      </Box>
    </Grow>
  );
};
