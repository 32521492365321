import { useNotificationsStore } from '@mapper/admin-ui-kit';
import React, { useCallback, useState, FC } from 'react';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';

import { ChangePassword, IChangePasswordFormValues } from './ChangePassword';
import { useAuthStore } from '../../module';
import { useAuthModals } from '../AuthProvider';
import { useTranslation } from '../utils';

interface IProps {}
export const ChangePasswordContainer: FC<IProps> = () => {
  const { showSecondAuthModal } = useAuthModals();
  const { showNotification } = useNotificationsStore();
  const { t } = useTranslation();
  const authStore = useAuthStore();
  const [counter, setCounter] = useState(1);

  const handleChangePassword = useCallback(
    (values: IChangePasswordFormValues) => {
      showSecondAuthModal({
        onSubmit: (code) =>
          pipe(
            authStore.changePassword(values.password, code),
            TE.fold(T.of, (_) => {
              setCounter((c) => c + 1);
              showNotification({
                text: t('settings_change_pass_success'),
                options: { variant: 'success' },
              });
              return T.of(_);
            })
          )(),
      });
    },
    [authStore, showNotification, showSecondAuthModal, t]
  );
  return (
    <>
      <ChangePassword key={counter} onChangePassword={handleChangePassword} />
    </>
  );
};
