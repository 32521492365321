import { IRestApi, RestApi } from '@mapper/admin-resources';
import { ClientRespDto } from 'apis-generated/mapper-sso-admin';

export enum ETempClientDtoStatusEnum {
  Active = 'active',
  Blocked = 'blocked',
}

export interface ITempClientDtoRegistration {
  device: string;
  registrationDate: number;
}

/*
ИИН, ФИО, номер телефона, кол-во устройств, кол-во WS, дата подключения*/

export interface IClientsApi extends IRestApi<ClientRespDto> {}

export class ClientsApi extends RestApi<ClientRespDto> implements IClientsApi {}
