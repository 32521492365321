import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'startup/utils';
import { TerminalApk, TerminalInfoResponse } from 'apis-generated/mapper-documents-admin';
import { usePosTerminalsService } from 'modules/pos-terminals';
import { Autocomplete } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useTaskEither, useTaskEitherImmediate } from 'modules/common/async/hooks';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { ErrorAlert } from 'modules/common/ui/errors';
import { Controller, useForm } from 'react-hook-form';
import { requiredAnyValidator, requiredValidator } from 'modules/common/validators';
import { useNotificationsStore } from '@mapper/admin-ui-kit';

interface IFormValues {
  plannedApkVersion: TerminalApk;
}

type Props = {
  info: TerminalInfoResponse;
  title?: string;
  onChanged: () => void;
  open: boolean;
};

export const ApkVersionCard = (props: Props) => {
  const { open, onChanged, info, ...dialogProps } = props;
  const { t } = useTranslation();
  const { showNotification } = useNotificationsStore();
  const formRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(open);
  const handleClosing = useCallback(() => {
    setIsOpen(false);
  }, []);

  const service = usePosTerminalsService();
  const { state: apkListState } = useTaskEitherImmediate(() => pipe(service.apkList()));
  const { execute: executeUpdateApkVersion, state: updateApkVersionState } = useTaskEither(
    service.changeTerminalApkVersion
  );
  const handleSubmitInternal = useCallback(
    (values: IFormValues) => {
      pipe(
        service.changeTerminalApkVersion({
          terminalId: info.terminalId!,
          plannedApkId: values.plannedApkVersion.id!,
        }),
        TE.map(onChanged),
        TE.map(() => {
          showNotification({
            options: { variant: 'success' },
            text: t('pos_terminal_apk_edit_version_success', [info.terminalId]),
          });
          setIsOpen(false);
        })
      )();
    },
    [executeUpdateApkVersion, info]
  );

  const { control, handleSubmit, formState } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      plannedApkVersion: undefined,
    },
  });

  return (
    <>
      <Dialog
        open={isOpen}
        {...dialogProps}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: 500,
          },
        }}
      >
        <DialogTitle>{t('pos_terminal_apk_change_version')}</DialogTitle>
        <DialogContent ref={formRef} dividers>
          <ErrorAlert error={updateApkVersionState?.error} />
          <Box
            mb={1}
            id="ApkVersionForm"
            component="form"
            onSubmit={handleSubmit(handleSubmitInternal)}
          >
            <Controller
              name="plannedApkVersion"
              control={control}
              rules={{
                validate: requiredAnyValidator,
              }}
              render={({ field: { ref, onChange, ...other } }) => (
                <Autocomplete
                  ref={ref}
                  fullWidth
                  loading={apkListState.loading}
                  options={apkListState.value ?? []}
                  getOptionLabel={(op) => `${op.versionName} (${op.versionCode})`}
                  renderInput={(params) => <TextField {...params} label="" />}
                  onChange={(e, val) => {
                    onChange(val);
                  }}
                  {...other}
                />
              )}
            ></Controller>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosing} color="primary">
            {t('common_cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            form="ApkVersionForm"
            disabled={!formState.isValid || formState.isSubmitted}
          >
            {t('common_save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
