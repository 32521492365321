import React, { FC, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { WorkspaceStatsRespDto } from 'apis-generated/mapper-processing-admin';
import { ClientsStatsRespDto } from 'apis-generated/mapper-sso-admin';

import { useClientService, useWorkspaceService } from 'modules/clients/module';
import { useTaskEither } from 'modules/common/async/hooks';
import { mapAsyncState } from 'modules/common/async';
import { ErrorAlert } from 'modules/common/ui/errors';
import { useTranslation } from 'startup/utils';

interface IStatisticsPaneProps {
  startDate: Date | null;
  endDate: Date | null;
}

export const StatisticsPane: FC<IStatisticsPaneProps> = ({ startDate, endDate }) => {
  const { t } = useTranslation();

  const clientService = useClientService();
  const workspaceService = useWorkspaceService();

  const {
    execute: updateWorkspaceStats,
    state: workspacesStats,
    retry: workspacesStatsRetry,
  } = useTaskEither(workspaceService.getWorkspaceStats);

  const {
    execute: updateClientsStats,
    state: clientsStats,
    retry: clientsStatsRetry,
  } = useTaskEither(clientService.getClientsStatistics);

  useEffect(() => {
    if (startDate && endDate) {
      updateWorkspaceStats(startDate, endDate);
      updateClientsStats(startDate, endDate);
    }
  }, [endDate, startDate, updateClientsStats, updateWorkspaceStats]);

  const renderClientsStatistics = (clientStats: ClientsStatsRespDto) => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('client_statistics_column_total')}</TableCell>
              <TableCell align="center">{t('client_statistics_column_blocked')}</TableCell>
              <TableCell align="center">{t('client_statistics_column_registered')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">{clientStats.totalUsers}</TableCell>
              <TableCell align="center">{clientStats.blockedUsers}</TableCell>
              <TableCell align="center">{clientStats.registeredWithinPeriod}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderWorkspacesStatistics = (wsStats: WorkspaceStatsRespDto) => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('client_statistics_column_total')}</TableCell>
              <TableCell align="center">{t('client_statistics_column_legal')}</TableCell>
              <TableCell align="center">{t('client_statistics_column_entrepreneur')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">{wsStats.total}</TableCell>
              <TableCell align="center">{wsStats.entrepreneurWithinPeriod}</TableCell>
              <TableCell align="center">{wsStats.legalWithinPeriod}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        {clientsStats && (
          <>
            <Typography variant="h6" gutterBottom>
              {t('client_statistics_clients_table_title')}
            </Typography>
            {mapAsyncState(clientsStats, {
              error: (e) => <ErrorAlert error={e} onRetry={clientsStatsRetry} />,
              loading: () => (
                <Box p={4} textAlign="center">
                  <CircularProgress size={32} />
                </Box>
              ),
              success: (clientStats) => renderClientsStatistics(clientStats),
            })}
          </>
        )}
      </Grid>

      <Grid item xs={6}>
        {workspacesStats && (
          <>
            <Typography variant="h6" gutterBottom>
              {t('client_statistics_ws_table_title')}
            </Typography>
            {mapAsyncState(workspacesStats, {
              error: (e) => <ErrorAlert error={e} onRetry={workspacesStatsRetry} />,
              loading: () => (
                <Box p={4} textAlign="center">
                  <CircularProgress size={32} />
                </Box>
              ),
              success: (wsStats) => renderWorkspacesStatistics(wsStats),
            })}
          </>
        )}
      </Grid>
    </Grid>
  );
};
