import { Column, FilterType, TextFilterFormat } from '@mapper/admin-data-table';
import React, { useCallback } from 'react';
import { IAppTransFunction } from 'startup/utils';
import { BackofficeTestUserRespDto } from 'apis-generated/mapper-sso-admin';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';
import { formatInteger } from '@pay/common-utils';
import { RIFM_PHONE_WITH_PLUS } from 'modules/common/rifm';

export const getTestClientsColumns = (
  t: IAppTransFunction,
  onDeleteTestPhone: (testPhone: BackofficeTestUserRespDto) => void,
  canWrite: boolean
): Column<BackofficeTestUserRespDto>[] => {
  const actionsColumns: Column<BackofficeTestUserRespDto>[] = canWrite
    ? [
        {
          accessor: 'id',
          width: 60,
          disableResizing: true,
          horizontalAlign: 'center',
          Cell: ({ row: { original } }) => (
            <IconButton size="small" onClick={() => onDeleteTestPhone(original)}>
              <Delete />
            </IconButton>
          ),
        },
      ]
    : [];

  return [
    {
      accessor: 'createdAt',
      Header: t('common_added_date'),
      Cell: ({ value }) => <>{formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm)}</>,
      customFilter: {
        type: FilterType.DateRange,
        withTime: true,
      },
    },
    {
      accessor: 'username',
      Header: t('test_clients_number'),
      customFilter: {
        field: 'username',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
        rifmOptions: RIFM_PHONE_WITH_PLUS,
      },
    },
    {
      accessor: 'code',
      Header: t('test_clients_code'),
      customFilter: {
        field: 'code',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
        rifmOptions: {
          format: formatInteger,
        },
      },
    },
    ...actionsColumns,
  ];
};
