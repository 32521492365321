import React from 'react';
import { Paper, Box, Avatar, Typography } from '@mui/material';
import { PersonOutline } from '@mui/icons-material';
import { CurrentUserModel } from '../../data';

interface IProps {
  user: CurrentUserModel;
}
export const ProfileCard: React.FC<IProps> = (props) => {
  const { user } = props;
  return (
    <>
      <Paper style={{ width: 360 }}>
        <Box
          p={4}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Box mb={2}>
            <Avatar sx={{ width: 100, height: 100 }}>
              <PersonOutline style={{ width: '80%', height: '80%' }} />
            </Avatar>
          </Box>
          <Typography gutterBottom variant="h5">
            {user.fullName}
          </Typography>
          <Typography gutterBottom color="textSecondary">
            {user.username}
          </Typography>
        </Box>
      </Paper>
    </>
  );
};
