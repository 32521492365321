import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';
import { IFetcherResult } from '@pay/data-fetching';
import { ComparisonOperator, makeTableDataSource } from '@mapper/admin-resources';
import { encode } from 'mdurl';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as AO from 'fp-ts-contrib/ArrayOption';

import { ClientApiInterface as ClientControlApiInterface } from 'apis-generated/mapper-processing-admin/apis';
import {
  FinancialOrganization,
  ProfileWorkspace,
  UnverifiedOrganization,
} from 'apis-generated/mapper-processing-admin';
import { ClientApiInterface } from 'apis-generated/mapper-sso-admin/apis';
import {
  ClientAuditRespDto,
  ClientRespDto,
  ToDeleteClientRespDto,
} from 'apis-generated/mapper-sso-admin';

import { extractSome, filterSomes } from 'modules/common/fpts-utils';
import { IRemoteError } from 'modules/common/store';
import {
  mapRemoteError,
  mapFetcherResultEither,
  mapFetcherResultEither2,
} from 'modules/common/fetcher';
import { fetchWithRsql } from 'api';

export interface AddedFinOrganization {
  finOrganization: FinancialOrganization;
  type: 'default' | 'added';
  workspace: ProfileWorkspace;
}

export interface IClientDocInfo {
  type: string;
  number: string;
  issuer: string;
  issuedDate: Date;
  photoUrl: string;
}

export class ClientService {
  constructor(
    private _clientApi: ClientApiInterface,
    private _clientControlApi: ClientControlApiInterface,
    private _fetcher: IFetcher
  ) {}

  public activateUser = (username: string) =>
    pipe(() => this._clientApi.activateUser({ username: username }), T.map(mapFetcherResultEither));

  public blockUser = (username: string) =>
    pipe(() => this._clientApi.blockUser({ username: username }), T.map(mapFetcherResultEither));

  public deregisterUser = (registrationId: string) =>
    pipe(() => this._clientApi.deregister({ registrationId }), T.map(mapFetcherResultEither));

  public getUserDevices = (username: string) =>
    pipe(() => this._clientApi.userDevices({ username: username }), T.map(mapFetcherResultEither));

  public getClientLogs = makeTableDataSource<ClientAuditRespDto>(this._fetcher, `bo/clients/log`);
  public getClients = makeTableDataSource<ClientRespDto>(this._fetcher, `bo/clients`);
  public getToDeleteClients = makeTableDataSource<ToDeleteClientRespDto>(
    this._fetcher,
    `bo/todeleteclients`
  );

  public getClientWorkspaces = (username: string) =>
    pipe(
      () => this._clientControlApi.getUserWorkspaceFinancials({ username: username }),
      T.map(mapFetcherResultEither)
    );

  public getClientsStatistics = (from: Date, to: Date) =>
    pipe(
      () => this._clientApi.getClientsStats({ from: from, to: to }),
      T.map(mapFetcherResultEither)
    );

  public getClientFetchPhoto = (username: string) =>
    pipe(
      () => this._clientControlApi.getPhoto({ username }),
      T.map(
        mapFetcherResultEither2((res) => {
          if (res.response && res.serverError?.hasCode('404000')) {
            return {
              type: 'photo-not-found',
            } as const;
          }
          return mapRemoteError(res);
        })
      )
    );

  private fetchClientsDocumentInfo = (): Promise<IFetcherResult<IClientDocInfo>> => {
    return Promise.resolve({
      success: true,
      body: {
        type: 'ID',
        number: '7628784532',
        issuer: 'МВД РК',
        issuedDate: new Date(),
        photoUrl: '',
      },
      rawResponse: 1 as unknown as Response,
    });
  };

  public getClientsDocumentInfo = () =>
    pipe(() => this.fetchClientsDocumentInfo(), T.map(mapFetcherResultEither));

  public getUserWorkspaceFinancialBinds = (workspaceId: string) => {
    return pipe(
      () => this._clientControlApi.getUserWorkspaceFinancialBinds({ workspaceId }),
      T.map(mapFetcherResultEither)
    );
  };

  public getUserWorkspaceFinancials(
    username: string
  ): TE.TaskEither<IRemoteError, AddedFinOrganization[]> {
    return pipe(
      () => this._clientControlApi.getUserWorkspaceFinancials({ username }),
      T.map(mapFetcherResultEither),
      TE.map((result) => {
        const mappedOrganizations = result.map((res) => {
          const mapOrg = (
            organization: FinancialOrganization | UnverifiedOrganization
          ): AddedFinOrganization => {
            return {
              finOrganization: organization,
              type: 'added',
              workspace: res.workspace!,
            };
          };

          const defaultOrganization = pipe(
            O.fromNullable(res.financials?.defaultOrganization),
            O.map(
              (defOrgs): AddedFinOrganization => ({
                finOrganization: defOrgs,
                type: 'default',
                workspace: res.workspace!,
              })
            )
          );

          const organizations = pipe(
            res.financials?.organizations || [],
            AO.fromArray,
            AO.map(mapOrg)
          );

          const unverifiedOrganizations = pipe(
            res.financials?.unverifiedOrganizations || [],
            AO.fromArray,
            AO.map(mapOrg)
          );

          const result = pipe(
            [defaultOrganization, ...organizations, ...unverifiedOrganizations],
            filterSomes,
            A.map(extractSome)
          );
          return result;
        });
        return A.flatten(mappedOrganizations);
      })
    );
  }

  public unbindDevice = (deviceId: string, workspaceId: string, bin: string) =>
    pipe(
      () => this._clientControlApi.unbindDevice({ deviceId, workspaceId, bin }),
      T.map(mapFetcherResultEither)
    );

  public unbindWorkspace = (workspaceId: string, bin: string) =>
    pipe(
      () => this._clientControlApi.unbindWorkspace({ workspaceId, bin }),
      T.map(mapFetcherResultEither)
    );

  public getClientsByValue = (value: string, page = 0) => {
    const encodedVal = encode(value, encode.componentChars + '=:,;"\'<>#', false);
    const fetch = fetchWithRsql<ClientRespDto>(
      this._fetcher,
      `bo/clients?page=${page}&size=${1_000}&search=username==*${encode(
        encodedVal
      )}*,iin==*${encodedVal}*,firstName==*${encodedVal}*,lastName==*${encodedVal}*`
    );
    return pipe(
      fetch,
      T.map(mapFetcherResultEither),
      TE.map((res) => res.items ?? [])
    );
  };
}
