import { Paper, Box, Grid, Typography, useTheme, MenuItem, CircularProgress } from '@mui/material';
import { Page } from '@mapper/admin-ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';
import React, { FC, useCallback, useEffect } from 'react';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { endOfToday, startOfToday } from 'date-fns';
import { subMonths } from 'date-fns/esm';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTaskEither } from 'modules/common/async/hooks';
import { DATE_FORMAT } from 'modules/common/constants';
import { mapAsyncState } from 'modules/common/async';
import { matchI } from 'modules/common/match';
import { extractErrorMessage } from 'modules/common/errors';
import { ErrorAlert } from 'modules/common/ui/errors';
import { useTranslation } from 'startup/utils';

import { useDashboardService } from '../module';
import { StatisticsPane } from './Statistics';
import { FIAvailabilityChart } from './FIAvailabilityChart';
import { TEtoPromise } from 'modules/common/fpts-utils';
import { AsyncSelect } from 'modules/common/ui/AsyncSelect/AsyncSelect';
import { useMountedState } from 'react-use';
import { DatePicker } from '@mui/x-date-pickers';

interface IProps {}

const schema = z.object({
  from: z.date(),
  to: z.date(),
  bin: z.string(),
});

export type FormValues = Zod.infer<typeof schema>;

export const DashboardPage: FC<IProps> = () => {
  const theme = useTheme();
  const dashboardService = useDashboardService();
  const isMounted = useMountedState();

  const { t } = useTranslation();

  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      from: subMonths(startOfToday(), 1),
      to: endOfToday(),
      bin: '',
    },
  });

  const startDate = watch('from');
  const endDate = watch('to');
  const organizationBin = watch('bin');

  const {
    state: finOrgAvailability,
    execute: finOrgAvailabilityExecute,
    retry: finOrgRetry,
  } = useTaskEither(dashboardService.fetchFinOrganizationAvailability);

  const handleFetchFinOrganizations = useCallback(
    () =>
      pipe(
        dashboardService.fetchAllFinOrganizations(),
        TE.chainFirstIOK((organizations) => () => {
          if (!isMounted()) return;
          const defaultOrganizationBin = organizations[0].bin;
          setValue('bin', defaultOrganizationBin ?? '');
        }),
        TEtoPromise
      )(),
    [dashboardService, setValue, isMounted]
  );

  useEffect(() => {
    if (organizationBin) {
      finOrgAvailabilityExecute({
        from: startDate,
        to: endDate,
        bin: organizationBin,
      });
    }
  }, [finOrgAvailabilityExecute, startDate, endDate, organizationBin]);

  return (
    <Page style={{ overflowY: 'auto' }}>
      <Box mb={2}>
        <Paper>
          <Box p={2} display="flex" alignItems="start">
            <Box mr={2} pt={1}>
              <Typography>{t('dashboard_period')}</Typography>
            </Box>
            <Controller
              name={'from'}
              control={control}
              render={({ field }) => (
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      size: 'small',
                      variant: 'outlined',
                      style: { marginRight: theme.spacing(2) },
                    },
                  }}
                  format={DATE_FORMAT.DD_MM_YYYY}
                  {...field}
                />
              )}
            />
            <Controller
              name={'to'}
              control={control}
              render={({ field }) => (
                <DatePicker
                  disableFuture
                  format={DATE_FORMAT.DD_MM_YYYY}
                  slotProps={{
                    textField: {
                      size: 'small',
                      variant: 'outlined',
                      style: { marginRight: theme.spacing(2) },
                    },
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name={'bin'}
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  size="small"
                  variant="outlined"
                  style={{ minWidth: 160 }}
                  label={t('dashboard_fin_org')}
                  onLoadItems={handleFetchFinOrganizations}
                  {...makeRhfMuiTextFieldProps(errors.bin, t)}
                  {...field}
                >
                  {(finOrganizations) =>
                    finOrganizations?.map((finOrganization) => (
                      <MenuItem value={finOrganization.bin} key={finOrganization.bin}>
                        {finOrganization.name}
                      </MenuItem>
                    ))
                  }
                </AsyncSelect>
              )}
            />
          </Box>
        </Paper>
      </Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                {t('dashboard_fin_organizations_availability')}
              </Typography>
              {finOrgAvailability &&
                mapAsyncState(finOrgAvailability, {
                  error: (error) => {
                    const errorText = error
                      ? matchI(error)({
                          DataExceeded: () => t('dashboard_data_exceeded'),
                          unknown: (err) => extractErrorMessage(err, t),
                        })
                      : '';
                    return <ErrorAlert error={errorText} onRetry={finOrgRetry} />;
                  },
                  loading: (_) => (
                    <Box p={4} textAlign="center">
                      <CircularProgress size={32} />
                    </Box>
                  ),
                  success: (finOrgs) => <FIAvailabilityChart finOrgs={finOrgs} />,
                })}
            </Box>
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <StatisticsPane startDate={startDate} endDate={endDate} />
        </Grid>
      </Grid>
    </Page>
  );
};
