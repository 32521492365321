import { Tabs as MuiTabs, Tab, styled } from '@mui/material';
import { Page, PageTitle } from '@mapper/admin-ui-kit';
import React, { FC, useState } from 'react';

import { useTranslation } from 'startup/utils';
import { PrivacyPolicyHistoryPane } from './history/PrivacyPolicyHistoryPane';
import { PrivacyPolicyChangeRequestsPane } from './changeRequests/PrivacyPolicyChangeRequestsPane';

const TabsRoot = styled(MuiTabs)(({ theme }) => ({
  padding: `0 ${theme.spacing(6)}px`,
  marginBottom: theme.spacing(2),
  flex: 0,
}));

export enum ETab {
  History = '',
  ChangeRequests = 'changeRequests',
}

interface IProps {}

export const PrivacyPolicyPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(ETab.History);

  const handleChange = (event: React.ChangeEvent<{}>, tab: ETab) => {
    setTab(tab);
  };

  const renderTabContent = () => {
    return (
      <>
        <Page>
          <PageTitle>{t('privacy_policy_page_title')}</PageTitle>
          <TabsRoot
            value={tab}
            indicatorColor="primary"
            onChange={handleChange}
            textColor="primary"
            variant="standard"
          >
            <Tab value={ETab.History} label={t('privacy_policy_tab_history')} />
            <Tab value={ETab.ChangeRequests} label={t('privacy_policy_tab_change_requests')} />
          </TabsRoot>
          {tab === ETab.History && <PrivacyPolicyHistoryPane />}
          {tab === ETab.ChangeRequests && <PrivacyPolicyChangeRequestsPane />}
        </Page>
      </>
    );
  };

  return <>{renderTabContent()}</>;
};
