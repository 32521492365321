import React, { FC, useCallback } from 'react';
import { Box, Paper, Typography, useTheme, Grid } from '@mui/material';
import { endOfToday, startOfToday, subMonths } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import * as TE from 'fp-ts/TaskEither';
import { DatePicker } from '@mui/x-date-pickers';

import { zodResolver } from '@hookform/resolvers/zod';
import { useNotificationsStore } from '@mapper/admin-ui-kit';
import { Button } from '@pay/mui-enhancement';

import { DATE_FORMAT } from 'modules/common/constants';
import { removeEmptyKeys } from 'modules/common/utils';
import { useTranslation } from 'startup/utils';
import { useReportService } from '../module';
import { LocationAndSchoolForm } from './LocationAndSchoolForm';

interface Props {
  refresh: () => void;
}

const schema = z.object({
  from: z.date(),
  to: z.date(),
  locationCode: z.string().optional(),
  schoolBin: z.string().optional(),
});
export type FormValues = Zod.infer<typeof schema>;

export const BusinessWalletByMerchantReportPage: FC<Props> = ({ refresh }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showNotification } = useNotificationsStore();

  const reportService = useReportService();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      from: subMonths(startOfToday(), 1),
      to: endOfToday(),
    },
  });

  const handleSubmitValues = useCallback(
    (values: FormValues) => {
      pipe(
        reportService.prepareReportByMerchants(removeEmptyKeys(values) as FormValues),
        TE.map(() => refresh()),
        TE.mapLeft(() => {
          showNotification({
            text: t('common_download_error'),
            options: { variant: 'error' },
          });
        })
      )();
    },
    [reportService, showNotification, refresh, t]
  );

  return (
    <Paper>
      <Box p={2}>
        <form onSubmit={handleSubmit(handleSubmitValues)}>
          <Grid container direction="column" spacing={2}>
            <Grid container item key={1} alignItems="center">
              <Grid item xs={1}>
                <Box mr={2}>
                  <Typography>{t('dashboard_period')}</Typography>
                </Box>
              </Grid>
              <Controller
                name={'from'}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    disableFuture
                    format={DATE_FORMAT.DD_MM_YYYY}
                    slotProps={{
                      textField: {
                        size: 'small',
                        variant: 'outlined',
                        style: { marginRight: theme.spacing(2) },
                      },
                    }}
                    label={t('common_range_from')}
                    {...field}
                  />
                )}
              />
              <Controller
                name={'to'}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    disableFuture
                    slotProps={{
                      textField: {
                        size: 'small',
                        variant: 'outlined',
                        style: { marginRight: theme.spacing(2) },
                      },
                    }}
                    format={DATE_FORMAT.DD_MM_YYYY}
                    label={t('common_range_to')}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <LocationAndSchoolForm control={control} />
          <Box mt={2}>
            <Button variant="contained" type="submit" color="primary" loading={isSubmitting}>
              {t('common_download')}
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
