import React from 'react';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/x-tree-view/TreeItem';
import { LocationInfoDto, LocationNextDTO } from 'apis-generated/reports';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  hasChild?: boolean;
  isExpanded?: boolean;
};

type Props = {
  regionsData: LocationInfoDto[];
  childData: { [key: string]: LocationNextDTO[] };
  expanded: string[] | undefined;
  selected: string;
  onNodeToggle: (event: React.SyntheticEvent, itemIds: string[]) => void;
  onNodeSelect: (event: React.SyntheticEvent, itemId: string | null) => void;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&.Mui-focused .MuiTypography-root, &:hover .MuiTypography-root, &.Mui-selected .MuiTypography-root':
      {
        backgroundColor: 'transparent',
      },
  },

  [`& .${treeItemClasses.content}`]: {
    paddingLeft: theme.spacing(1),
  },
  [`& .MuiTreeItem-iconContainer`]: {
    visibility: 'hidden',
  },
  [`& .${treeItemClasses.label}`]: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
}));

const LabelRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 0),
}));

const LabelIcon = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const LabelText = styled(Typography)({
  fontWeight: 'inherit',
  flexGrow: 1,
});

const StyledTreeItem = (props: StyledTreeItemProps) => {
  const {
    labelText,
    labelIcon: LabelIconComponent,
    labelInfo,
    color,
    bgColor,
    hasChild,
    isExpanded,
    ...prop
  } = props;

  return (
    <StyledTreeItemRoot
      {...props}
      label={
        <LabelRoot>
          {hasChild && !isExpanded && <ArrowRightIcon />}
          {hasChild && isExpanded && <ArrowDropDownIcon />}
          {LabelIconComponent && <LabelIconComponent />}
          <LabelText variant="body2">{labelText}</LabelText>
        </LabelRoot>
      }
      slots={{
        expandIcon: undefined,
        collapseIcon: undefined,
      }}
      {...prop}
    />
  );
};

export const CustomTreeView = ({
  regionsData,
  childData,
  expanded,
  selected,
  onNodeSelect,
  onNodeToggle,
}: Props) => {
  const renderTreeItems = (items: LocationInfoDto[]) => {
    return items.map((item: LocationInfoDto) => {
      const isExpanded = expanded?.includes(item.code!);
      if (!item.code) {
        return null;
      }
      return (
        <StyledTreeItem
          key={item.code}
          itemId={item.code!}
          labelText={item.name!}
          hasChild={item.hasChild}
          isExpanded={isExpanded}
        >
          {childData[item.code] && renderTreeItems(childData[item.code])}
        </StyledTreeItem>
      );
    });
  };

  return (
    <Box sx={{ height: 400, width: 400, overflow: 'auto' }}>
      <SimpleTreeView
        expandedItems={expanded}
        selectedItems={selected}
        onExpandedItemsChange={onNodeToggle}
        onSelectedItemsChange={onNodeSelect}
      >
        {regionsData && renderTreeItems(regionsData)}
      </SimpleTreeView>
    </Box>
  );
};
