import { EitherType, useLazyRef } from '@pay/common-utils';
import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useMount } from 'react-use';
import { IAsyncState, IAsyncStateSimple, mapAsyncState, fromEither } from './async';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { pipe } from 'fp-ts/lib/function';
import { noop } from 'lodash-es';

/**
 * Converts async fucntion returning Either to async @see {IAsyncState}.
 * https://github.com/microsoft/TypeScript/issues/13995
//  */
// export function useAsyncEither<TL, TR>(
//   func: () => Promise<EitherType<TL, TR>>
// ): {
//   state: IAsyncState<TR, TL>;
//   retry: () => void;
// } {
//   const [state, setState] = useState<IAsyncState<TR, TL>>({ loading: true });

//   const execute = useRef(async () => {
//     setState({ loading: true });
//     const result = await func();
//     ReactDOM.unstable_batchedUpdates(() => {
//       setState(fromEither(result));
//     });
//   });

//   useMount(() => {
//     execute.current();
//   });

//   const handleRetry = execute.current;

//   return {
//     retry: handleRetry,
//     state: state,
//   };
// }

// export const useAsyncFn = <TResult, TParams extends any[]>(
//   fn: (...args: TParams) => Promise<TResult>,
//   handler?: (resulr: TResult) => void
// ): [IAsyncStateSimple<TResult> | undefined, (...args: TParams) => void] => {
//   const [state, setState] = useState<IAsyncStateSimple<TResult> | undefined>();

//   const execute = useLazyRef(() => (...args: TParams) => {
//     setState({ loading: true });
//     fn(...args).then(res => {
//       setState({ value: res, loading: false });
//       handler?.(res);
//     });
//   }).current;

//   return [state, execute];
// };

// type INotInitiatedState = {
//   loading?: false;
//   value?: undefined;
//   error?: undefined;
// };
/**
 * Converts async fucntion returning Either to async @see {IAsyncState}.
 * https://github.com/microsoft/TypeScript/issues/13995
 */
export function useTaskEither<TParams extends any[], TL, TR>(
  func: (...args: TParams) => TE.TaskEither<TL, TR>,
  initialState?: IAsyncState<TR, TL>
): {
  state: IAsyncState<TR, TL> | undefined;
  retry: () => void;
  reset: () => void;
  execute: (...args: TParams) => Promise<void>;
} {
  const [state, setState] = useState<IAsyncState<TR, TL> | undefined>(initialState ?? undefined);
  const lastArgsRef = useRef<TParams | undefined>(undefined);
  const executeInternal = useRef((...args: TParams) => {
    setState({ loading: true });
    lastArgsRef.current = args;
    return pipe(
      func(...args),
      TE.fold(
        (e) => {
          setState({ loading: false, error: e });
          return T.of(undefined);
        },
        (res) => {
          setState({ loading: false, value: res });
          return T.of(undefined);
        }
      )
    )();
  });

  return {
    execute: executeInternal.current,
    retry: useLazyRef(
      () => () => lastArgsRef.current ? executeInternal.current(...lastArgsRef.current) : noop
    ).current,
    state: state,
    reset: useLazyRef(() => () => setState(undefined)).current,
  };
}

export const useTaskEitherImmediate = <TL, TR>(
  func: () => TE.TaskEither<TL, TR>
): {
  state: IAsyncState<TR, TL>;
  retry: () => void;
} => {
  const req = useTaskEither(func, { loading: true });
  useMount(() => {
    req.execute();
  });

  return {
    retry: req.execute,
    state: req.state as Exclude<(typeof req)['state'], undefined>,
  };
};
