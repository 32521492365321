import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from '@pay/mui-enhancement';
import { ICommonError, IRemoteError } from 'modules/common/store';
import React, { useCallback, useState } from 'react';
import { I2FaVerificationError } from '../../constants';
import { IResetSecondFactorContext } from '../../../auth/service/AuthService';
import { SecondFactorCodeForm } from '../second-factor/SecondFactorCodeForm';
import { useTranslation } from '../utils';

interface IProps {
  open: boolean;
  onClosed: () => void;
  onSubmit: (
    code: string
  ) => Promise<ICommonError | IRemoteError | I2FaVerificationError | undefined>;
  context: IResetSecondFactorContext | undefined;
}
export const ResetSecondAuthDilog: React.FC<IProps> = (props) => {
  const { open, onClosed, context, onSubmit } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(open);

  const handleSubmit: React.ComponentProps<typeof SecondFactorCodeForm>['onSubmit'] = useCallback(
    async (code) => {
      setIsLoading(true);
      const result = await onSubmit(code);
      setIsLoading(false);
      return result;
    },
    [onSubmit]
  );

  const handleClosing = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        disableEscapeKeyDown
        TransitionProps={{
          onExited: onClosed,
        }}
      >
        <DialogTitle>{t('settings_second_auth_reset_dialog_title')}</DialogTitle>
        <DialogContent dividers>
          {context && (
            <SecondFactorCodeForm
              onSubmit={handleSubmit}
              formId="CodeForm"
              qrContext={{
                qrUrl: context.qrUrl,
                secret: context.secret,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={handleClosing}>
            {t('common_cancel')}
          </Button>
          <Button
            loading={isLoading}
            type="submit"
            variant="contained"
            form="CodeForm"
            color="primary"
          >
            {t('common_submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
