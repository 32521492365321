import { Column } from '@mapper/admin-data-table';
import { IAppTransFunction } from 'startup/utils';
import { TerminalApk, TerminalInfoResponse } from 'apis-generated/mapper-documents-admin';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';
import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const getPosTerminalApkColumns = (t: IAppTransFunction): Column<TerminalApk>[] => {
  return [
    {
      accessor: 'versionName',
      Header: t('pos_terminal_apk_upload_version_name'),
      sortable: false,
    },
    {
      accessor: 'versionCode',
      Header: t('pos_terminal_apk_upload_version_code'),
      sortable: false,
    },
  ];
};
export const getPosTerminalInfoColumns = (
  t: IAppTransFunction,
  onEditApkVersion: (info: TerminalInfoResponse) => void,
  onDeactivatePosTerminal: (info: TerminalInfoResponse) => void,
  canWrite: boolean,
  canDeactivatePosTerminal: boolean
): Column<TerminalInfoResponse>[] => {
  const actionsColumns: Column<TerminalInfoResponse>[] = canWrite
    ? [
        {
          id: 'editApkVersion',
          accessor: 'terminalId',
          width: 60,
          disableResizing: true,
          horizontalAlign: 'center',
          Cell: ({ row: { original } }) => (
            <IconButton size="small" onClick={() => onEditApkVersion(original)}>
              <Edit />
            </IconButton>
          ),
        },
      ]
    : [];
  if (canDeactivatePosTerminal) {
    actionsColumns.push({
      id: 'deactivatePosTerminal',
      accessor: 'terminalId',
      width: 60,
      disableResizing: true,
      horizontalAlign: 'center',
      Cell: ({ row: { original } }) => (
        <IconButton size="small" onClick={() => onDeactivatePosTerminal(original)}>
          <Delete />
        </IconButton>
      ),
    });
  }

  return [
    {
      id: 'terminalId',
      accessor: 'terminalId',
      Header: t('business_accounts_pos_terminal_id'),
      sortable: false,
    },
    {
      accessor: 'regDate',
      Header: t('business_accounts_pos_terminal_bind_date'),
      Cell: ({ value }) => <>{formatDate(value, DATE_FORMAT.DD_MM_YYYY)}</>,
      sortable: false,
    },
    {
      accessor: 'address',
      Header: t('business_accounts_pos_terminal_address'),
      sortable: false,
    },
    {
      accessor: 'currentVersion',
      Header: t('business_accounts_pos_terminal_po_version'),
      sortable: false,
    },
    {
      accessor: 'osVersion',
      Header: t('business_accounts_pos_terminal_os_version'),
      sortable: false,
    },
    ...actionsColumns,
  ];
};
