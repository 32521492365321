import { Search } from '@mui/icons-material';
import {
  ErrorOverlay,
  IDetailsComponentProps,
  ITEM_ID_NEW,
  LoadingOverlay,
} from '@mapper/admin-ui-kit';
import * as TE from 'fp-ts/TaskEither';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BackofficeUserEditReqDto } from 'apis-generated/mapper-sso-admin';
import { useAuthStore } from 'modules/auth/module';
import { constRemoteError } from 'modules/common/store';
import { EPermissionType } from 'modules/user-management';
import { useTaskEitherImmediate } from 'modules/common/async/hooks';
import { useRolesStore } from '../../../module';
import { useUsersStore } from '../../../module';
import { IFormValues } from './types';
import { EMode, UserEditForm } from './UserEditForm';
import { hasOneOfPermissions } from 'modules/auth/data/AuthUser';

interface IUserDetailsProps extends IDetailsComponentProps {}

export const UserDetails: FC<IUserDetailsProps> = ({ itemId, onClose }) => {
  const store = useUsersStore();
  const rolesStore = useRolesStore();

  const handleSave = useCallback(
    (values: IFormValues, roles: string[]) => {
      //TODO: fix any to BackofficeUserAddRequest
      const createReq: any = {
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        position: values.position,
        email: values.email,
        roles: roles,
        phone: values.phone,
      };
      return store.addUser(createReq);
    },
    [store]
  );

  return itemId === ITEM_ID_NEW ? (
    <UserEditForm
      onLoadRoles={rolesStore.fetchAll}
      onReset2Fa={store.reset2fa}
      onResetPassword={store.resetPassword}
      // onLockUnlock={store.lockUnlock}
      onDelete={store.deleteUser}
      // onRestore={store.restoreUser}
      onClose={onClose}
      onSave={handleSave}
      mode={EMode.Create}
    />
  ) : (
    <UserViewEditContainer onClose={onClose} userId={itemId} />
  );
};

interface IUserEditContainerProps {
  userId: string;
  onClose: () => void;
}
export const UserViewEditContainer: FC<IUserEditContainerProps> = ({ userId, onClose }) => {
  const store = useUsersStore();
  const rolesStore = useRolesStore();
  const { t } = useTranslation();
  const { currentUser } = useAuthStore();

  const [isEditing, setIsEditing] = useState(false);
  const { state, retry } = useTaskEitherImmediate(() => store.fetchOneUser(userId!));
  const handleEdit = useCallback(() => setIsEditing(true), []);

  const handleSave = useCallback(
    (values: IFormValues, roles: string[]) => {
      if (state.loading || state.error) {
        // hack, not really a remote error
        return TE.left(constRemoteError());
      }
      const update: BackofficeUserEditReqDto = {
        firstName: values.firstName,
        lastName: values.lastName,
        position: values.position,
        phone: values.phone,
        email: values.email,
        roles: roles,
      };
      return store.updateUser(state.value, update);
    },
    [state, store]
  );

  if (state.loading) {
    return <LoadingOverlay />;
  }

  if (state.error) {
    if (state.error.type === 'unknown') {
      return <ErrorOverlay icon={Search} text={t('user_not_found')} onClose={onClose} />;
    }
    return <ErrorOverlay text={t('common_unknown_error')} onClose={onClose} />;
  }

  return (
    <UserEditForm
      mode={isEditing ? EMode.Edit : EMode.View}
      onClose={onClose}
      originalUser={state.value}
      onStartEdit={handleEdit}
      onLoadRoles={rolesStore.fetchAll}
      onReset2Fa={store.reset2fa}
      onResetPassword={store.resetPassword}
      // onLockUnlock={store.lockUnlock}
      onDelete={store.deleteUser}
      // onRestore={store.restoreUser}
      onRefresh={retry}
      userCanEdit={hasOneOfPermissions(currentUser!, [
        EPermissionType.UsersWrite,
        EPermissionType.System,
        EPermissionType.Superadmin,
      ])}
      // userCanLock={authStore.userHasOneOfPermissions([
      //   EPermissionType.UserLockUnlock,
      //   EPermissionType.System,
      // ])}
      onSave={handleSave}
    />
  );
};
