import { Box } from '@mui/material';
import { CurrentUserModel, ProfileCard } from '../../../../auth';
import React from 'react';

interface IProps {
  user: CurrentUserModel;
}
export const GeneralSettings: React.FC<IProps> = (props) => {
  const { user } = props;
  return (
    <>
      <Box sx={{ padding: 3 }}>
        <Box>
          <ProfileCard user={user} />
        </Box>
      </Box>
    </>
  );
};
