import React from 'react';
import { observer } from 'mobx-react-lite';
import * as AuthState from '../../core/AuthState';
import { useAuthStore } from 'modules/auth';
import { AuthCodeForm } from '../AuthCodeForm';
import { AuthForm } from '../AuthForm';
import Box from '@mui/material/Box/Box';

export const AuthExpiredSessionModalFlow: React.FC = observer(() => {
  const { state, currentUser, logIn, logOut, verify2AuthCode } = useAuthStore();

  if (!AuthState.isSessionExpired(state)) return null;

  const content =
    state.stageContext.stage === AuthState.InitialStage.SecondFactor ? (
      <AuthCodeForm
        onSubmit={verify2AuthCode}
        authResponse={state.stageContext.authResponse}
        buttonBoxProps={{ mb: 0 }}
      />
    ) : (
      <AuthForm
        onSubmit={logIn}
        logOutReason={AuthState.LogoutReason.SessionExpiredOrInvalidToken}
        currentUser={currentUser}
        onLogOut={() => logOut()}
      />
    );

  return (
    <Box width={300} p={2}>
      {content}
    </Box>
  );
});
