import { BOReportRequestMealTypeEnum, BOReportRequestSearchTypeEnum } from 'apis-generated/reports';
import { ITransKey } from 'startup/i18n';

export const SEARCH_TYPE_TRANS_MAP: Record<BOReportRequestSearchTypeEnum, ITransKey> = {
  [BOReportRequestSearchTypeEnum.School]: 'report_meal_voucher_search_type_school',
  [BOReportRequestSearchTypeEnum.Student]: 'report_meal_voucher_search_type_student',
};

export const MEAL_TYPE_TRANS_MAP: Record<BOReportRequestMealTypeEnum, ITransKey> = {
  [BOReportRequestMealTypeEnum.Breakfast]: 'report_meal_voucher_meal_type_breakfast',
  [BOReportRequestMealTypeEnum.Lunch]: 'report_meal_voucher_meal_type_lunch',
};

export enum ReportFormEnum {
  Form1 = 'Form1',
  Form2 = 'Form2',
  Form3 = 'Form3',
}

export const REPORT_FORM_TRANS_MAP: Record<ReportFormEnum, ITransKey> = {
  [ReportFormEnum.Form1]: 'report_meal_stats_title',
  [ReportFormEnum.Form2]: 'report_meal_voucher_title',
  [ReportFormEnum.Form3]: 'report_meal_bw_merchant_title',
};
