import { styled, Tabs as MuiTabs, Tab } from '@mui/material';
import { Page, PageTitle } from '@mapper/admin-ui-kit';
import React, { useState, FC } from 'react';
import { useTranslation } from 'startup/utils';

import { LegalAgreementHistoryPane } from './history/LegalAgreementHistoryPane';
import { AgreementChangeRequestsPane } from './changeRequests/AgreementChangeRequestsPane';

const TabsRoot = styled(MuiTabs)(({ theme }) => ({
  padding: `0 ${theme.spacing(6)}px`,
  marginBottom: theme.spacing(2),
  flex: 0,
}));

export enum ETab {
  History = '',
  ChangeRequests = 'changeRequests',
}

interface IProps {}

export const LegalAgreementPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(ETab.History);

  const handleChange = (event: React.ChangeEvent<{}>, tab: ETab) => {
    setTab(tab);
  };

  const renderTabContent = () => {
    return (
      <>
        <Page>
          <PageTitle>{t('legal_agreement_page_title')}</PageTitle>
          <TabsRoot
            value={tab}
            indicatorColor="primary"
            onChange={handleChange}
            textColor="primary"
            variant="standard"
          >
            <Tab value={ETab.History} label={t('agreement_tab_history')} />
            <Tab value={ETab.ChangeRequests} label={t('agreement_tab_change_requests')} />
          </TabsRoot>
          {tab === ETab.History && <LegalAgreementHistoryPane />}
          {tab === ETab.ChangeRequests && <AgreementChangeRequestsPane />}
        </Page>
      </>
    );
  };

  return <>{renderTabContent()}</>;
};
