import React from 'react';
import { Page, PageTitle } from '@mapper/admin-ui-kit';
import { useTranslation } from 'react-i18next';
import { MealAdminPane } from './MealAdminPane';

export const MealAdminPage = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <PageTitle>{t('meal_page_title')}</PageTitle>
      <MealAdminPane />
    </Page>
  );
};
