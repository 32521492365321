import {
  DETAILS_STORE_KEY,
  DetailsStore,
  NOTIFICATIONS_STORE_KEY,
  NotificationsStore,
  UI_STORE_KEY,
  UiPreferencesStore,
} from '@mapper/admin-ui-kit';
import { Fetcher, withJaegerDebug } from '@pay/data-fetching';
import { registerService } from '@pay/modules';
import { StorageManager } from '@pay/storage';
import { createBrowserHistory } from 'history';

import { API_HOST } from './constants';
import { ELang } from './i18n';

import { corsMiddleware } from 'infrastructure/middlewares/corsMiddleware';
import { logMiddleware } from 'infrastructure/middlewares/logMiddleware';

import { initializeAuthModuleContext, makeAuthErrorMiddleware } from 'modules/auth';
import { initializeBusinessAccountsModuleContext } from 'modules/business-accounts';
import { initializeClientsModuleContext } from 'modules/clients';
import { initializeLogsModuleContext } from 'modules/logs';
import { initializeAgreementModuleContext } from 'modules/agreement';
import { initializeLegalAgreementModuleContext } from 'modules/legal-agreement';
import { initializeFinOrganizationsModuleContext } from 'modules/fin-organizations';
import { initializeConfigurationModuleContext } from 'modules/configuration';
import { initializeUserManagementModuleContext } from 'modules/user-management';
import { initializePrivacyPolicyModuleContext } from '../modules/privacy-policy';
import { initializeDashboardModuleContext } from '../modules/dashboard';
import { initializePreferencesModuleContext } from '../modules/preferences';
import { initializeTestClientsModuleContext } from 'modules/test-clients';
import { initializePosTerminalssModuleContext } from 'modules/pos-terminals';
import { initializeReportModuleContext } from 'modules/reports';
import { initializeMealsModuleContext } from 'modules/meals';
import { initializeSchoolModuleContext } from 'modules/schools';

const localStorageMan = new StorageManager(window.localStorage);
export const browserHistory = createBrowserHistory();

const notificationsStore = new NotificationsStore();
registerService(NOTIFICATIONS_STORE_KEY, notificationsStore);

const uiPreferencesStore = new UiPreferencesStore(localStorageMan, ELang.Ru);
registerService(UI_STORE_KEY, uiPreferencesStore);
uiPreferencesStore.initialize();

const detailsStore = new DetailsStore(browserHistory);
registerService(DETAILS_STORE_KEY, detailsStore);
detailsStore.initialize();

export const initializeModules = () => {
  const ssoFetcher = new Fetcher(API_HOST + '/api/v1/sso/', { credentials: 'include' });
  const mapperFetcher = new Fetcher(API_HOST + '/api/v1/mapper/', { credentials: 'include' });
  const documentsFetcher = new Fetcher(API_HOST + '/api/v1/documents/', { credentials: 'include' });
  const reportsFetcher = new Fetcher(API_HOST + '/api/v1/reports/', { credentials: 'include' });
  const backofficeFetcher = new Fetcher(API_HOST + '/api/v1/backoffice/', {
    credentials: 'include',
  });
  const ctx = {
    ...initializeAuthModuleContext(ssoFetcher, localStorageMan),
    ...initializeAgreementModuleContext(ssoFetcher),
    ...initializeLegalAgreementModuleContext(ssoFetcher),
    ...initializeBusinessAccountsModuleContext(mapperFetcher),
    ...initializeClientsModuleContext(ssoFetcher, mapperFetcher),
    ...initializeConfigurationModuleContext(ssoFetcher),
    ...initializeDashboardModuleContext(mapperFetcher),
    ...initializeFinOrganizationsModuleContext(mapperFetcher),
    ...initializeLogsModuleContext(ssoFetcher),
    ...initializePreferencesModuleContext(ssoFetcher),
    ...initializePrivacyPolicyModuleContext(ssoFetcher),
    ...initializeUserManagementModuleContext(ssoFetcher),
    ...initializeTestClientsModuleContext(ssoFetcher),
    ...initializePosTerminalssModuleContext(documentsFetcher, ssoFetcher),
    ...initializeReportModuleContext(reportsFetcher),
    ...initializeMealsModuleContext(ssoFetcher, reportsFetcher),
    ...initializeSchoolModuleContext(backofficeFetcher),
  };

  const authMiddleware = makeAuthErrorMiddleware(ctx.auth.store);

  ssoFetcher.addMiddlewares(corsMiddleware, logMiddleware, withJaegerDebug, authMiddleware);

  mapperFetcher.addMiddlewares(corsMiddleware, logMiddleware, withJaegerDebug, authMiddleware);

  documentsFetcher.addMiddlewares(corsMiddleware, logMiddleware, withJaegerDebug, authMiddleware);

  reportsFetcher.addMiddlewares(corsMiddleware, logMiddleware, withJaegerDebug, authMiddleware);

  return ctx;
};
