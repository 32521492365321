import React from 'react';
import { Button, Typography } from '@mui/material';
import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';
import { useBusinessAccountParticipantsColumns } from './tableDefs';
import { shouldTrans } from 'modules/common/utils';
import { LegalDetails, LegalParticipants } from 'apis-generated/mapper-processing-admin';
import { AddEmployeeModal } from 'modules/business-accounts/ui/AddEmployee';
import { matchPrimitive } from 'modules/common/typescript';
import { useModals } from '@mapper/admin-ui-kit';

type ParticipantsProps = {
  account: LegalDetails;
  onRefreshAccount: () => void;
};

export const Participants = (props: ParticipantsProps) => {
  const { account, onRefreshAccount } = props;

  const { showModal } = useModals();

  const participantsColumns = useBusinessAccountParticipantsColumns(onRefreshAccount);

  const { tableProps: participantTableProps, toolBarProps } = useTable<LegalParticipants>({
    fetchData: () =>
      Promise.resolve({ items: account.participants!, total: account.participants!.length }),
    initialQuery: {
      filters: {},
      page: 0,
      pageSize: account.participants?.length ?? 15,
      sortings: {},
    },
    columns: participantsColumns,
    keyGetter: (it) => it.id!,
  });

  return (
    <>
      <Typography style={{ marginBottom: '8px' }}>{shouldTrans('Сотрудники')}</Typography>
      <TableToolbar {...toolBarProps} columns={participantsColumns}>
        {true && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              showModal(
                <AddEmployeeModal
                  open={false}
                  legalId={account.extId}
                  onAdded={onRefreshAccount}
                  target={matchPrimitive(account.type, {
                    ENTREPRENEUR: () => 'enterpreneur',
                    LEGAL: () => 'legal',
                  })}
                />
              );
            }}
          >
            {shouldTrans('Добавить Соотрудника')}
          </Button>
        )}
      </TableToolbar>
      <DataTable {...participantTableProps} />
    </>
  );
};
