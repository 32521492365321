import * as z from 'zod';
import * as TE from 'fp-ts/TaskEither';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTaskEither } from 'async-state-ts-react-hooks';
import { Box, debounce, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from 'modules/common/ui/errors';
import { Button, RifmTextField, makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { shouldTrans } from 'modules/common/utils';
import { IRemoteError } from 'modules/common/store';
import { formatEmpty } from 'modules/common/format';
import { AsyncInfinityAutoComplete } from 'modules/common/ui/AsyncInfinityAutoComplete';
import { delay, formatInteger } from '@pay/common-utils';
import { pipe } from 'fp-ts/lib/function';
import { useClientService } from 'modules/clients/module';
import { TEtoPromise } from 'modules/common/fpts-utils';
import { useDebounce } from 'react-use';
import debouce from 'lodash-es/debounce';
import { createFilterOptions } from '@mui/material';
import { useNonMonetaryService } from 'modules/business-accounts/module';

const School = z.object({
  name: z.string(),
  bin: z.string(),
  number: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
});

type School = z.infer<typeof School>;

const schema = z.object({
  school: School,
});

export type Values = Zod.infer<typeof schema>;

export type SelectSchoolFormProps = {
  onClose?: () => void;
  onSubmit: (values: Values) => TE.TaskEither<IRemoteError, unknown>;
};

export const SelectSchoolForm = (props: SelectSchoolFormProps) => {
  const { onClose, onSubmit } = props;
  const { t } = useTranslation();

  const [symbol, setSymbol] = useState('');

  const [submitState, execSubmit] = useTaskEither(onSubmit);
  const { fetchAllSchool } = useNonMonetaryService();

  const { control, formState, handleSubmit } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues: {
      school: undefined,
    },
  });

  const handleSubmitInternal: SubmitHandler<Values> = useCallback(
    (values) => {
      return execSubmit(values);
    },
    [execSubmit]
  );

  const handleSymbolChange = useMemo(() => debounce((value: string) => setSymbol(value), 1000), []);

  return (
    <>
      <DialogContent dividers>
        <ErrorAlert error={submitState.error} />
        <Box
          component="form"
          id={FORM_ID}
          mb={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          onSubmit={handleSubmit(handleSubmitInternal)}
        >
          <Controller
            control={control}
            name={'school'}
            render={({ field: { ref, ...fieldProps }, fieldState }) => (
              // <RifmTextField
              //   rifm={{ accept: /./g, format: formatEmpty }}
              //   fullWidth
              //   label={'Соотрудник'}
              //   variant="outlined"
              //   inputRef={ref}
              //   helperText={fieldState.error?.message ?? ' '}
              //   error={!!fieldState.error}
              //   {...fieldProps}
              // />
              <AsyncInfinityAutoComplete
                symbol={symbol}
                onLoadItems={(symbol: string) => pipe(fetchAllSchool(symbol), TEtoPromise)()}
                inputProps={{
                  label: 'Школа',
                  helperText: fieldState.error?.message,
                  error: !!fieldState.error,
                }}
                freeSolo
                disableClearable
                {...fieldProps}
                getOptionLabel={(school) => {
                  if (typeof school === 'string') {
                    return school;
                  }
                  return school?.number ? `Школа №${school?.number}` : school?.name;
                }}
                filterOptions={createFilterOptions({
                  stringify: (option) =>
                    `${option.name ? option.name : ''}${option.number ? option.number : ''}${
                      option.address ? option.address : ''
                    }${option.bin ? option.bin : ''}`,
                })}
                renderOption={(props, op) => {
                  return (
                    <Box flexDirection="column" mb={1}>
                      {op.number && <Typography>Школа №{op.number}</Typography>}
                      <Typography>{op.name}</Typography>
                      <Typography>{op.bin}</Typography>
                      <Typography>{op.address}</Typography>
                    </Box>
                  );
                }}
                onInputChange={(_, value) => {
                  handleSymbolChange(value);
                }}
                onChange={(a, b) => {
                  fieldProps.onChange(b);
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button onClick={onClose} color="primary">
            {t('common_close')}
          </Button>
        )}
        <Button
          loading={formState.isSubmitting}
          disableElevation
          variant="contained"
          color="primary"
          type="submit"
          form={FORM_ID}
        >
          {shouldTrans('Добавить')}
        </Button>
      </DialogActions>
    </>
  );
};

const FORM_ID = 'new-entrepreneur-form';
