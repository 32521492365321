import React, { useMemo, FC } from 'react';
import { TableToolbar, useTable } from '@mapper/admin-data-table';
import { Page, PageTitle } from '@mapper/admin-ui-kit';
import { getColumns } from './tableDefs';
import { useTranslation } from 'startup/utils';
import { DataTable } from '@mapper/admin-data-table';
import { IMockCoidLogs } from '../utils';

const mockItems: IMockCoidLogs[] = [
  {
    id: '7gsbd2s-jdycbns-726bhxy-jdysh29',
    appealDate: new Date().getTime(),
    fromDevice: 'Iphone XR',
    result: 'Успешно',
    firstName: 'Eric',
    lastName: 'Kim',
    IIN: '980627000052',
    phoneNumber: '+77087745621',
  },
];

export const CoidLogPage: FC = () => {
  const { t } = useTranslation();
  // const store = useLogsStore();
  const columns = useMemo(() => getColumns(t), [t]);
  const { tableProps, toolBarProps } = useTable<IMockCoidLogs>({
    fetchData: () => Promise.resolve({ items: mockItems, total: mockItems.length }),
    pageSize: 20,
    columns,
    keyGetter: (it) => it.id,
  });

  return (
    <Page style={{ flexFlow: 'column' }}>
      <PageTitle>{t('coid_page_title')}</PageTitle>
      <TableToolbar<IMockCoidLogs> {...toolBarProps} columns={columns}></TableToolbar>
      <DataTable<IMockCoidLogs> {...tableProps} />
    </Page>
  );
};

CoidLogPage.displayName = 'CoidLogPage';
