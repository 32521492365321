import { IFetcherMiddleware } from '@pay/data-fetching';
import { AuthStore, ELogoutReason } from './AuthStore';
import { HttpLogoutReasonCode, HTTP_LOGOUT_REASON_MAP } from '../constants';
import { mapRemoteError } from 'modules/common/fetcher';
import { IRemoteError } from 'modules/common/store';
import { when } from 'mobx';

export const makeAuthErrorMiddleware =
  (authStore: AuthStore): IFetcherMiddleware =>
  (next) =>
  async (req) => {
    const result = await next(req);
    const errorStatus = result.success === false ? result.response?.status : undefined;
    const remoteError = result.success === false ? mapRemoteError(result) : undefined;
    if (remoteError && isLogoutReason(errorStatus) && shouldLogout(remoteError, errorStatus)) {
      authStore.tryLogOut(HTTP_LOGOUT_REASON_MAP[errorStatus]);
      if (authStore.currentUser && errorStatus === HttpLogoutReasonCode.Unauthorized) {
        authStore.sessionExpired(authStore.currentUser);
        return new Promise((res) => {
          when(
            () => authStore.isLoggedIn,
            async () => {
              await next(req).then(res);
            }
          );
        });
      }
    }
    return result;
  };

const shouldLogout = (remoteError: IRemoteError, errorStatus: HttpLogoutReasonCode) => {
  switch (errorStatus) {
    // case HttpLogoutReasonCode.Forbidden:
    //   return remoteError.switchError?.errorCode !== ApiError.KnownCode.INCORRECT_VERIFICATION_CODE;
    default:
      return true;
  }
};

const isLogoutReason = (errorStatus?: number): errorStatus is HttpLogoutReasonCode =>
  !!errorStatus && !!HTTP_LOGOUT_REASON_MAP[errorStatus as HttpLogoutReasonCode];
