import { Column, FilterType, TextFilterFormat } from '@mapper/admin-data-table';
import React from 'react';
import { DetailsLinkContainer } from '@mapper/admin-ui-kit';

import { IAppTransFunction } from 'startup/utils';
import { BlackboxData } from 'apis-generated/mapper-processing-admin/models';
import { ORGANIZATION_ENTITY_ID } from './details/constants';
import { BB_STATUS_MAP } from './constants';

export const getFinOrganizationColumns = (t: IAppTransFunction): Column<BlackboxData>[] => {
  return [
    {
      accessor: 'name',
      Header: t('fin_organizations_column_name'),
      customFilter: {
        field: 'foName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
      Cell: ({ value, row: { original } }) => {
        return (
          <DetailsLinkContainer
            entityId={original.bin!}
            title={original.name}
            entityType={ORGANIZATION_ENTITY_ID}
          />
        );
      },
    },

    {
      accessor: 'bin',
      Header: t('fin_organizations_column_bin'),
      customFilter: {
        field: 'bin',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },

    // {
    //   accessor: 'rootUrl',
    //   Header: t('fin_organizations_column_url'),
    //   customFilter: {
    //     field: 'rootUrl',
    //     format: TextFilterFormat.Text,
    //     type: FilterType.Text,
    //   },
    //   Cell: ({ value }) => {
    //     return <Link href={value}>{value}</Link>;
    //   },
    // },

    {
      accessor: 'status',
      Header: t('fin_organizations_column_status'),
      sortable: false,
      Cell: ({ value }) => {
        return value ? t(BB_STATUS_MAP[value]) : '';
      },
    },
  ];
};
