import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';
import React, { useCallback, useMemo, useState, FC } from 'react';
import { useTranslation } from 'startup/utils';
import { getAgreementHistoryColumns } from './tableDefs';
import {
  AgreementHistoryRespDto,
  DownloadAgreementTypeEnum,
} from 'apis-generated/mapper-sso-admin';
import Button from '@mui/material/Button';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { useNotificationsStore } from '@mapper/admin-ui-kit';

import { downloadFile } from 'modules/common/utils';
import { useAuthStore } from 'modules/auth/module';
import { EPermissionType } from 'modules/user-management';
import { NewAgreementModal } from '../NewAgreementModal';
import { useLegalAgreementService } from '../../module';
import { hasOneOfPermissions } from 'modules/auth/data/AuthUser';

interface IProps {}
export const LegalAgreementHistoryPane: FC<IProps> = () => {
  const { t } = useTranslation();
  const agreementService = useLegalAgreementService();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { showNotification } = useNotificationsStore();
  const { currentUser } = useAuthStore();

  const hasWritePermission = hasOneOfPermissions(currentUser!, [
    EPermissionType.AgreementWrite,
    EPermissionType.Superadmin,
  ]);

  const columns = useMemo(
    () =>
      getAgreementHistoryColumns(t, (dto, lang) => {
        pipe(
          agreementService.downloadAgreement(dto.id, DownloadAgreementTypeEnum.Document, lang),
          TE.map((file) => downloadFile(file, lang === 'kk' ? dto.nameKz : dto.nameRu)),
          TE.mapLeft(() => {
            showNotification({
              text: t('agreement_download_error'),
              options: { variant: 'error' },
            });
          })
        )();
      }),
    [agreementService, t, showNotification]
  );

  const { tableProps, toolBarProps } = useTable<AgreementHistoryRespDto>({
    fetchData: agreementService.fetchLegalAgreementHistory,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.id,
  });

  const handleCloseModal = useCallback(() => {
    setIsEdit(false);
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <TableToolbar<AgreementHistoryRespDto> {...toolBarProps} columns={columns}>
        {hasWritePermission && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setIsModalOpen(true)}
          >
            {t('agreement_add')}
          </Button>
        )}
      </TableToolbar>
      <DataTable<AgreementHistoryRespDto> {...tableProps} />
      {isModalOpen && (
        <NewAgreementModal open={isModalOpen} isEdit={isEdit} onClose={handleCloseModal} />
      )}
    </>
  );
};
