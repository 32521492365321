import React, { FC, useState, useCallback } from 'react';
import { Page, PageTitle, useNotificationsStore } from '@mapper/admin-ui-kit';
import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';
import * as TE from 'fp-ts/TaskEither';
import { Refresh } from '@mui/icons-material';
import { pipe } from 'fp-ts/lib/function';

import { DownloadPathParams, ReportHistoryItem } from 'apis-generated/reports';
import { downloadFile } from 'modules/common/utils';
import { useTranslation } from 'startup/utils';
import { Box, MenuItem, Button, Paper, Typography } from '@mui/material';
import { REPORT_FORM_TRANS_MAP, ReportFormEnum } from '../constants';
import { MealVoucherReportPage } from './MealVoucherReportPage';
import { StatReportPage } from './StatReportPage';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useReportService } from '../module';
import { useReportsColumns } from './tableDefs';
import { BusinessWalletByMerchantReportPage } from './BusinessWalletByMerchantReportPage';

interface IProps {}

export const ReportsPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationsStore();

  const [reportForm, setReportForm] = useState<ReportFormEnum>(ReportFormEnum.Form1);

  const handleChange = (e: SelectChangeEvent<ReportFormEnum>) => {
    const selected = ReportFormEnum[e.target.value as keyof typeof ReportFormEnum];
    setReportForm(selected);
  };
  const { list, download } = useReportService();
  const handleDownload = useCallback(
    (params: ReportHistoryItem) => {
      const fileName = params.name as string;
      pipe(
        download(params as DownloadPathParams),
        TE.map((file) => downloadFile(file, `${fileName}.xlsx`)),
        TE.mapLeft(() => {
          showNotification({
            text: t('common_download_error'),
            options: { variant: 'error' },
          });
        })
      )();
    },
    [download, showNotification, t]
  );
  const columns = useReportsColumns(t, handleDownload);

  const { tableProps, toolBarProps, refresh } = useTable({
    fetchData: list(),
    columns,
    keyGetter: (it) => it.id,
  });

  return (
    <Page style={{ overflow: 'auto' }}>
      <PageTitle>{t('reports_title')}</PageTitle>
      <Box mb={2}>
        <Paper>
          <Box p={2} display="flex" alignItems="center">
            <Box mr={2}>
              <Typography>{t('reports_form')}</Typography>
            </Box>
            <Select
              variant="outlined"
              style={{ minWidth: 160 }}
              value={reportForm ?? ''}
              onChange={handleChange}
            >
              {Object.entries(REPORT_FORM_TRANS_MAP).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {t(value)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Paper>
      </Box>
      {reportForm === ReportFormEnum.Form1 && <StatReportPage refresh={refresh} />}
      {reportForm === ReportFormEnum.Form2 && <MealVoucherReportPage refresh={refresh} />}
      {reportForm === ReportFormEnum.Form3 && (
        <BusinessWalletByMerchantReportPage refresh={refresh} />
      )}

      <Box mt={2}>
        <TableToolbar {...toolBarProps} columns={columns}>
          <Button startIcon={<Refresh />} variant="contained" color="primary" onClick={refresh}>
            {t('common_refresh')}
          </Button>
        </TableToolbar>
        <DataTable {...tableProps} />
      </Box>
    </Page>
  );
};
