import React, { useState, useMemo, useContext, useCallback } from 'react';
import {
  ISecondAuthCodeModalProps as SecondAuthCodeModalPropsOrig,
  SecondAuthCodeModal,
} from './SecondAuthModal/SecondAuthCodeModal';

export type SecondAuthCodeModalProps = Omit<SecondAuthCodeModalPropsOrig, 'onClose'>;

interface AuthModalContext {
  showSecondAuthModal: (options: SecondAuthCodeModalProps) => void;
}

const AuthContext = React.createContext<AuthModalContext>({
  showSecondAuthModal: () => Promise.resolve(),
});

export const AuthModalsProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const [currentModal, setCurrentModal] = useState<SecondAuthCodeModalProps | undefined>(undefined);

  const contextValue: AuthModalContext = useMemo(
    () => ({
      showSecondAuthModal: (props) => {
        setCurrentModal(props);
      },
    }),
    []
  );

  const handleClose = useCallback(() => {
    setCurrentModal(undefined);
  }, []);

  return (
    <>
      <AuthContext.Provider value={contextValue}>
        {props.children}
        {currentModal && (
          <SecondAuthCodeModal
            onClose={handleClose}
            onSubmit={currentModal.onSubmit}
          ></SecondAuthCodeModal>
        )}
      </AuthContext.Provider>
    </>
  );
};

export const useAuthModals = () => {
  const { showSecondAuthModal } = useContext(AuthContext);

  return {
    showSecondAuthModal,
  };
};
