import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import { ru as ruDateFnsLocale } from 'date-fns/locale';

import { DataTableTransKey } from '@mapper/admin-data-table';
import { IAdminUiKitTransKey } from '@mapper/admin-ui-kit';
import { ITransFunction as TTransFunction } from '@pay/i18n-utils';

import ru from './ru.json';

export enum ELang {
  Ru = 'ru',
}

export enum EApiLang {
  Ru = 'ru',
  Kk = 'kk',
}

export type ITransKey = Extract<keyof typeof ru, string> | IAdminUiKitTransKey | DataTableTransKey;

// We cast to ensure all translation keys are presented
const ruTrans: Record<ITransKey, string> = {
  ...ru,
  not_enough_permission: '',
};

export const initializei18n = () => {
  i18next.use(initReactI18next).init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    debug: process.env.NODE_ENV !== 'production',
    defaultNS: 'app',
    lng: ELang.Ru, // 'en' | 'es'
    fallbackLng: ['ru', 'kk'],
    whitelist: ['ru', 'kk'],
    // Using simple hardcoded resources for simple example
    resources: {
      ru: {
        app: ruTrans,
      },
    },
  });

  return i18next;
};

export const DATE_FNS_LOCALE_MAP: Record<ELang, Locale> = {
  [ELang.Ru]: ruDateFnsLocale,
};

export type ITransFunction = TTransFunction<ITransKey>;
