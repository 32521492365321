import { createTheme } from '@mui/material/styles';

const PRIMARY_MAIN = '#2F77ED';
const BACKGROUND = '#F3F3F3';
const SPACING_UNIT = 8;

const muiLightTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: '#707070',
    },
    background: {
      default: BACKGROUND,
    },
    error: {
      main: '#D1745F',
    },
    warning: {
      main: '#D4AD47',
    },
    action: {
      selected: BACKGROUND,
    },
    success: {
      main: '#82ca9d',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  spacing: SPACING_UNIT, // Обеспечивает единообразие в отступах
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          letterSpacing: '0.25px',
          fontWeight: 500,
        },
      },
      defaultProps: {
        disableElevation: true,
        size: 'small',
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontWeight: 500,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: SPACING_UNIT * 2,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
        spacing: {
          padding: SPACING_UNIT * 2,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

const muiDarkTheme = createTheme({
  palette: {
    mode: 'dark', // Заменяет `type: 'dark'`
    primary: {
      main: '#90caf9',
    },
    error: {
      main: '#D1745F',
    },
    success: {
      main: '#82ca9d',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: muiLightTheme.components, // Используем те же настройки компонентов
});

export const BODY_1_LINE_HEIGHT_PX = 24;

export { muiLightTheme, muiDarkTheme };
