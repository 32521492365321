import { Column, FilterType, TextFilterFormat } from '@mapper/admin-data-table';
import { IAppTransFunction } from 'startup/utils';
import { useMemo } from 'react';
import { ReportHistoryItem } from 'apis-generated/reports';
import React from 'react';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';
import { IconButton } from '@mui/material';
import { GetApp } from '@mui/icons-material';

export const useReportsColumns = (
  t: IAppTransFunction,
  handleDownload: (params: ReportHistoryItem) => void
): Column<ReportHistoryItem>[] => {
  return useMemo(
    (): Column<ReportHistoryItem>[] => [
      {
        accessor: 'name',
        Header: t('common_title_name'),
        sortable: false,
        customFilter: {
          field: 'name',
          format: TextFilterFormat.Text,
          type: FilterType.Text,
        },
      },
      {
        accessor: 'status',
        Header: t('clients_column_status'),
        sortable: false,
        customFilter: {
          field: 'number',
          format: TextFilterFormat.Text,
          type: FilterType.Text,
        },
      },
      {
        accessor: 'dateTime',
        Header: t('common_date'),
        Cell: ({ row: { original }, value }) => (
          <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
        ),
      },
      {
        accessor: 'id',
        width: 60,
        disableResizing: true,
        horizontalAlign: 'center',
        Cell: ({ row: { original } }) =>
          original.status === 'DONE' && (
            <IconButton size="small" onClick={() => handleDownload(original)}>
              <GetApp />
            </IconButton>
          ),
      },
    ],
    [t, handleDownload]
  );
};
