import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { Page, PageTitle } from '@mapper/admin-ui-kit';
import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EPermissionType } from 'modules/user-management/constants';
import { useAuthStore } from 'modules/auth/module';
import { RolesPane } from '../../roles/ui/RolesPane';
import { UsersPane } from './UsersPane';
import { useTheme } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { hasOneOfPermissions } from 'modules/auth/data/AuthUser';

enum ETab {
  Users = 'users',
  Roles = 'roles',
}

const useStyles = () => {
  const theme = useTheme();

  return {
    root: {},
    tabsRoot: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(2),
      flex: 0,
    },
  };
};

export const UserManagementPage: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentUser } = useAuthStore();

  const permittedTabs = useMemo(
    () =>
      [
        {
          tab: ETab.Users,
          perms: [
            EPermissionType.UsersRead,
            EPermissionType.RolesRead,
            EPermissionType.System,
            EPermissionType.Superadmin,
          ],
        },
        {
          tab: ETab.Roles,
          perms: [
            EPermissionType.UsersRead,
            EPermissionType.RolesRead,
            EPermissionType.System,
            EPermissionType.Superadmin,
          ],
        },
      ]
        .filter(({ tab, perms }) => hasOneOfPermissions(currentUser!, perms))
        .map((t) => t.tab),
    [currentUser]
  );

  const tab = useParams<{ tab: ETab }>().tab || permittedTabs[0];
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: ETab) => {
    navigate(`/user-management/${newValue}`);
  };

  return (
    <>
      <Page>
        <PageTitle>{t('users_page_title')}</PageTitle>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          classes={classes.tabsRoot}
        >
          {permittedTabs.includes(ETab.Users) && (
            <Tab value={ETab.Users} label={t('users_page_tab_users')} />
          )}
          {permittedTabs.includes(ETab.Roles) && (
            <Tab value={ETab.Roles} label={t('users_page_tab_roles')} />
          )}
        </Tabs>
        {tab === ETab.Users && <UsersPane />}
        {tab === ETab.Roles && <RolesPane />}
      </Page>
    </>
  );
};
