import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  Button as MuiButton,
} from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'startup/utils';
import { usePosTerminalsService } from '../module';
import { useTaskEither } from 'modules/common/async/hooks';
import { useForm } from 'react-hook-form';
import { ErrorAlert } from 'modules/common/ui/errors';
import { ITransKey } from 'startup/i18n';
import { CloudUpload } from '@mui/icons-material';
import { Button } from '@pay/mui-enhancement';
import { useNotificationsStore } from '@mapper/admin-ui-kit';
import { IS_DEV } from 'startup/constants';

interface IProps extends Omit<DialogProps, 'children'> {
  onClose?: () => void;
  onUploaded?: () => void;
}

type Values = {
  file: FileList;
};

// TODO: make cancel upload apk (AbortController)
export const UploadApkModal: FC<IProps> = ({ onClose, onUploaded, ...dialogProps }) => {
  const { t } = useTranslation();

  const { uploadApk } = usePosTerminalsService();
  const { execute, state } = useTaskEither(uploadApk);

  const { showNotification } = useNotificationsStore();

  const {
    handleSubmit,
    formState,
    formState: { errors },
    register,
    watch,
  } = useForm<Values>({ mode: 'onChange' });
  const file = watch('file');

  const handleUploadApk = useCallback(
    (values: Values) => {
      if (values.file[0]) {
        const formData = new FormData();
        const file = values.file[0];
        formData.append('fileapk', file);
        return execute(formData);
      }
    },
    [execute]
  );

  useEffect(() => {
    if (state?.value) {
      onUploaded?.();
      showNotification({ options: { variant: 'success' }, text: t('pos_terminal_uploaded') });
      onClose?.();
    }
  }, [state, onUploaded, onClose, showNotification, t]);

  return (
    <>
      <Dialog
        {...dialogProps}
        onClose={onClose}
        PaperProps={{
          style: { width: 488 },
          component: 'form',
          onSubmit: handleSubmit(handleUploadApk),
        }}
      >
        <DialogTitle>{t('pos_terminal_upload')}</DialogTitle>
        <DialogContent dividers>
          <ErrorAlert error={state?.error} />
          <Box mb={1} display="flex" flexDirection="column" alignItems="center">
            <MuiButton
              variant="contained"
              component="label"
              color="primary"
              startIcon={<CloudUpload />}
            >
              {t('common_upload')}

              <input
                {...register('file', {
                  validate: makeFileValidete(t),
                })}
                type="file"
                hidden
                accept=".apk"
                name="file"
              />
            </MuiButton>
            {errors.file && (
              <FormHelperText error={true}>{t(errors.file.message as ITransKey)}</FormHelperText>
            )}
            {file && <FormHelperText>{file[0]?.name}</FormHelperText>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('common_close')}
          </Button>
          <Button
            loading={formState.isSubmitting}
            disabled={!formState.isValid}
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
          >
            {t('common_upload')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const makeFileValidete = (t: (key: ITransKey) => string) => (value?: FileList) => {
  if (!value?.[0]) return t('pos_terminal_apk_required');

  const file = value[0];
  const maxFileSize = !IS_DEV ? 20 * 1024 * 1024 : 50 * 1024 * 1024;
  if (file.size > maxFileSize) {
    return t('pos_terminal_apk_upload_size_error');
  }
};
