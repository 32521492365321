import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { Logo } from './Logo';

const Root = styled('div')({
  display: 'flex',
  height: '100vh',
});

const LeftContent = styled('div')(({ theme }) => ({
  background:
    theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.background.default,
  height: '100vh',
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  padding: theme.spacing(6),
  width: 4 + theme.spacing(6),
  overflowY: 'auto',
}));

const LogoWrap = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  padding: theme.spacing(2),
  flex: '0 0 35vh',
}));

const FormWrap = styled('div')({
  flex: '1 0 auto',
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  position: 'relative',
  flexFlow: 'column',
});

const SplashWrap = styled('div')<{ backgroundUrl: string }>(({ backgroundUrl }) => ({
  backgroundImage: `url(${backgroundUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  flexGrow: 1,
  minHeight: '100vh',
  width: '100%',
}));

const Copyright = styled(Typography)({
  flex: '0 0 auto',
  textAlign: 'center',
});

interface IProps {
  backgroundUrl: string;
}

export const AuthFormWrapper: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { backgroundUrl } = props;
  // const classes = useStyles();

  return (
    <Root>
      <LeftContent>
        <LogoWrap>
          <Box>
            <Logo />
          </Box>
        </LogoWrap>
        <FormWrap>{props.children}</FormWrap>
        <Copyright variant="body2" color="textSecondary">
          @ 2020 Backoffice LTD
        </Copyright>
      </LeftContent>
      <SplashWrap backgroundUrl={backgroundUrl} />
    </Root>
  );
};
