import React from 'react';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import { INavigationLinkGroup, INavigationLinkUnion } from '@mapper/admin-ui-kit';

import { CurrentUserModel } from 'modules/auth';
// import { RouteConfig } from 'modules/common/routing';
import { EPermissionType } from 'modules/user-management';
import { IAppTransFunction } from 'startup/utils';
// import { hasOneOfPermissions, isServiceMealAdmin } from '../utils/permission';
import { MealAdminPage } from './MealAdminPage';
import { ReportsPage } from 'modules/reports';
import { SchoolsPage } from 'modules/schools';
import { RouteProps } from 'react-router-dom';
import { hasOneOfPermissions } from 'modules/auth/data/AuthUser';
// import { isServiceMealAdmin } from '../utils/permission';
import * as O from 'fp-ts/Option';

const reportPaths = {
  admin: '/meals/admin',
  reports: '/meals/reports',
  schools: '/meals/schools',
} as const;

type RouteConfig = RouteProps & {
  path: string;
  permissions?: EPermissionType[];
};

export const getNavigationLinks = ({
  userModel,
  t,
}: {
  userModel: CurrentUserModel;
  t: IAppTransFunction;
}): O.Option<INavigationLinkUnion> => {
  if (
    !hasOneOfPermissions(userModel, [
      EPermissionType.ServiceMealReports,
      EPermissionType.Superadmin,
    ])
  )
    return O.none;

  const link: INavigationLinkGroup = {
    group: true,
    menuIcon: InsertDriveFileOutlined,
    title: t('navigation_service_meals'),
    key: 'meals',
    subLinks: [
      {
        child: true,
        group: false,
        path: reportPaths.reports,
        end: false,
        title: t('navigation_reports_nb'),
      },
    ],
  };
  if (
    hasOneOfPermissions(userModel, [EPermissionType.ServiceMealReports, EPermissionType.Superadmin])
  ) {
    const adminSublink: INavigationLinkGroup['subLinks'][number] = {
      child: true,
      group: false,
      path: reportPaths.admin,
      end: false,
      title: t('navigation_service_meals_admin'),
    };
    link.subLinks = [adminSublink, ...link.subLinks];
  }
  if (
    hasOneOfPermissions(userModel, [EPermissionType.ServiceMealReports, EPermissionType.Superadmin])
  ) {
    const schoolSublink: INavigationLinkGroup['subLinks'][number] = {
      child: true,
      group: false,
      path: reportPaths.schools,
      end: false,
      title: t('navigation_service_meals_schools'),
    };
    link.subLinks = [...link.subLinks, schoolSublink];
  }
  return link ? O.some(link) : O.none;
};

export const getRoutes = (): RouteConfig[] => {
  return [
    {
      path: reportPaths.admin,
      element: <MealAdminPage />,
      permissions: withdminPermissions([
        EPermissionType.Superadmin,
        EPermissionType.ServiceMealAdmin,
      ]),
    },
    {
      path: reportPaths.reports,
      element: <ReportsPage />,
      permissions: withdminPermissions([EPermissionType.ReportsRead, EPermissionType.Superadmin]),
    },
    {
      path: reportPaths.schools,
      element: <SchoolsPage />,
      permissions: [EPermissionType.Superadmin],
    },
  ];
};

const withdminPermissions = (permissions: EPermissionType[] = []) => [
  EPermissionType.ServiceMealAdmin,
  ...permissions,
];
