import * as z from 'zod';
import * as TE from 'fp-ts/TaskEither';
import React, { useCallback, useMemo, useState } from 'react';
import { useTaskEither } from 'async-state-ts-react-hooks';
import {
  Box,
  createFilterOptions,
  debounce,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from 'modules/common/ui/errors';
import { Button } from '@pay/mui-enhancement';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { shouldTrans } from 'modules/common/utils';
import { IRemoteError } from 'modules/common/store';
import { AsyncInfinityAutoComplete } from 'modules/common/ui/AsyncInfinityAutoComplete';
import { pipe } from 'fp-ts/lib/function';
import { useClientService } from 'modules/clients/module';
import { TEtoPromise } from 'modules/common/fpts-utils';
import { Autocomplete } from '@mui/lab';
import { LegalIdRoleEnum } from 'apis-generated/mapper-processing-admin';
import { ROLE_LABEL_MAP } from '../details/BusinessAccountInfoPane/Participants/tableDefs';

const RoleEnum = z.nativeEnum(LegalIdRoleEnum, {
  invalid_type_error: 'business_accounts_participant_role_not_selected_error',
  required_error: 'business_accounts_participant_role_not_selected_error',
});

const Employee = z.object(
  {
    username: z.string(),
    firstName: z.string().optional().nullable(),
    lastName: z.string().optional().nullable(),
    iin: z.string().optional().nullable(),
  },
  { required_error: 'validation_required' }
);

type Employee = z.infer<typeof Employee>;

const schema = z.object({
  user: Employee,
  role: RoleEnum,
});

export type Values = Zod.infer<typeof schema>;

export type EmployeeFormProps = {
  onClose?: () => void;
  onSubmit: (values: Values) => TE.TaskEither<IRemoteError, unknown>;
};

export const EmployeeForm = (props: EmployeeFormProps) => {
  const { onClose, onSubmit } = props;
  const { t } = useTranslation();

  const [symbol, setSymbol] = useState('');

  const [submitState, execSubmit] = useTaskEither(onSubmit);
  const { getClientsByValue } = useClientService();

  const {
    control,
    formState,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    resolver: zodResolver(schema),
  });

  const handleSubmitInternal: SubmitHandler<Values> = useCallback(
    (values) => {
      return execSubmit(values);
    },
    [execSubmit]
  );

  const handleSymbolChange = useMemo(() => debounce((value: string) => setSymbol(value), 1000), []);

  const rolesOptions = useMemo(() => {
    return [
      LegalIdRoleEnum.Head,
      LegalIdRoleEnum.EmployeeWithSignPrivilege,
      LegalIdRoleEnum.Employee,
      LegalIdRoleEnum.Hr,
    ];
  }, []);

  return (
    <>
      <DialogContent dividers>
        <ErrorAlert error={submitState.error} />
        <Box
          component="form"
          id={FORM_ID}
          mb={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Controller
            control={control}
            name={'user'}
            render={({ field: { ref, ...fieldProps }, fieldState }) => (
              <AsyncInfinityAutoComplete
                fullWidth
                symbol={symbol}
                onLoadItems={(symbol: string) => pipe(getClientsByValue(symbol), TEtoPromise)()}
                inputProps={{
                  label: 'Сотрудник',
                  helperText: fieldState.error?.message && t(fieldState.error?.message),
                  error: !!fieldState.error,
                }}
                freeSolo
                {...fieldProps}
                getOptionLabel={(user) => {
                  if (!user || typeof user !== 'object') return '';
                  return user.username || user.firstName || user.lastName || '';
                }}
                filterOptions={createFilterOptions({
                  stringify: (option) =>
                    `${option.firstName ? option.firstName : ''}${
                      option.lastName ? option.lastName : ''
                    }${option.username ? option.username : ''}${option.iin ? option.iin : ''}`,
                })}
                renderOption={(props, op) => (
                  <Box component="li" {...props} key={op.username}>
                    <Typography>{op.username}</Typography>
                    <Typography>{op.iin}</Typography>
                    <Typography>
                      {op.firstName} {op.lastName}
                    </Typography>
                  </Box>
                )}
                onInputChange={(_, value) => {
                  handleSymbolChange(value);
                }}
                onChange={(_, value) => {
                  fieldProps.onChange(value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="role"
            render={({ field: { ...fieldProps }, fieldState }) => (
              <Autocomplete<LegalIdRoleEnum>
                onChange={(a, b) => {
                  fieldProps.onChange(b);
                }}
                options={rolesOptions}
                fullWidth
                disabled={formState.isSubmitting}
                getOptionLabel={(it) => t(ROLE_LABEL_MAP[it])}
                noOptionsText={t('common_table_empty')}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    error={!!fieldState.error}
                    helperText={t(fieldState.error?.message!)}
                    {...params}
                    label={t('business_accounts_participant_role')}
                  />
                )}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button onClick={onClose} color="primary">
            {t('common_close')}
          </Button>
        )}
        <Button
          loading={formState.isSubmitting}
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleSubmitInternal)}
          form={FORM_ID}
        >
          {shouldTrans('Добавить')}
        </Button>
      </DialogActions>
    </>
  );
};

const FORM_ID = 'new-entrepreneur-form';
