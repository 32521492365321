import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { Dialog, DialogProps, DialogTitle } from '@mui/material';

import { useTranslation } from 'modules/common/utils';
import React, { FC, useCallback } from 'react';
import { SchoolForm } from './SchoolForm';
import { SchoolViewDTO } from 'apis-generated/school-meal';

type CreateSchoolModalProps = Omit<DialogProps, 'children'> & {
  onClose?: () => void;
  onSuccess: () => void;
  school?: SchoolViewDTO;
};

export const CreateSchoolModal: FC<CreateSchoolModalProps> = (props) => {
  const { t } = useTranslation();
  const { onClose, onSuccess, school, ...dialogProps } = props;

  const handleSucessAdded = useCallback(() => {
    onSuccess();
    onClose?.();
  }, [onSuccess, onClose]);

  return (
    <>
      <Dialog
        {...dialogProps}
        fullWidth
        maxWidth="xs"
        onClose={onClose}
        PaperProps={{
          component: 'form',
        }}
      >
        <DialogTitle>
          {t(school ? 'meal_school_edit_school_title' : 'meal_school_create_school_title')}
        </DialogTitle>

        <SchoolForm onSuccess={handleSucessAdded} onClose={() => onClose?.()} school={school} />
      </Dialog>
    </>
  );
};
