import React from 'react';
import { observer } from 'mobx-react-lite';
import { AuthExpiredSessionModalFlow } from './AuthExpiredSessionModalForm';
import { useAuthStore } from '../../';
import * as AuthState from '../../core/AuthState';
import { Dialog } from '@mui/material';

export const AuthExpiredSessionModal: React.FC<React.PropsWithChildren<unknown>> = observer(() => {
  const { state } = useAuthStore();
  const isExpiredSession = AuthState.isSessionExpired(state);

  return (
    <Dialog disableEscapeKeyDown maxWidth="xs" open={isExpiredSession}>
      <AuthExpiredSessionModalFlow />
    </Dialog>
  );
});
