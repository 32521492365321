import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, TableToolbar, useTable } from '@mapper/admin-data-table';
import { useNotificationsStore } from '@mapper/admin-ui-kit';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { ClientDetailRespDto, ClientRegistrationResponse } from 'apis-generated/mapper-sso-admin';
import { useClientService } from '../../../module';
import { getClientDevicesColumns } from './tableDefs';

interface IProps {
  client: ClientDetailRespDto;
  onRefreshClient: () => void;
}

export const ClientDevicesPane: FC<IProps> = ({ client, onRefreshClient }) => {
  const { t } = useTranslation();
  const service = useClientService();
  const { showNotification } = useNotificationsStore();

  const columns = useMemo(
    () =>
      getClientDevicesColumns(t, async ({ registrationId }) => {
        return pipe(
          service.deregisterUser(registrationId),
          TE.map(() => {
            showNotification({
              text: t('client_details_devices_deregister_success'),
              options: { variant: 'success' },
            });
            onRefreshClient();
          }),
          TE.mapLeft(() => {
            showNotification({
              text: t('client_details_devices_deregister_failure'),
              options: { variant: 'error' },
            });
          })
        )();
      }),
    [onRefreshClient, service, showNotification, t]
  );

  const { tableProps, toolBarProps } = useTable<ClientRegistrationResponse>({
    fetchData: async () => ({
      items: client.registrations ?? [],
      total: client.registrations?.length ?? 0,
    }),
    columns: columns,
    keyGetter: (item) => item.registrationId,
  });

  return (
    <div>
      <TableToolbar<ClientRegistrationResponse> columns={columns} {...toolBarProps} />
      <DataTable<ClientRegistrationResponse> {...tableProps} />
    </div>
  );
};
