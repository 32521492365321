import { delay } from '@pay/common-utils';
import { makeUseTranslation } from '@pay/i18n-utils';
import { ITransKey } from 'startup/i18n';

export const useTranslation = makeUseTranslation<ITransKey>();

export const delayedResolve =
  <T>(value: T, delayMs = 0) =>
  async () => {
    await delay(delayMs);
    return value;
  };

export const downloadFile = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const shouldTrans = (v: string) => v;

export const removeEmptyKeys = (obj: Record<string, unknown>) =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as Record<string, unknown>);
