import React, { FC, useCallback, useState } from 'react';
import { Box, Paper, Typography, TextField, MenuItem, Grid } from '@mui/material';
import { endOfToday, startOfToday, subMonths } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
import { useNotificationsStore } from '@mapper/admin-ui-kit';
import { RifmTextField, Button, makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';
import { formatInteger } from '@pay/common-utils';

import { DATE_FORMAT } from 'modules/common/constants';
import { downloadFile, removeEmptyKeys } from 'modules/common/utils';
import { AsyncSelect } from 'modules/common/ui/AsyncSelect/AsyncSelect';
import { TEtoPromise } from 'modules/common/fpts-utils';
import { useTranslation } from 'startup/utils';
import { useCompanyService, useReportService } from '../module';
import * as TE from 'fp-ts/TaskEither';
import { formatDate } from 'modules/common/format';
import { BOReportRequestMealTypeEnum, BOReportRequestSearchTypeEnum } from 'apis-generated/reports';
import { MEAL_TYPE_TRANS_MAP, SEARCH_TYPE_TRANS_MAP } from '../constants';
import { LocationAndSchoolForm } from './LocationAndSchoolForm';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  refresh: () => void;
}

const schema = z.object({
  dateFrom: z.date(),
  dateTo: z.date(),
  searchType: z.nativeEnum(BOReportRequestSearchTypeEnum),
  serviceCompanyId: z.number().optional(),
  mealType: z.nativeEnum(BOReportRequestMealTypeEnum).optional(),
  childIin: z.string().optional(),
  schoolBin: z.string().optional(),
  locationCode: z.string().optional(),
});
export type FormValues = Zod.infer<typeof schema>;

export const MealVoucherReportPage: FC<Props> = ({ refresh }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showNotification } = useNotificationsStore();
  // const classes = useStyles();

  const reportService = useReportService();
  const companyService = useCompanyService();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      dateFrom: subMonths(startOfToday(), 1),
      dateTo: endOfToday(),
      searchType: BOReportRequestSearchTypeEnum.School,
    },
  });

  const [searchType, setSearchType] = useState<BOReportRequestSearchTypeEnum>();

  const handleFetchServiceCompanies = useCallback(
    () => pipe(companyService.getServiceCompanies(), TEtoPromise)(),
    [companyService]
  );

  const handleSubmitValues = useCallback(
    (values: FormValues) => {
      if (values.searchType !== BOReportRequestSearchTypeEnum.Student) {
        values.childIin = undefined;
      }
      return pipe(
        reportService.downloadExcelReportBySchoolVauchers(removeEmptyKeys(values) as FormValues),
        TE.map(() => refresh()),
        TE.mapLeft((err) => {
          showNotification({
            text: t('common_download_error'),
            options: { variant: 'error' },
          });
        })
      )();
    },
    [reportService, refresh, showNotification, t]
  );

  return (
    <Paper>
      <Box p={2}>
        <form onSubmit={handleSubmit(handleSubmitValues)}>
          <Box mt={2} mb={2}>
            <Controller
              name={'searchType'}
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  label={t('report_meal_voucher_search_type')}
                  select
                  sx={{ marginRight: theme.spacing(2), minWidth: 155 }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setSearchType(e.target.value as BOReportRequestSearchTypeEnum);
                  }}
                >
                  {Object.entries(SEARCH_TYPE_TRANS_MAP).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {t(value)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name={'mealType'}
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  label={t('report_meal_voucher_meal_type')}
                  select
                  sx={{ marginRight: theme.spacing(2), minWidth: 155 }}
                  {...field}
                >
                  <MenuItem>{t('common_not_selected')}</MenuItem>
                  {Object.entries(MEAL_TYPE_TRANS_MAP).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {t(value)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name={'serviceCompanyId'}
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  size="small"
                  sx={{ marginRight: theme.spacing(2), minWidth: 155 }}
                  style={{ minWidth: 260 }}
                  label={t('report_meal_voucher_service_company')}
                  onLoadItems={handleFetchServiceCompanies}
                  {...makeRhfMuiTextFieldProps(errors.serviceCompanyId, t)}
                  {...field}
                >
                  {(serviceCompanies) => [
                    <MenuItem key="not-selected">{t('common_not_selected')}</MenuItem>,
                    ...serviceCompanies?.map((company) => (
                      <MenuItem value={company.id} key={company.id}>
                        {company.name}
                      </MenuItem>
                    )),
                  ]}
                </AsyncSelect>
              )}
            />
          </Box>

          <Grid container direction="column" spacing={2}>
            <Grid container item key={1} alignItems="center">
              <Grid item xs={1}>
                <Box mr={2}>
                  <Typography>{t('dashboard_period')}</Typography>
                </Box>
              </Grid>
              <Controller
                name={'dateFrom'}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    disableFuture
                    slotProps={{
                      textField: {
                        size: 'small',
                        variant: 'outlined',
                        style: { marginRight: theme.spacing(2) },
                      },
                    }}
                    format={DATE_FORMAT.DD_MM_YYYY}
                    label={t('common_range_from')}
                    {...field}
                  />
                )}
              />
              <Controller
                name={'dateTo'}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    disableFuture
                    slotProps={{
                      textField: {
                        size: 'small',
                        variant: 'outlined',
                        style: { marginRight: theme.spacing(2) },
                      },
                    }}
                    format={DATE_FORMAT.DD_MM_YYYY}
                    label={t('common_range_to')}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <LocationAndSchoolForm control={control} />
          {searchType === BOReportRequestSearchTypeEnum.Student && (
            <Box mt={2}>
              <Grid container item key={1} alignItems="center">
                <Grid item xs={1}>
                  <Box>
                    <Typography>{t('report_meal_voucher_child_iin')}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <Box>
                    <Controller
                      name={'childIin'}
                      control={control}
                      render={({ field }) => (
                        <RifmTextField
                          size="small"
                          rifm={{ format: formatInteger }}
                          style={{
                            marginRight: theme.spacing(2),
                          }}
                          inputProps={{ maxLength: 12 }}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box mt={2}>
            <Button variant="contained" type="submit" color="primary" loading={isSubmitting}>
              {t('common_download')}
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
