import {
  CircularProgress,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material';
import * as AsyncState from 'async-state-ts/AsyncState';
import React, { forwardRef, RefAttributes, useEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import { useTranslation } from 'react-i18next';

interface AsyncSelectProps<T> extends Omit<TextFieldProps, 'select' | 'children'> {
  onLoadItems: () => Promise<T[]>;
  children: (items: T[]) => React.ReactNode;
  emptyMessage?: string;
}
// Assertion used to keep generic parameter
type AsyncSelectType = <T>(
  props: AsyncSelectProps<T> & RefAttributes<HTMLDivElement>
) => JSX.Element;
export const AsyncSelect = forwardRef<HTMLDivElement, AsyncSelectProps<unknown>>(
  ({ children, onLoadItems, emptyMessage, ...textFieldProps }, ref) => {
    type State = AsyncState.AsyncState<unknown, unknown[]>;
    const { t } = useTranslation();

    const [state, setState] = useState<State>(AsyncState.loading());
    const [attempt, setAttempt] = useState(0);
    const isMounted = useMountedState();

    useEffect(() => {
      setState(AsyncState.loading());
      onLoadItems().then(
        (res) => isMounted() && setState(AsyncState.success(res)),
        (err) => isMounted() && setState(AsyncState.error(err))
      );
    }, [onLoadItems, isMounted, attempt]);

    const renderHelperText = () => {
      if (state.error) {
        return (
          <>
            {t('common_data_download_error')}.{' '}
            <Link style={{ cursor: 'pointer' }} onClick={() => setAttempt((a) => a + 1)}>
              {t('common_retry')}
            </Link>
          </>
        );
      }

      return textFieldProps.helperText;
    };

    const renderValue = (items: unknown[]) => {
      if (items.length === 0 && emptyMessage) {
        return <MenuItem disabled>{emptyMessage}</MenuItem>;
      }
      return children(items);
    };

    return (
      <TextField
        ref={ref}
        select={!!state.value}
        {...textFieldProps}
        InputProps={{
          endAdornment: state.loading ? (
            <InputAdornment style={{ marginRight: 3 }} position="end">
              <CircularProgress size={24} />
            </InputAdornment>
          ) : undefined,
        }}
        disabled={!state.value || textFieldProps.disabled}
        helperText={renderHelperText()}
        error={!!state.error || textFieldProps.error}
      >
        {state.value && renderValue(state.value)}
      </TextField>
    );
  }
) as AsyncSelectType;
