import { BackofficeUserRespDto } from 'apis-generated/mapper-sso-admin';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as String from 'fp-ts/string';
import { EPermissionType } from 'modules/user-management';

export type AuthUser = BackofficeUserRespDto;

const getPermissionNames = (user: AuthUser) => {
  return (
    user.roles
      ?.flatMap((role) => role?.permissions)
      .filter((perm) => !!perm)
      .map((perm) => perm!.name as EPermissionType) ?? []
  );
};

export const hasAllPermissions = (
  currentUser: AuthUser,
  permissions: EPermissionType[]
): boolean => {
  const currentPermissions = getPermissionNames(currentUser);

  return pipe(A.difference(String.Eq)(permissions, currentPermissions), A.isEmpty);
};

export const hasOneOfPermissions = (currentUser: AuthUser, permissions: EPermissionType[]) => {
  const currentPermissions = getPermissionNames(currentUser);
  return currentPermissions.length > 0
    ? permissions.some((perm) => currentPermissions.some((currentPerm) => currentPerm === perm))
    : permissions.length === 0
    ? true
    : false;
};
