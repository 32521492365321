import { Card, CardActions, CardContent, CardHeader, Divider } from '@mui/material';
import { Alert } from '@mui/material';
import { Button } from '@pay/mui-enhancement';
import React from 'react';
import { useTranslation } from '../utils';

interface IProps {
  onReset: () => void;
}
export const ResetSecondAuth: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { onReset } = props;

  return (
    <>
      <Card>
        <CardHeader title={t('settings_second_auth')} titleTypographyProps={{ variant: 'h6' }} />
        <Divider />
        <CardContent>
          <Alert severity="info">{t('settings_second_auth_reset_description')}</Alert>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            onClick={onReset}
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            size="medium"
          >
            {t('settings_second_auth_reset')}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
