import React, { useCallback, FC } from 'react';
import { styled, Tab } from '@mui/material';
import {
  DetailsError,
  DetailsLoading,
  DetailsView,
  IDetailsComponentProps,
  Tabs as MuiTabs,
} from '@mapper/admin-ui-kit';
import { capitalize } from '@pay/common-utils';

import { useTaskEitherImmediate } from 'modules/common/async/hooks';
import { useDetailsStore } from 'modules/common/details';
import { useTranslation } from 'startup/utils';
import { ORGANIZATION_ENTITY_ID } from './constants';
import { useFinOrganizationsService } from '../../module';
import { OrganizationInfoPane } from './OrganizationInfoPane';

enum ETab {
  Default = '',
  // Keys = 'keys',
  // Logs = 'logs',
}

const ContentRoot = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
  overflow: 'auto',
  flexGrow: 1,
}));

const TabsRoot = styled(MuiTabs)(({ theme }) => ({
  padding: `0 ${theme.spacing(6)}px`,
  marginLeft: theme.spacing(2),
}));

interface ITransactionDetailsProps extends IDetailsComponentProps {}
export const OrganizationDetails: FC<ITransactionDetailsProps> = ({
  tab = ETab.Default,
  ...props
}) => {
  const { t } = useTranslation();
  const detailsStore = useDetailsStore();
  const organizationsService = useFinOrganizationsService();

  const handleTabChange = useCallback(
    (_: unknown, tab: ETab) => {
      detailsStore.openDetails({
        entityId: props.itemId,
        entityType: ORGANIZATION_ENTITY_ID,
        tab: tab,
      });
    },
    [detailsStore, props.itemId]
  );

  const { state: orgState, retry: orgRetry } = useTaskEitherImmediate(() =>
    organizationsService.fetchOrganization(props.itemId)
  );

  const renderContent = () => {
    if (orgState.loading) {
      return <DetailsLoading />;
    }

    if (orgState.error) {
      return <DetailsError />;
    }

    const org = orgState.value;

    return (
      <>
        <TabsRoot
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab value={ETab.Default} label={t('organization_details_tab_info')} />
          {/* <Tab value={ETab.Keys} label={t('organization_details_tab_key')} />
          <Tab value={ETab.Logs} label={t('organization_details_tab_logs')} />  */}
        </TabsRoot>
        <ContentRoot>
          {tab === ETab.Default && <OrganizationInfoPane onRefresh={orgRetry} organization={org} />}
          {/* {tab === ETab.Keys && <OrganizaitonKeysPane organization={org} />}
          {tab === ETab.Logs && <OrganizationLogsPane organization={org} />}  */}
        </ContentRoot>
      </>
    );
  };

  const title = orgState.value && `${capitalize(t('fin_organization'))}: ${orgState.value.name}`;

  return (
    <DetailsView onClose={props.onClose} title={title} entered={props.entered}>
      {renderContent()}
    </DetailsView>
  );
};
