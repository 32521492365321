import { IAppTransFunction } from 'startup/utils';
import { Column } from '@mapper/admin-data-table';
import React from 'react';
import { ClientDetailRespDto } from 'apis-generated/mapper-sso-admin';
import {
  ClientWorkspaceFinancial,
  EntrepreneurWorkspaceTypeEnum,
  IndividualWorkspaceTypeEnum,
  LegalWorkspaceTypeEnum,
} from 'apis-generated/mapper-processing-admin';
import { getWorkspaceName, getWorkspaceType } from '../fin_organizations/tableDefs';
import { Button } from '@mui/material';

export const getWorkspaceColumns = (
  t: IAppTransFunction,
  client: ClientDetailRespDto,
  onDeleteClick: (workspace: ClientWorkspaceFinancial) => void
): Column<ClientWorkspaceFinancial>[] => {
  return [
    {
      id: 'name',
      accessor: 'workspace',
      Header: t('client_details_ws_column_name'),
      Cell: ({ row: { original }, value }) => {
        return <>{getWorkspaceName(value, client)}</>;
      },
    },
    {
      id: 'type',
      accessor: 'workspace',
      Header: t('client_details_ws_column_type'),
      Cell: ({ row: { original }, value }) => {
        return <>{getWorkspaceType(value, t)}</>;
      },
    },
    {
      id: 'bin',
      accessor: 'workspace',
      Header: t('client_details_ws_column_bin'),
      Cell: ({ value }) => {
        let number: string | undefined;
        switch (value?.data?.type) {
          case EntrepreneurWorkspaceTypeEnum.Entrepreneur:
          case IndividualWorkspaceTypeEnum.Individual:
            number = client.iin;
            break;
          case LegalWorkspaceTypeEnum.Legal:
            number = value.data.bin;
            break;
        }
        return <>{number}</>;
      },
    },
    {
      id: 'mcc',
      accessor: 'workspace',
      Header: t('client_details_ws_column_mcc'),
      Cell: ({ value }) => {
        let mcc: string | undefined;
        const data = value?.data;
        switch (data?.type) {
          case LegalWorkspaceTypeEnum.Legal:
            mcc = data?.mcc?.code;
            break;
          case EntrepreneurWorkspaceTypeEnum.Entrepreneur:
            mcc = data?.mcc?.code;
            break;
        }
        return <>{mcc}</>;
      },
    },
    {
      id: 'role',
      accessor: 'workspace',
      Header: t('client_details_ws_column_role'),
      Cell: ({ value }) => {
        let role: string | undefined;
        switch (value?.data?.type) {
          case LegalWorkspaceTypeEnum.Legal:
            role = value?.data?.role;
            break;
        }
        return <>{role}</>;
      },
    },
    {
      id: 'active',
      accessor: 'workspace',
      Header: t('client_details_ws_column_status'),
      Cell: ({ row: { original }, value }) => {
        const data = value?.data;
        switch (data?.type) {
          case LegalWorkspaceTypeEnum.Legal:
            return (
              <>
                {data?.active
                  ? t('client_details_ws_status_active')
                  : t('client_details_ws_status_deactivated')}
              </>
            );
          case EntrepreneurWorkspaceTypeEnum.Entrepreneur:
            return (
              <>
                {data?.active
                  ? t('client_details_ws_status_active')
                  : t('client_details_ws_status_deactivated')}
              </>
            );
          default:
            return <></>;
        }
      },
    },
    {
      id: 'delete-button',
      accessor: 'workspace',
      Cell: ({ row: { original } }) => {
        if (original.workspace?.data?.type === IndividualWorkspaceTypeEnum.Individual) {
          return <></>;
        }
        return (
          <Button onClick={() => onDeleteClick(original)}>
            {t('client_details_ws_column_delete')}
          </Button>
        );
      },
    },
  ];
};
