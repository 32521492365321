import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { matchPrimitive } from 'modules/common/typescript';

import { Dialog, DialogProps, DialogTitle } from '@mui/material';

import { useWorkspaceService } from 'modules/clients/module';
import { shouldTrans, useTranslation } from 'modules/common/utils';
import React, { FC, useCallback } from 'react';
import { EmployeeFormProps, EmployeeForm } from './EmployeeForm';

type AddBusinessModalProps = Omit<DialogProps, 'children'> & {
  onClose?: () => void;
  onAdded: () => void;
  legalId: string;
  target: 'legal' | 'enterpreneur';
};

export const AddEmployeeModal: FC<AddBusinessModalProps> = (props) => {
  const { onClose, legalId, onAdded, target, ...dialogProps } = props;
  const { t } = useTranslation();

  const { addEmployeeToEntrepreneur, addEmployeeToLegal } = useWorkspaceService();

  const handleSucessAdded = useCallback(() => {
    onAdded();
    onClose?.();
  }, [onAdded, onClose]);

  const handleAddEmployeeSubmit: EmployeeFormProps['onSubmit'] = (values) => {
    const username = values.user.username;
    const role = values.role;
    const req = () =>
      matchPrimitive(target, {
        enterpreneur: () => addEmployeeToEntrepreneur(username, { extId: legalId, role: role }),
        legal: () => addEmployeeToLegal(username, { extId: legalId, role: role }),
      });

    return pipe(
      req(),
      TE.chainFirstIOK(() => () => {
        handleSucessAdded();
      })
    );
  };

  return (
    <>
      <Dialog
        {...dialogProps}
        fullWidth
        maxWidth="xs"
        onClose={onClose}
        PaperProps={{
          component: 'form',
        }}
      >
        <DialogTitle>{t('business_accounts_participant_add')}</DialogTitle>

        <EmployeeForm onSubmit={handleAddEmployeeSubmit} onClose={onClose} />
      </Dialog>
    </>
  );
};
