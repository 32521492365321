import { Subject } from 'rxjs';
import { makeTableDataSource } from '@mapper/admin-resources';
import { IFetcher } from '@pay/data-fetching';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

import {
  RoleAddReqDto,
  RolePermissionApiInterface,
  RoleRespDto,
  RoleEditReqDto,
} from 'apis-generated/mapper-sso-admin';
import {
  mapFetcherResultEither,
  mapFetcherResultEither2,
  mapRemoteError,
} from 'modules/common/fetcher';
import { IRoleValues } from './types';

export class RolesStore {
  public updates$ = new Subject<{ roleId: string | undefined }>();

  constructor(private _rolesApi: RolePermissionApiInterface, private _fetcher: IFetcher) {}

  public updateRole = (originalRole: RoleRespDto, role: IRoleValues) => {
    const request: RoleEditReqDto = {
      name: role.name,
      permissions: role.permissions,
    };

    return pipe(
      () => this._rolesApi.editRole({ roleEditReqDto: request }, { id: originalRole.id }),
      T.map(mapFetcherResultEither),
      TE.chain((_) => {
        this.updates$.next({ roleId: originalRole.id });
        return TE.of(undefined);
      })
    );
  };

  public addRole = (role: RoleAddReqDto) => {
    const request = {
      name: role.name,
      permissions: role.permissions,
    };
    return pipe(
      () => this._rolesApi.addRole({ roleAddReqDto: request }),
      T.map(
        mapFetcherResultEither2((res) => {
          if (res.response && res.serverError?.hasCode('400000')) {
            return {
              type: 'role-already-exists',
            } as const;
          }
          return mapRemoteError(res);
        })
      ),
      TE.chain((_) => {
        this.updates$.next({ roleId: undefined });
        return TE.of(undefined);
      })
    );
  };

  public fetchAllRoles = makeTableDataSource<RoleRespDto>(this._fetcher, 'bo/roles');

  public fetchAll = () => {
    return pipe(
      () => this._rolesApi.getRoles({}),
      T.map(mapFetcherResultEither),
      TE.map((s) => s.items!)
    );
  };

  public fetchOneRole = (roleId: string) => {
    return pipe(() => this._rolesApi.getRole({ id: roleId }), T.map(mapFetcherResultEither));
  };

  public delete = (roleIds: string[]) => {
    const tasks = pipe(
      roleIds.map((id) =>
        pipe(() => this._rolesApi.removeRole({ id: id }), T.map(mapFetcherResultEither))
      ),
      A.sequence(TE.taskEither)
    );
    return tasks;
  };
}
