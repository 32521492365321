import * as z from 'zod';

export const initializeZodErrorMap = () => {
  z.setErrorMap((issue, ctx) => {
    switch (issue.code) {
      case z.ZodIssueCode.invalid_type:
        if (issue.received === z.ZodParsedType.undefined) {
          return {
            message: 'validation_required',
          };
        }
    }

    return z.defaultErrorMap(issue, ctx);
  });
};
