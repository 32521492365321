import React from 'react';
import { Fade, CircularProgress, Box } from '@mui/material';

interface Props {
  in: boolean;
}
export const LoadingOverlay: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <Fade in={props.in}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.common.white
              : theme.palette.background.default,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <CircularProgress />
      </Box>
    </Fade>
  );
};
