import { IFetcher } from '@pay/data-fetching';
import {
  BOReportRequest,
  DownloadPathParams,
  ExcelReportBySchoolVouchersRequestBody,
  MealMerchantsRequest,
  MealReportsControllerApiInterface,
  PrepareMerchantsRequestBody,
  StatisticReportQueryParams,
} from 'apis-generated/reports';
import { toApiDate } from 'modules/common/date';

export const makeReportApi = (fetcher: IFetcher): MealReportsControllerApiInterface => {
  return {
    statisticReport: (queryParamsArg: StatisticReportQueryParams) => {
      const { dateFrom, dateTo, ...args } = queryParamsArg;
      const queryParams: StatisticReportQueryParams = {
        dateFrom: toApiDate(dateFrom),
        dateTo: toApiDate(dateTo),
        ...args,
      };
      return fetcher.get(`bo/meal/prepare/stats`, { queryParams });
    },
    download: (params: DownloadPathParams) => {
      return fetcher.get(`bo/meal/download/${params.id}`);
    },
    excelReportBySchoolVouchers: (requestBody: ExcelReportBySchoolVouchersRequestBody) => {
      const { dateFrom, dateTo, ...args } = requestBody.bOReportRequest;
      const requestBodyValues: BOReportRequest = {
        dateFrom: toApiDate(dateFrom as Date),
        dateTo: toApiDate(dateTo as Date),
        ...args,
      };

      return fetcher.postJson(`bo/meal/prepare/meal-voucher-report`, requestBodyValues);
    },
    list: () => {
      return fetcher.get(`bo/meal/history`);
    },
    prepareMerchants: (requestBody: PrepareMerchantsRequestBody) => {
      const { from, to, ...args } = requestBody.mealMerchantsRequest;
      const requestBodyValues: MealMerchantsRequest = {
        from: toApiDate(from as Date),
        to: toApiDate(to as Date),
        ...args,
      };
      return fetcher.postJson(`bo/meal/prepare/merchants-report`, requestBodyValues);
    },
    reportBySchoolVauchers: () => {
      throw new Error('Not implemented');
    },
    reportBySchoolVauchers1: () => {
      throw new Error('Not implemented');
    },
    view: () => {
      throw new Error('Not implemented');
    },
  };
};
