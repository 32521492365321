import { Box, Button, Grow, TextField } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';
import { observer } from 'mobx-react';
import React, { useCallback, useState, FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';

import { matchI } from 'modules/common/match';
import { ICommonError, IRemoteError } from 'modules/common/store';
import { ITransKey } from 'startup/i18n';
import { IAuthLoginError } from '../constants';
import * as AuthState from '../core/AuthState';
import { LoadingOverlay } from './LoadingOverlay';
import { CurrentUserModel } from '../data';
import { BackofficeUserRespDto } from 'apis-generated/mapper-sso-admin';

enum EBaseAuthFields {
  Login = 'login',
  Password = 'password',
}

type IFormValues = Record<EBaseAuthFields, string>;

const required = (value: string) => (value ? undefined : 'validation_required');

interface IAuthFormProps {
  onSubmit: (
    login: string,
    password: string
  ) => TE.TaskEither<IRemoteError | ICommonError | IAuthLoginError, void>;
  logOutReason: AuthState.LogoutReason | undefined;
  currentUser?: BackofficeUserRespDto;
  onLogOut?: () => void;
}

export const AuthForm: React.FC<React.PropsWithChildren<IAuthFormProps>> = observer(
  ({ onSubmit, logOutReason, currentUser, onLogOut }) => {
    // const classes = useStyles();
    const { t } = useTranslation();
    const {
      control,
      formState: { errors },
      handleSubmit,
      formState,
      getValues,
    } = useForm<IFormValues>({
      defaultValues: {
        login: currentUser?.username ?? '',
        password: '',
      },
    });
    const [error, setError] = useState<IAuthLoginError | ICommonError | IRemoteError | undefined>(
      undefined
    );

    const handleSubmitInternal = useCallback(
      (values: IFormValues) => {
        return pipe(
          onSubmit(values.login, values.password),
          TE.fold(
            (e) => {
              setError(e);
              return T.of(e);
            },
            (_) => {
              return T.of(undefined);
            }
          )
        )();
      },
      [onSubmit]
    );

    let alertContent: React.ReactNode = undefined;
    if (error) {
      alertContent = (
        <Alert severity="error">
          <AlertTitle>{t('common_error')}</AlertTitle>
          {error && t(getErrorTrans(error))}
        </Alert>
      );
    } else if (logOutReason === AuthState.LogoutReason.SessionExpiredOrInvalidToken) {
      alertContent = (
        <Alert severity="info">
          <AlertTitle>{t('auth_session_expired_title')}</AlertTitle>
          {t('auth_session_expired_description')}
        </Alert>
      );
    }

    return (
      <>
        <form
          style={{
            padding: '8px 0',
            flexGrow: 0,
            position: 'relative',
            maxWidth: 300,
          }}
          onSubmit={handleSubmit(handleSubmitInternal)}
        >
          <Grow in={!!alertContent} unmountOnExit>
            <Box mb={3}>{alertContent}</Box>
          </Grow>
          <Box mb={1}>
            <Controller
              name={EBaseAuthFields.Login}
              control={control}
              rules={{ validate: required }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...makeRhfMuiTextFieldProps(errors.login, t)}
                  fullWidth
                  disabled={!!currentUser?.username}
                  // {...loginField.input}
                  label={t('auth_login')}
                  data-test-id="login"
                  {...field}
                />
              )}
            />
          </Box>
          <Box mb={3}>
            <Controller
              name={EBaseAuthFields.Password}
              control={control}
              rules={{ validate: required }}
              render={({ field }) => (
                <TextField
                  size="small"
                  fullWidth
                  {...makeRhfMuiTextFieldProps(errors.password, t)}
                  label={t('auth_password')}
                  data-test-id="password"
                  type="password"
                  {...field}
                />
              )}
            />
          </Box>
          <Box textAlign="right">
            {logOutReason === AuthState.LogoutReason.SessionExpiredOrInvalidToken && onLogOut && (
              <Button
                disableElevation
                onClick={onLogOut}
                size="medium"
                // variant="contained"
                color="inherit"
                style={{ marginRight: 16 }}
              >
                {t('auth_sign_out')}
              </Button>
            )}
            <Button
              disableElevation
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              data-test-id="submit"
            >
              {t('auth_sign_in')}
            </Button>
          </Box>
        </form>
        <LoadingOverlay in={formState.isSubmitting} />
      </>
    );
  }
);

const getErrorTrans = (err: IAuthLoginError | ICommonError | IRemoteError) =>
  matchI(err)({
    InvalidCredentials: (): ITransKey => 'auth_error_invalid_creds',
    'unknown-error': (): ITransKey => 'common_unknown_error',
    unknown: (): ITransKey => 'auth_error_unknown',
  });
