import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import { IFetcher } from '@pay/data-fetching';

import { TEtoPromise } from 'modules/common/fpts-utils';
import {
  AddSchoolRequest,
  EditSchoolRequest,
  ListQueryParams,
  SchoolControllerApiInterface,
  SchoolViewDTO,
} from 'apis-generated/school-meal';
import { makeTableDataSource } from '@mapper/admin-resources';

export const makeSchoolService = (fetcher: IFetcher, api: SchoolControllerApiInterface) => {
  return {
    fetchTable: makeTableDataSource<SchoolViewDTO>(fetcher, 'meal/school/search'),
    createSchool: (request: AddSchoolRequest) =>
      pipe(() => api.addSchool({ addSchoolRequest: request }), T.map(mapFetcherResultEither)),

    editSchool: (request: EditSchoolRequest, bin: string) =>
      pipe(
        () => api.editSchool({ editSchoolRequest: request }, { bin: bin }),
        T.map(mapFetcherResultEither)
      ),

    deleteSchool: (bin: string) =>
      pipe(() => api.deleteSchool({ bin: bin }), T.map(mapFetcherResultEither)),

    uploadSchools: (body: FormData) =>
      pipe(
        () =>
          fetcher.post<void>(`meal/school/upload`, {
            body,
            headers: { 'X-Locale': 'ru' },
          }),
        T.map(mapFetcherResultEither)
      ),

    getTemplate: () => pipe(() => api.getTemplate(), T.map(mapFetcherResultEither)),
  };
};

export type SchoolService = ReturnType<typeof makeSchoolService>;
