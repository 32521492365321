import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorScreen } from './ErrorScreen';

interface Props {}
export const UnkownErrorPage: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { t } = useTranslation();

  return <ErrorScreen shouldReload={true} title={t('unknown_error_page_title')} />;
};
