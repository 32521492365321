import React, { useCallback, useEffect, useState, FC } from 'react';
import { useLatest, usePreviousDistinct } from 'react-use';
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid } from '@mui/material';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { observer } from 'mobx-react';
import { DetailsCard, Page, PageTitle } from '@mapper/admin-ui-kit';

import { SmsGatewayResponse } from 'apis-generated/mapper-sso-admin';
import { mapAsyncState } from 'modules/common/async';
import { useTaskEither, useTaskEitherImmediate } from 'modules/common/async/hooks';
import { ErrorAlert } from 'modules/common/ui/errors';
import { useTranslation } from 'startup/utils';
import { useConfigurationService } from '../module';

export const ConfigurationPage: FC = observer(() => {
  const { t } = useTranslation();

  const [smsEnabled, setSmsEnabled] = useState(false);
  const smsEnabledLatest = usePreviousDistinct(smsEnabled);
  const smsEnabledLatestRef = useLatest(smsEnabledLatest);
  const configurationService = useConfigurationService();

  const { state, retry } = useTaskEitherImmediate(() =>
    pipe(
      configurationService.getSmsStatus(),
      TE.map((res) => {
        setSmsEnabled(res.enabled);
        return res;
      })
    )
  );
  const { execute: updateStatus, state: updateState } = useTaskEither(
    configurationService.updateSmsStatus
  );

  const handleChange = useCallback(
    (e: unknown, checked: boolean) => {
      setSmsEnabled(checked);
      return updateStatus(checked);
    },
    [updateStatus]
  );

  useEffect(() => {
    if (updateState?.error) {
      setSmsEnabled(smsEnabledLatestRef.current ?? false);
    }
  }, [smsEnabledLatestRef, updateState]);

  const renderSwitch = (state: SmsGatewayResponse) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            disabled={updateState?.loading}
            onChange={handleChange}
            checked={smsEnabled}
            color="primary"
          />
        }
        label={t('config_sms_toggle')}
        labelPlacement="start"
        style={{ marginLeft: 0 }}
      />
    );
  };

  return (
    <>
      <Page style={{ flexFlow: 'column' }}>
        <PageTitle>{t('config_title')}</PageTitle>
        <Grid container spacing={2}>
          <Grid item lg={4} sm={6} xs={12}>
            <DetailsCard title={t('config_sms_title')}>
              <Box p={2} pt={0}>
                <ErrorAlert error={updateState?.error} />
                {mapAsyncState(state, {
                  error: (e) => <ErrorAlert error={e} onRetry={retry} />,
                  loading: (_) => <CircularProgress />,
                  success: renderSwitch,
                })}
              </Box>
            </DetailsCard>
          </Grid>
        </Grid>
      </Page>
    </>
  );
});

ConfigurationPage.displayName = 'ConfigurationPage';
